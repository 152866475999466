import { isDisabled } from "@testing-library/user-event/dist/utils"
import { Avatar as AntdAvatar, Button, Progress, Skeleton } from "antd"
import { EditAvatarModal } from "components/profile/EditAvatarModal"
import { useApp } from "core/providers/AppProvider"
import { Pencil } from "core/utilities/Icons"
import { Score } from "core/utilities/ImageImport.js"
import { useState } from "react"

interface Props extends React.HTMLProps<HTMLDivElement> {
    username: string
    imageUrl: string
    notificationCount: number
    scoreData: {
        current: number
        nextLevel: number
    }
    disabled?: boolean
}

interface ProfileAvatarInterface extends React.FC<Props> {
    Skeleton: React.FC
}

const ProfileAvatar: ProfileAvatarInterface = ({
    username,
    imageUrl,
    scoreData,
    notificationCount,
    className,
    disabled = false,
}) => {
    const { isGuest } = useApp()
    const [showEditAvatarModal, setShowEditAvatarModal] = useState(false)

    return (
        <>
            {showEditAvatarModal && <EditAvatarModal onClose={() => setShowEditAvatarModal(false)} />}
            <div className="flex flex-col items-center">
                <div className={`relative p-1 rounded-full ${className}`}>
                    <Progress
                        strokeLinecap="round"
                        trailColor="#0000006b"
                        strokeWidth={3}
                        strokeColor={{ "0%": "#c084fc", "100%": "#dc2626" }}
                        width={163}
                        type="circle"
                        percent={Math.round((100 * scoreData.current) / scoreData.nextLevel) - 3}
                        showInfo={false}
                        className="absolute w-full h-full z-10 -rotate-[140deg] -left-0.5 -top-0 bg-transparent"
                    />
                    <div className="p-1">
                        <AntdAvatar className="w-full h-full bg-absolute" size="large" src={imageUrl} />
                    </div>
                    <sub className="absolute flex items-center justify-center bg-red-500 rounded-full  text-sm w-6 h-6 bottom-[2%] left-[15%] z-20">
                        {notificationCount}
                    </sub>
                    {!isGuest && !disabled && (
                        <Button
                            onClick={() => setShowEditAvatarModal(true)}
                            className="absolute flex justify-center items-center bottom-[2%] right-[15%] border bg-white p-1 w-6 h-6 rounded-full z-20"
                        >
                            <Pencil className="w-full h-full fill-black " />
                        </Button>
                    )}
                </div>
                <span className="mt-2 text-lg ltr">{username}</span>
                <div className="flex items-center gap-1.5 ltr mt-1">
                    <img src={Score} alt="score icon" />
                    <span className="text-pink-500 font-bold ltr">
                        {scoreData.current} / {scoreData.nextLevel}
                    </span>
                </div>
            </div>
        </>
    )
}

ProfileAvatar.Skeleton = () => (
    <div className="flex flex-col items-center relative ">
        <div className="flex flex-col gap-2  items-center ">
            <Skeleton.Avatar active className="w-32 h-32" />
            <Skeleton.Input active className="rounded-lg" />
            <Skeleton.Input active size="small" className="rounded-lg" />
        </div>
    </div>
)

export { ProfileAvatar }
