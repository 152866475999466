// Mock Data
export const data = {
    bannerPath: "https://cdn.funcraft.ir/assets/banners/130b5fa4-847d-4ccb-af38-e2e78bc3eea7_banner-3704685406.png",
    currentUser: {
        ID: 706,
        CreatedAt: "2022-12-23T13:55:22.697357Z",
        UpdatedAt: "2022-12-24T16:01:07.695930501Z",
        DeletedAt: null,
        deviceToken: "5eb5c9a7-9b64-47ce-9615-d42a1f751208",
        name: "Guest0edac726",
        tag: "0edac726",
        avatarID: 0,
        avatarUrl: "https://cdn.funcraft.ir/avatars/avatar_-303946604.png",
        googleId: "",
        firebaseId: "",
        phoneNumber: "",
        email: "",
        silverCoin: 170,
        goldCoin: 0,
        xp: 10,
        level: 1,
        lastGames: [
            {
                ID: 38,
                CreatedAt: "2022-12-13T01:29:25.898163Z",
                UpdatedAt: "2022-12-20T17:13:45.780515Z",
                DeletedAt: null,
                name: "جامپی",
                clientId: "130b5fa4-847d-4ccb-af38-e2e78bc3eea7",
                gamePath: "https://cdn.funcraft.ir/assets/webgls/130b5fa4-847d-4ccb-af38-e2e78bc3eea7_webgl-2802653916",
                imagePath:
                    "https://cdn.funcraft.ir/assets/icons/130b5fa4-847d-4ccb-af38-e2e78bc3eea7_icon-1963178627.png",
                bannerPath:
                    "https://cdn.funcraft.ir/assets/banners/130b5fa4-847d-4ccb-af38-e2e78bc3eea7_banner-3704685406.png",
                modes: null,
                isAvailable: true,
                tutorials: [
                    "https://cdn.funcraft.ir/assets/tutorials/130b5fa4-847d-4ccb-af38-e2e78bc3eea7_tutorial-1833694704.png",
                    "https://cdn.funcraft.ir/assets/tutorials/130b5fa4-847d-4ccb-af38-e2e78bc3eea7_tutorial-673395956.png",
                    "https://cdn.funcraft.ir/assets/tutorials/130b5fa4-847d-4ccb-af38-e2e78bc3eea7_tutorial-634779383.png",
                ],
                engine: "playcanvas",
            },
            {
                ID: 37,
                CreatedAt: "2022-12-11T09:17:44.496734Z",
                UpdatedAt: "2022-12-20T17:14:29.150949Z",
                DeletedAt: null,
                name: "توپ شیطونک",
                clientId: "eaedc195-70b8-40d2-9c96-8945e3c50672",
                gamePath: "https://cdn.funcraft.ir/assets/webgls/eaedc195-70b8-40d2-9c96-8945e3c50672_webgl-3276889562",
                imagePath:
                    "https://cdn.funcraft.ir/assets/icons/eaedc195-70b8-40d2-9c96-8945e3c50672_icon-2215500920.png",
                bannerPath:
                    "https://cdn.funcraft.ir/assets/banners/eaedc195-70b8-40d2-9c96-8945e3c50672_banner-712183438.png",
                modes: null,
                isAvailable: true,
                tutorials: [
                    "https://cdn.funcraft.ir/assets/tutorials/eaedc195-70b8-40d2-9c96-8945e3c50672_tutorial-2443895937.png",
                    "https://cdn.funcraft.ir/assets/tutorials/eaedc195-70b8-40d2-9c96-8945e3c50672_tutorial-1597442047.png",
                    "https://cdn.funcraft.ir/assets/tutorials/eaedc195-70b8-40d2-9c96-8945e3c50672_tutorial-4171684140.png",
                    "https://cdn.funcraft.ir/assets/tutorials/eaedc195-70b8-40d2-9c96-8945e3c50672_tutorial-1131678334.png",
                ],
                engine: "playcanvas",
            },
            {
                ID: 11,
                CreatedAt: "2022-10-31T12:11:27.551145Z",
                UpdatedAt: "2022-12-20T14:29:45.722832Z",
                DeletedAt: null,
                name: "تتریس ۳×۳",
                clientId: "37f7348f-8e24-41da-a050-92256ce1627d",
                gamePath: "https://cdn.funcraft.ir/assets/webgls/37f7348f-8e24-41da-a050-92256ce1627d_webgl-3086095742",
                imagePath:
                    "https://cdn.funcraft.ir/assets/icons/37f7348f-8e24-41da-a050-92256ce1627d_icon-1742162888.png",
                bannerPath:
                    "https://cdn.funcraft.ir/assets/banners/37f7348f-8e24-41da-a050-92256ce1627d_banner-3924083360.png",
                modes: null,
                isAvailable: true,
                tutorials: [
                    "https://cdn.funcraft.ir/assets/tutorials/37f7348f-8e24-41da-a050-92256ce1627d_tutorial-3148477846.png",
                    "https://cdn.funcraft.ir/assets/tutorials/37f7348f-8e24-41da-a050-92256ce1627d_tutorial-1336285638.png",
                    "https://cdn.funcraft.ir/assets/tutorials/37f7348f-8e24-41da-a050-92256ce1627d_tutorial-2147610031.png",
                ],
                engine: "playcanvas",
            },
            {
                ID: 9,
                CreatedAt: "2022-10-31T12:00:23.439127Z",
                UpdatedAt: "2022-12-20T23:34:52.796131Z",
                DeletedAt: null,
                name: "هگزونیکس",
                clientId: "1107580a-98e9-4afa-9fab-17e33c33fd36",
                gamePath: "https://cdn.funcraft.ir/assets/webgls/1107580a-98e9-4afa-9fab-17e33c33fd36_webgl-1882149778",
                imagePath:
                    "https://cdn.funcraft.ir/assets/icons/1107580a-98e9-4afa-9fab-17e33c33fd36_icon-1359794073.png",
                bannerPath:
                    "https://cdn.funcraft.ir/assets/banners/1107580a-98e9-4afa-9fab-17e33c33fd36_banner-3581026933.png",
                modes: null,
                isAvailable: true,
                tutorials: [
                    "https://cdn.funcraft.ir/assets/tutorials/1107580a-98e9-4afa-9fab-17e33c33fd36_tutorial-3792944468.png",
                    "https://cdn.funcraft.ir/assets/tutorials/1107580a-98e9-4afa-9fab-17e33c33fd36_tutorial-3655326761.png",
                    "https://cdn.funcraft.ir/assets/tutorials/1107580a-98e9-4afa-9fab-17e33c33fd36_tutorial-3460818711.png",
                ],
                engine: "playcanvas",
            },
            {
                ID: 12,
                CreatedAt: "2022-12-04T11:47:40.286665Z",
                UpdatedAt: "2022-12-19T16:10:17.510918Z",
                DeletedAt: null,
                name: "جنگ ژله‌ای",
                clientId: "e30fcc3e-57d1-43c8-af23-d6bb7addd4ea",
                gamePath: "https://cdn.funcraft.ir/assets/webgls/e30fcc3e-57d1-43c8-af23-d6bb7addd4ea_webgl-302457870",
                imagePath:
                    "https://cdn.funcraft.ir/assets/icons/e30fcc3e-57d1-43c8-af23-d6bb7addd4ea_icon-3178539549.png",
                bannerPath:
                    "https://cdn.funcraft.ir/assets/banners/e30fcc3e-57d1-43c8-af23-d6bb7addd4ea_banner-2568687289.png",
                modes: null,
                isAvailable: true,
                tutorials: [
                    "https://cdn.funcraft.ir/assets/tutorials/e30fcc3e-57d1-43c8-af23-d6bb7addd4ea_tutorial-3536559069.png",
                    "https://cdn.funcraft.ir/assets/tutorials/e30fcc3e-57d1-43c8-af23-d6bb7addd4ea_tutorial-780018413.png",
                ],
                engine: "playcanvas",
            },
            {
                ID: 13,
                CreatedAt: "2022-10-31T13:51:26.261721Z",
                UpdatedAt: "2022-12-11T15:41:03.356232Z",
                DeletedAt: null,
                name: "۲۰۴۸ مکعبی",
                clientId: "6b7ac66c-4afa-47a4-8cdc-e3e7a41a310a",
                gamePath: "https://cdn.funcraft.ir/assets/webgls/6b7ac66c-4afa-47a4-8cdc-e3e7a41a310a_webgl-1998828409",
                imagePath:
                    "https://cdn.funcraft.ir/assets/icons/6b7ac66c-4afa-47a4-8cdc-e3e7a41a310a_icon-2685321711.png",
                bannerPath:
                    "https://cdn.funcraft.ir/assets/banners/6b7ac66c-4afa-47a4-8cdc-e3e7a41a310a_banner-3187106578.png",
                modes: null,
                isAvailable: true,
                tutorials: [
                    "https://cdn.funcraft.ir/assets/tutorials/6b7ac66c-4afa-47a4-8cdc-e3e7a41a310a_tutorial-1240094580.png",
                    "https://cdn.funcraft.ir/assets/tutorials/6b7ac66c-4afa-47a4-8cdc-e3e7a41a310a_tutorial-2816191908.png",
                    "https://cdn.funcraft.ir/assets/tutorials/6b7ac66c-4afa-47a4-8cdc-e3e7a41a310a_tutorial-391672863.png",
                ],
                engine: "playcanvas",
            },
        ],
        friends: [],
        referralsCount: 0,
        isNameChanged: false,
        xpProgress: {
            value: 10,
            maxValue: 40,
        },
    },
    players: [
        {
            ID: 1,
            name: "User 1",
            score: 23123,
        },
        {
            ID: 2,
            name: "User 2",
            score: 23332,
        },
    ],
    game: {
        ID: 38,
        CreatedAt: "2022-12-13T01:29:25.898163Z",
        UpdatedAt: "2022-12-20T17:13:45.780515Z",
        DeletedAt: null,
        name: "جامپی",
        clientId: "130b5fa4-847d-4ccb-af38-e2e78bc3eea7",
        gamePath: "https://cdn.funcraft.ir/assets/webgls/130b5fa4-847d-4ccb-af38-e2e78bc3eea7_webgl-2802653916",
        imagePath: "https://cdn.funcraft.ir/assets/icons/130b5fa4-847d-4ccb-af38-e2e78bc3eea7_icon-1963178627.png",
        bannerPath: "https://cdn.funcraft.ir/assets/banners/130b5fa4-847d-4ccb-af38-e2e78bc3eea7_banner-3704685406.png",
        modes: [
            {
                ID: 25,
                CreatedAt: "2022-10-31T12:56:18.445313Z",
                UpdatedAt: "2022-12-15T13:42:14.991887Z",
                DeletedAt: null,
                rank: 3,
                type: 0,
                trophyReward: 10,
                loseTrophy: 6,
                winXp: 15,
                loseXp: 7,
                rewards: [
                    {
                        type: 2,
                        amount: 2,
                    },
                ],
                entrance: 1000,
                entranceType: 1,
                isAvailable: true,
            },
            {
                ID: 26,
                CreatedAt: "2022-10-31T12:57:18.332904Z",
                UpdatedAt: "2022-11-19T19:56:11.079994Z",
                DeletedAt: null,
                rank: 4,
                type: 0,
                trophyReward: 10,
                loseTrophy: 6,
                winXp: 20,
                loseXp: 10,
                rewards: [
                    {
                        type: 2,
                        amount: 19,
                    },
                ],
                entrance: 10000,
                entranceType: 1,
                isAvailable: true,
            },
            {
                ID: 23,
                CreatedAt: "2022-10-31T12:38:37.598396Z",
                UpdatedAt: "2022-12-18T23:42:19.880806Z",
                DeletedAt: null,
                rank: 0,
                type: 0,
                trophyReward: 0,
                loseTrophy: 0,
                winXp: 10,
                loseXp: 5,
                rewards: [
                    {
                        type: 1,
                        amount: 20,
                    },
                ],
                entrance: 0,
                entranceType: 1,
                isAvailable: true,
            },
            {
                ID: 24,
                CreatedAt: "2022-10-31T12:43:20.891997Z",
                UpdatedAt: "2022-11-19T19:55:20.076407Z",
                DeletedAt: null,
                rank: 2,
                type: 0,
                trophyReward: 10,
                loseTrophy: 6,
                winXp: 10,
                loseXp: 5,
                rewards: [
                    {
                        type: 1,
                        amount: 190,
                    },
                ],
                entrance: 100,
                entranceType: 1,
                isAvailable: true,
            },
        ],
        isAvailable: true,
        tutorials: [
            "https://cdn.funcraft.ir/assets/tutorials/130b5fa4-847d-4ccb-af38-e2e78bc3eea7_tutorial-1833694704.png",
            "https://cdn.funcraft.ir/assets/tutorials/130b5fa4-847d-4ccb-af38-e2e78bc3eea7_tutorial-673395956.png",
            "https://cdn.funcraft.ir/assets/tutorials/130b5fa4-847d-4ccb-af38-e2e78bc3eea7_tutorial-634779383.png",
        ],
        engine: "playcanvas",
        playCount: 84,
        avatars: [
            "https://cdn.funcraft.ir/avatars/avatar_-3895330492.png",
            "https://cdn.funcraft.ir/avatars/avatar_-2761153026.png",
            "https://cdn.funcraft.ir/avatars/avatar_-2761153026.png",
        ],
    },
    userGameModes: [
        {
            ID: 23,
            CreatedAt: "2022-10-31T12:38:37.598396Z",
            UpdatedAt: "2022-12-18T23:42:19.880806Z",
            DeletedAt: null,
            rank: 2,
            type: 0,
            trophyReward: 0,
            loseTrophy: 0,
            winXp: 10,
            loseXp: 5,
            rewards: [
                {
                    type: 1,
                    amount: 20,
                },
            ],
            entrance: 0,
            entranceType: 1,
            isAvailable: true,
            isActive: false,
            value: 0,
            maxValue: 0,
        },
        {
            ID: 24,
            CreatedAt: "2022-10-31T12:43:20.891997Z",
            UpdatedAt: "2022-11-19T19:55:20.076407Z",
            DeletedAt: null,
            rank: 2,
            type: 0,
            trophyReward: 10,
            loseTrophy: 6,
            winXp: 10,
            loseXp: 5,
            rewards: [
                {
                    type: 1,
                    amount: 190,
                },
            ],
            entrance: 100,
            entranceType: 1,
            isAvailable: true,
            isActive: false,
            value: 0,
            maxValue: 49,
        },
        {
            ID: 25,
            CreatedAt: "2022-10-31T12:56:18.445313Z",
            UpdatedAt: "2022-12-15T13:42:14.991887Z",
            DeletedAt: null,
            rank: 3,
            type: 0,
            trophyReward: 10,
            loseTrophy: 6,
            winXp: 15,
            loseXp: 7,
            rewards: [
                {
                    type: 2,
                    amount: 2,
                },
            ],
            entrance: 1000,
            entranceType: 1,
            isAvailable: true,
            isActive: false,
            value: 0,
            maxValue: 49,
        },
        {
            ID: 0,
            CreatedAt: "0001-01-01T00:00:00Z",
            UpdatedAt: "0001-01-01T00:00:00Z",
            DeletedAt: null,
            rank: 4,
            type: 0,
            trophyReward: 0,
            loseTrophy: 0,
            winXp: 10,
            loseXp: 0,
            rewards: [
                {
                    amount: 100,
                    type: 2,
                },
            ],
            entrance: 0,
            entranceType: 0,
            isAvailable: true,
            isActive: true,
            value: 1,
            maxValue: 3,
        },
        {
            ID: 26,
            CreatedAt: "2022-10-31T12:57:18.332904Z",
            UpdatedAt: "2022-11-19T19:56:11.079994Z",
            DeletedAt: null,
            rank: 4,
            type: 0,
            trophyReward: 10,
            loseTrophy: 6,
            winXp: 20,
            loseXp: 10,
            rewards: [
                {
                    type: 2,
                    amount: 19,
                },
            ],
            entrance: 10000,
            entranceType: 1,
            isAvailable: true,
            isActive: false,
            value: 0,
            maxValue: 99,
        },
    ],
    rankData: {
        ranks: [
            {
                ID: 1,
                CreatedAt: "0001-01-01T00:00:00Z",
                UpdatedAt: "0001-01-01T00:00:00Z",
                DeletedAt: null,
                key: "Qualification",
                type: 1,
                minTrophy: 0,
                maxTrophy: 0,
                icon: "",
            },
            {
                ID: 2,
                CreatedAt: "0001-01-01T00:00:00Z",
                UpdatedAt: "0001-01-01T00:00:00Z",
                DeletedAt: null,
                key: "Bronze",
                type: 2,
                minTrophy: 1,
                maxTrophy: 50,
                icon: "https://cdn.funcraft.ir/ranks/1.png",
            },
            {
                ID: 3,
                CreatedAt: "0001-01-01T00:00:00Z",
                UpdatedAt: "0001-01-01T00:00:00Z",
                DeletedAt: null,
                key: "Silver",
                type: 3,
                minTrophy: 51,
                maxTrophy: 100,
                icon: "https://cdn.funcraft.ir/ranks/2.png",
            },
            {
                ID: 4,
                CreatedAt: "0001-01-01T00:00:00Z",
                UpdatedAt: "0001-01-01T00:00:00Z",
                DeletedAt: null,
                key: "Gold",
                type: 4,
                minTrophy: 101,
                maxTrophy: 200,
                icon: "https://cdn.funcraft.ir/ranks/3.png",
            },
        ],
        currentRank: 2,
        currentTrophy: 0,
        progressValue: 0,
        maxValue: 1,
    },
    dailyMissions: {
        time: "12:45:05",
        missions: [
            {
                id: 1,
                text: "هشت بار بازی کن!",
                coin: 1,
                maxLevel: 8,
                level: 5,
                claimed: false,
                image: "https://akm-img-a-in.tosshub.com/indiatoday/images/story/202105/photo-1585620385456-4759f9b5c7_1200x768.jpeg?FBnzbNtZQtXS8JsNwQGVjNs5Th8WXGnW&size=770:433",
            },
            {
                id: 2,
                text: "هفت بار بازی کن!",
                coin: 3,
                maxLevel: 7,
                level: 7,
                claimed: false,
                image: "https://akm-img-a-in.tosshub.com/indiatoday/images/story/202105/photo-1585620385456-4759f9b5c7_1200x768.jpeg?FBnzbNtZQtXS8JsNwQGVjNs5Th8WXGnW&size=770:433",
            },
            {
                id: 3,
                text: "پنج بار بازی کن!",
                coin: 1,
                maxLevel: 5,
                level: 5,
                claimed: true,
                image: "https://akm-img-a-in.tosshub.com/indiatoday/images/story/202105/photo-1585620385456-4759f9b5c7_1200x768.jpeg?FBnzbNtZQtXS8JsNwQGVjNs5Th8WXGnW&size=770:433",
            },
        ],
    },
    onGoingGameData: {
        ID: 1635,
        CreatedAt: "2022-12-25T15:49:37.349361233Z",
        UpdatedAt: "2022-12-25T15:49:37.349361233Z",
        DeletedAt: null,
        gameClientID: "eaedc195-70b8-40d2-9c96-8945e3c50672",
        gameId: 37,
        matchId: "7a074404-bf09-441f-863e-bee07a17c919",
        playerOneId: 706,
        playerOneName: "Guest0edac726",
        playerTwoId: 0,
        playerTwoName: "",
        winnerId: 0,
        playerOneScore: 0,
        playerTwoScore: 0,
        playerOneEndGame: false,
        playerTwoEndGame: false,
        rank: 1,
        mode: {
            ID: 0,
            CreatedAt: "0001-01-01T00:00:00Z",
            UpdatedAt: "0001-01-01T00:00:00Z",
            DeletedAt: null,
            rank: 1,
            type: 0,
            trophyReward: 0,
            loseTrophy: 0,
            winXp: 10,
            loseXp: 0,
            rewards: [
                {
                    amount: 70,
                    type: 1,
                },
            ],
            entrance: 0,
            entranceType: 0,
            isAvailable: true,
        },
        PlayerOneStartTime: "2022-12-25T15:49:37.271513909Z",
        PlayerTwoStartTime: "0001-01-01T00:00:00Z",
        game: {
            ID: 37,
            CreatedAt: "2022-12-11T09:17:44.496734Z",
            UpdatedAt: "2022-12-20T17:14:29.150949Z",
            DeletedAt: null,
            name: "توپ شیطونک",
            clientId: "eaedc195-70b8-40d2-9c96-8945e3c50672",
            gamePath: "https://cdn.funcraft.ir/assets/webgls/eaedc195-70b8-40d2-9c96-8945e3c50672_webgl-3276889562",
            imagePath: "https://cdn.funcraft.ir/assets/icons/eaedc195-70b8-40d2-9c96-8945e3c50672_icon-2215500920.png",
            bannerPath:
                "https://cdn.funcraft.ir/assets/banners/eaedc195-70b8-40d2-9c96-8945e3c50672_banner-712183438.png",
            modes: [
                {
                    ID: 19,
                    CreatedAt: "2022-10-31T12:38:37.598396Z",
                    UpdatedAt: "2022-10-31T12:55:44.750737Z",
                    DeletedAt: null,
                    rank: 0,
                    type: 0,
                    trophyReward: 0,
                    loseTrophy: 0,
                    winXp: 10,
                    loseXp: 5,
                    rewards: [
                        {
                            type: 1,
                            amount: 20,
                        },
                    ],
                    entrance: 0,
                    entranceType: 1,
                    isAvailable: true,
                },
                {
                    ID: 20,
                    CreatedAt: "2022-10-31T12:43:20.891997Z",
                    UpdatedAt: "2022-11-19T19:55:20.076407Z",
                    DeletedAt: null,
                    rank: 2,
                    type: 0,
                    trophyReward: 10,
                    loseTrophy: 6,
                    winXp: 10,
                    loseXp: 5,
                    rewards: [
                        {
                            type: 1,
                            amount: 190,
                        },
                    ],
                    entrance: 100,
                    entranceType: 1,
                    isAvailable: true,
                },
                {
                    ID: 21,
                    CreatedAt: "2022-10-31T12:56:18.445313Z",
                    UpdatedAt: "2022-12-15T13:13:31.257702Z",
                    DeletedAt: null,
                    rank: 3,
                    type: 0,
                    trophyReward: 10,
                    loseTrophy: 6,
                    winXp: 15,
                    loseXp: 7,
                    rewards: [
                        {
                            type: 2,
                            amount: 2,
                        },
                    ],
                    entrance: 1000,
                    entranceType: 1,
                    isAvailable: true,
                },
                {
                    ID: 22,
                    CreatedAt: "2022-10-31T12:57:18.332904Z",
                    UpdatedAt: "2022-11-19T19:56:11.079994Z",
                    DeletedAt: null,
                    rank: 4,
                    type: 0,
                    trophyReward: 10,
                    loseTrophy: 6,
                    winXp: 20,
                    loseXp: 10,
                    rewards: [
                        {
                            type: 2,
                            amount: 19,
                        },
                    ],
                    entrance: 10000,
                    entranceType: 1,
                    isAvailable: true,
                },
            ],
            isAvailable: true,
            tutorials: [
                "https://cdn.funcraft.ir/assets/tutorials/eaedc195-70b8-40d2-9c96-8945e3c50672_tutorial-2443895937.png",
                "https://cdn.funcraft.ir/assets/tutorials/eaedc195-70b8-40d2-9c96-8945e3c50672_tutorial-1597442047.png",
                "https://cdn.funcraft.ir/assets/tutorials/eaedc195-70b8-40d2-9c96-8945e3c50672_tutorial-4171684140.png",
                "https://cdn.funcraft.ir/assets/tutorials/eaedc195-70b8-40d2-9c96-8945e3c50672_tutorial-1131678334.png",
            ],
            engine: "playcanvas",
        },
        playerOneAvatar: "https://cdn.funcraft.ir/avatars/avatar_-303946604.png",
        playerTwoAvatar: "",
    },
    tournaments: [
        {
            ID: 1,
            name: "جنگ ژله‌ای",
            imagePath: "https://cdn.funcraft.ir/assets/icons/e30fcc3e-57d1-43c8-af23-d6bb7addd4ea_icon-2687131242.png",
            bannerPath:
                "https://cdn.funcraft.ir/assets/banners/e30fcc3e-57d1-43c8-af23-d6bb7addd4ea_banner-2948322272.png",
            avatars: [
                "https://cdn.funcraft.ir/avatars/avatar_-3895330492.png",
                "https://cdn.funcraft.ir/avatars/avatar_-2761153026.png",
                "https://cdn.funcraft.ir/avatars/avatar_-2761153026.png",
            ],
            reward: {
                type: 2,
                amount: 213,
            },
            entrance: 123,
            entranceType: 2,
            playCount: 113,
        },
        {
            ID: 2,
            name: "جامپی",
            imagePath: "https://cdn.funcraft.ir/assets/icons/130b5fa4-847d-4ccb-af38-e2e78bc3eea7_icon-1963178627.png",
            bannerPath:
                "https://cdn.funcraft.ir/assets/banners/130b5fa4-847d-4ccb-af38-e2e78bc3eea7_banner-3704685406.png",
            avatars: [
                "https://cdn.funcraft.ir/avatars/avatar_-3895330492.png",
                "https://cdn.funcraft.ir/avatars/avatar_-2761153026.png",
                "https://cdn.funcraft.ir/avatars/avatar_-2761153026.png",
            ],
            reward: {
                type: 2,
                amount: 100,
            },
            entrance: 100,
            entranceType: 2,
            playCount: 84,
        },
        {
            ID: 3,
            name: "تتریس 3x3",
            imagePath: "https://cdn.funcraft.ir/assets/icons/37f7348f-8e24-41da-a050-92256ce1627d_icon-2236917959.png",
            bannerPath:
                "https://cdn.funcraft.ir/assets/banners/37f7348f-8e24-41da-a050-92256ce1627d_banner-2887738935.png",
            avatars: [
                "https://cdn.funcraft.ir/avatars/avatar_-3895330492.png",
                "https://cdn.funcraft.ir/avatars/avatar_-2761153026.png",
                "https://cdn.funcraft.ir/avatars/avatar_-2761153026.png",
            ],
            reward: {
                type: 1,
                amount: 321,
            },
            entrance: 98,
            entranceType: 1,
            playCount: 43,
          },
    ],
    statistics: [
        {
            rewardWinners: 10,
            totalRewards: 168,
            players: 15,
            active: false,
        },
        {
            rewardWinners: 13,
            totalRewards: 89,
            players: 23,
            active: false,
        },
        {
            rewardWinners: 6,
            totalRewards: 871,
            players: 90,
            active: true,
        },
    ],
}
