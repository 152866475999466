import { useEffect } from "react"
import { useNavigate } from "react-router"

// Providers
import { useApp } from "core/providers/AppProvider"
import { useTournament } from "core/providers/TournamentProvider"

// Utilities
import { Gold, Silver, VersusTournamentBottom, VersusTournamentTop } from "core/utilities/ImageImport"
import { AppRoutes } from "core/utilities/AppRoutes"

// Components
import { ScreenLoader } from "components/template"
import { tools } from "core/functions/tools"
import { Trans } from "react-i18next"

const Versus = () => {
    const navigate = useNavigate()
    const { currentUser } = useApp()
    const { onGoingTournamentData, loadingTournament, tournament } = useTournament()
    const user = onGoingTournamentData?.leaderBoard?.find((_: any) => _.isPlayer)
    const leaderBoard = onGoingTournamentData?.leaderBoard

    useEffect(() => {
        if (!onGoingTournamentData || !tournament) return navigate(`${AppRoutes.dashboard.home}`)
    }, [])

    useEffect(() => {
        const timer = setTimeout(
            () =>
                navigate(`${AppRoutes.dashboard.tournament.playcanvas}/${onGoingTournamentData?.match?.tournamentId}`, {
                    replace: true,
                }),
            4_000
        )
        return () => clearTimeout(timer)
    }, [])

    // Render
    if (loadingTournament) return <ScreenLoader />
    return (
        <div className="flex flex-col h-screen bg-gradient-to-b from-red-800 to-black">
            {/* Pointers */}
            <div className="absolute top-0 left-0 w-full h-full overflow-hidden versus-bg-upper-shape ltr animate-in-from-tl">
                <img
                    src={VersusTournamentTop}
                    className="transition-all scale-125 left-5 top-10 w-80"
                    alt="Versus Tournament Top"
                />
            </div>
            <div className="absolute bottom-0 w-full h-full overflow-hidden versus-bg-down-shape ltr animate-in-from-br">
                <img
                    src={VersusTournamentBottom}
                    className="absolute bottom-0 right-0 object-cover scale-125 w-72"
                    alt="Versus Tournament Bottom"
                />
            </div>

            {/* Header */}
            <div className="flex items-center justify-start w-40 p-1 mx-auto bg-black bg-opacity-50 rounded-full ltr z-10 m-4">
                <img className="object-cover rounded-full w-7 h-7 " src={currentUser?.avatarUrl} alt="Avatar" />
                <span className="flex items-center justify-center flex-1 text-sm">{currentUser?.name}</span>
            </div>
            <div className="z-10 flex flex-col items-center justify-between h-full p-4">
                {/* Reward */}
                <div className="flex flex-col items-center mt-20 gap-y-2">
                    <span className="text-base">
                        <Trans>Prize Pool</Trans>
                    </span>
                    <div className="flex items-center justify-center mt-1">
                        <span className="ml-3 mr-2 text-2xl mt-1">
                            {tools.thousandsSeparator(
                                tournament?.prizePools.find(_ => _.reward.amount >= tournament?.activePlayers.playCount)
                                    ?.reward.amount ?? 0
                            )}
                        </span>
                        <img
                            className="h-8 w-8"
                            src={tournament?.prizePools[0].reward.type === 1 ? Silver : Gold}
                            alt="Coin"
                        />
                    </div>
                </div>

                <div className="w-full m-10">
                    {/* LeaderBoard */}
                    <div>
                        {user ? (
                            leaderBoard?.map((player: any) => {
                                if (player.isPlayer)
                                    return (
                                        <div className="flex items-center justify-between p-4 mx-auto my-8 bg-black bg-opacity-50 rounded-full ltr">
                                            <div className="flex items-center gap-x-4 ltr">
                                                <img
                                                    className="object-cover w-12 h-12 rounded-full "
                                                    src={player?.avatarUrl}
                                                    alt="Avatar"
                                                />
                                                <div className="ltr">{player?.rank}.</div>
                                                <span className="text-sm">{player?.username}</span>
                                            </div>
                                            <div className="mr-5 text-lg text-yellow-500">{player?.score}</div>
                                        </div>
                                    )

                                return (
                                    <div
                                        className="flex items-center w-full gap-x-6 ltr mb-4 px-10"
                                        key={player.username}
                                    >
                                        <div className="flex items-center w-full gap-x-2 ltr">
                                            <div className="w-12 h-12 rounded-full">
                                                <img
                                                    className="object-cover w-full h-full rounded-full "
                                                    src={player.avatarUrl}
                                                    alt={`Avatar`}
                                                />
                                            </div>
                                            <div className="ltr text-white">{player?.rank}.</div>
                                            <div className="mt-1 text-base text-center text-white">
                                                {player?.username}
                                            </div>
                                        </div>
                                        <span className="text-base text-yellow-500">{player.score}</span>
                                    </div>
                                )
                            })
                        ) : (
                            <div className="flex items-center justify-between p-4 mx-auto mt-10 bg-black bg-opacity-50 rounded-full ltr">
                                <div className="flex items-center gap-x-4 ltr">
                                    <img
                                        className="object-cover w-10 h-10 rounded-full "
                                        src={currentUser?.avatarUrl}
                                        alt="Avatar"
                                    />
                                    <span className="text-sm ">{currentUser?.name}</span>
                                </div>
                                <span className="mr-5 text-lg text-yellow-500">-</span>
                            </div>
                        )}
                    </div>
                </div>
                {/* Navigation */}
                <div className="z-50 flex flex-col items-center self-center w-60 gap-y-1">
                    <p className="text-base text-white">
                        <Trans>Redirecting To The Match</Trans>
                    </p>
                    <div className="w-full h-[2px] bg-white rounded-full"></div>
                </div>
            </div>
        </div>
    )
}

export default Versus
