import { Image } from "antd"
import { ActivityCard } from "components/activity/ActivityCard"
import { GameProgressIndicator } from "components/game/GameProgressIndicator"
import { StepIndicator } from "components/invite-friends/StepIndicator"
import { StepIndicatorContainer } from "components/invite-friends/StepIndicatorContainer"
import { ScreenLoader } from "components/template"
import { Layout } from "components/template/Layout"
import { MedalTypeLocalization, useGame } from "core/providers/GameProvider"
import { Check } from "core/utilities/Icons"
import { Trans } from "react-i18next"

const GameUserProgress = () => {
    // States and Hooks
    const { game, loadingGame, rankData } = useGame()
    // Methods

    // Render
    if (loadingGame) return <ScreenLoader />

    return (
        <Layout>
            <div className="py-4"></div>
            <ActivityCard
                title={game?.name ?? ""}
                desc={MedalTypeLocalization[rankData?.ranks[rankData.currentRank].key ?? ""]}
                gameImage={game?.imagePath ?? ""}
            />
            <div className="my-6">
                <GameProgressIndicator
                    levelImage={rankData?.ranks.find(rank => rank.type === rankData.currentRank)?.icon ?? ""}
                    min={rankData?.ranks.find(rank => rank.type === rankData.currentRank)?.minTrophy ?? 0}
                    max={rankData?.ranks.find(rank => rank.type === rankData.currentRank)?.maxTrophy ?? 0}
                    current={rankData?.currentTrophy ?? 0}
                />
            </div>

            <div className="w-full flex justify-start gap-7">
                <div className="h-100">
                    <StepIndicatorContainer type="vertical" progressPercentage={(rankData?.progressValue ?? 0) * 100}>
                        <StepIndicator>
                            <Check className="w-7 h-7 rounded-full bg-green-300 -mt-2" />
                        </StepIndicator>
                        {rankData?.ranks.map(rank => {
                            if (rank.icon !== "")
                                return (
                                    <div className="h-32 flex flex-col justify-end">
                                        <Image
                                            className="scale-150 w-10 h-auto"
                                            src={rank.icon}
                                            preview={false}
                                            alt="Medal illustration"
                                        />
                                    </div>
                                )
                            return <></>
                        })}
                    </StepIndicatorContainer>
                </div>
                <div className="h-100 w-full flex flex-col justify-between pb-2 py-1">
                    {rankData?.ranks.map(rank => {
                        return (
                            <div className="relative">
                                <div className="flex items-start justify-between h-100">
                                    <h3 className="text-white text-sm">
                                        <Trans>{rank.key}</Trans>
                                    </h3>
                                    <h3 className="text-gray-400 text-sm ltr">
                                        {rank.minTrophy === 0 && rank.maxTrophy === 0
                                            ? `0`
                                            : rank.maxTrophy === 100_000
                                            ? `+${rank.minTrophy - 1}`
                                            : `${rank.minTrophy}-${rank.maxTrophy}`}
                                    </h3>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="py-16"></div>
        </Layout>
    )
}

export default GameUserProgress
