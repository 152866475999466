import React from "react"
import { ShimmerElement } from "./ShimmerElement"

interface Props {}

const OfferCardSkeleton: React.FC<Props> = () => {
    // States and Hooks

    // Methods

    // Render
    return (
        <div className="flex flex-col relative w-72 h-36">
            <div className="skeleton-wrapper p-0 dark">
                <ShimmerElement type="rectangle" />
            </div>
        </div>
    )
}

export { OfferCardSkeleton }
