import { LoadingOutlined } from "@ant-design/icons"
import { Modal, Spin } from "antd"
import { OfflineStatus } from "core/utilities/Icons"
import React from "react"
import { Trans } from "react-i18next"
import i18n from "../../i18n"

interface Props {
    isOpen: boolean
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const OfflineModal: React.FC<Props> = ({ isOpen }) => {
    return (
        <Modal className="offline-modal" visible={isOpen} closable={false} footer={null} centered>
            <div className={`flex items-center gap-8 ${i18n.language === "fa" ? "persian rtl" : "english ltr"}`}>
                <div>{<OfflineStatus className="w-16 h-16 text-primary-main" />}</div>
                <div>
                    <div className="font-medium text-xl">
                        <Trans>You Are Offline</Trans>
                    </div>
                    <div className="flex items-center gap-3">
                        <div>
                            <Spin indicator={antIcon} />
                        </div>
                        <div>
                            <Trans>Check Connection</Trans>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default OfflineModal
