import { OnGoingGameCard } from "components/activity/OnGoingGameCard"
import { Layout } from "components/template/Layout"
import { ActivityCard } from "components/activity/ActivityCard"
import { Link } from "react-router-dom"
import { AppRoutes } from "core/utilities/AppRoutes"
import { CustomCollapse } from "components/template/CustomCollapse"
import { GameLevels, useActivity } from "core/providers/ActivityProvider"
import { useApp } from "core/providers/AppProvider"
import { AvatarUnknown } from "core/utilities/ImageImport"
import { GameLevelEnum } from "core/api/types/GameTypes"
import { ActivityTypeObject } from "core/providers/TournamentProvider"
import { OnGoingTournamentCard } from "components/activity"
import { Trans, useTranslation } from "react-i18next"

const Activity = () => {
    // States and Hooks
    const { t } = useTranslation()
    const { currentUser, lang } = useApp()
    const { loadingActivities, finishedGames, setIsActivity } = useActivity()

    // Methods
    // Render
    return (
        <Layout showControlCapsule={true} showHeader={true}>
            <div className="py-4 "></div>
            {loadingActivities && (
                <div>
                    <h3 className="text-xl text-white my-2">
                        <Trans>Unfinished</Trans>
                    </h3>
                    <OnGoingGameCard.Skeleton />
                </div>
            )}
            {!loadingActivities && <AwaitingGamesActivityCards />}

            <h3 className="text-white text-xl mt-8">
                <Trans>Recent Activity</Trans>
            </h3>

            <div className="flex flex-col mt-2 gap-2 pb-16 mb-3">
                {/* Skeleton screen while fetch the data */}
                {loadingActivities &&
                    [1, 2, 3, 4].map(i => <ActivityCard.Skeleton key={`activity-card-skeleton-${i}`} />)}

                {/* Showing the real content at the end */}
                {!loadingActivities &&
                    finishedGames
                        ?.map(_ => _.data)
                        ?.map((activity, index) => {
                            // States
                            const { type } = finishedGames[index]
                            const isTournament = type === ActivityTypeObject.Tournament
                            const isUserWinner: boolean = activity.winnerId === currentUser?.ID
                            const rank = activity.mode.rank as 0 | 1 | 2 | 3 | 4
                            let cardDesc = ""
                            switch (GameLevels[rank]) {
                                case "QUALIFICATION":
                                    cardDesc = t("Trial").toString()
                                    break
                                case "TRAINING":
                                    cardDesc = t("Practice Game").toString()
                                    break
                                case "BRONZE":
                                    cardDesc = `${t("Duel").toString()} ${Number(activity.mode.entrance)} ${t(
                                        "Coins"
                                    ).toString()}`
                                    break
                                case "SILVER":
                                    cardDesc = `${t("Duel").toString()} ${Number(activity.mode.entrance)} ${t(
                                        "Coins"
                                    ).toString()}`
                                    break
                                case "GOLD":
                                    cardDesc = `${t("Duel").toString()} ${Number(activity.mode.entrance)} ${t(
                                        "Coins"
                                    ).toString()}`
                                    break
                                default:
                                    break
                            }
                            // Render
                            if (isTournament)
                                return (
                                    <Link
                                        key={index}
                                        to={`${`${AppRoutes.dashboard.tournament.result}/${activity.tournamentID}`}`}
                                        onClick={() => setIsActivity(true)}
                                    >
                                        <ActivityCard
                                            title={
                                                lang === "en"
                                                    ? `You ranked ${activity.rank}`
                                                    : `رتبه ${activity.rank} را کسب کردی`
                                            }
                                            desc={t("Tournament").toString()}
                                            gameImage={activity.game.imagePath}
                                            score={
                                                isUserWinner || rank === GameLevelEnum.QUALIFICATION
                                                    ? {
                                                          type: activity.mode.rewards[0].type === 1 ? "Silver" : "Gold",
                                                          amount: Number(activity.mode.rewards[0].amount),
                                                      }
                                                    : undefined
                                            }
                                        />
                                    </Link>
                                )
                            return (
                                <Link
                                    onClick={() => setIsActivity(true)}
                                    key={index}
                                    to={`${
                                        isUserWinner
                                            ? `${AppRoutes.dashboard.win}/${activity.matchId}`
                                            : `${AppRoutes.dashboard.lose}/${activity.matchId}`
                                    }`}
                                >
                                    <ActivityCard
                                        title={isUserWinner ? t("You Won").toString() : t("You Placed 2nd").toString()}
                                        desc={cardDesc}
                                        gameImage={activity.game.imagePath}
                                        score={
                                            isUserWinner || rank === GameLevelEnum.QUALIFICATION
                                                ? {
                                                      type: activity.mode.rewards[0].type === 1 ? "Silver" : "Gold",
                                                      amount: Number(activity.mode.rewards[0].amount),
                                                  }
                                                : undefined
                                        }
                                    />
                                </Link>
                            )
                        })}
                {!loadingActivities && finishedGames?.length === 0 && (
                    <div className="text-center text-gray-400">
                        <Trans>No recent activities</Trans>
                    </div>
                )}
            </div>
        </Layout>
    )
}

export default Activity

const AwaitingGamesActivityCards = () => {
    // States and Hooks
    const { t } = useTranslation()
    const { currentUser } = useApp()
    const { awaitingGames, setIsActivity } = useActivity()

    // Render
    return (
        <CustomCollapse title={t("Unfinished")}>
            <CustomCollapse.Content>
                <div className="flex flex-col gap-2 mt-2">
                    {awaitingGames
                        ?.map(_ => _.data)
                        ?.slice(1)
                        ?.map((activity, index) => {
                            const { type } = awaitingGames.slice(1)[index]
                            const isTournament = type === ActivityTypeObject.Tournament
                            console.log("index ===> ", index, "type ===> ", type, "   isTournament ===> ", isTournament)
                            const firstScore: number =
                                activity.playerOneId === currentUser?.ID
                                    ? activity.playerOneScore
                                    : activity.playerTwoScore

                            const playerOneAvatar: string =
                                activity.playerOneId === currentUser?.ID
                                    ? activity.playerOneAvatar
                                    : activity.playerTwoAvatar

                            const rank = activity.mode.rank as 0 | 1 | 2 | 3 | 4
                            let cardDesc = ""
                            switch (GameLevels[rank]) {
                                case "QUALIFICATION":
                                    cardDesc = t("Trial").toString()
                                    break
                                case "TRAINING":
                                    cardDesc = t("Practice Game").toString()
                                    break
                                case "BRONZE":
                                    cardDesc = `${t("Duel").toString()} ${Number(activity.mode.entrance)} ${t(
                                        "Coins"
                                    ).toString()}`
                                    break
                                case "SILVER":
                                    cardDesc = `${t("Duel").toString()} ${Number(activity.mode.entrance)} ${t(
                                        "Coins"
                                    ).toString()}`
                                    break
                                case "GOLD":
                                    cardDesc = `${t("Duel").toString()} ${Number(activity.mode.entrance)} ${t(
                                        "Coins"
                                    ).toString()}`
                                    break
                                default:
                                    break
                            }

                            if (isTournament) {
                                return (
                                    <Link
                                        key={`ongoing-game-${index}`}
                                        to={`${AppRoutes.dashboard.tournament.result}/${activity.tournamentID}`}
                                        onClick={() => setIsActivity(true)}
                                    >
                                        <OnGoingTournamentCard
                                            gameImage={activity.game.imagePath}
                                            title="Waiting Tournament to Finish"
                                            desc="Tournament"
                                            players={{
                                                user: {
                                                    avatar: playerOneAvatar,
                                                    score: firstScore,
                                                },
                                                competitor: {
                                                    avatar: AvatarUnknown,
                                                },
                                            }}
                                            isTournament={true}
                                        />
                                    </Link>
                                )
                            }

                            return (
                                <Link
                                    key={`ongoing-game-${index}`}
                                    to={`${AppRoutes.dashboard.awaiting}/${activity.matchId}`}
                                    onClick={() => setIsActivity(true)}
                                >
                                    <OnGoingGameCard
                                        gameImage={activity.game.imagePath}
                                        title={t("Waiting Opponent").toString()}
                                        desc={t(cardDesc).toString()}
                                        players={{
                                            user: {
                                                avatar: playerOneAvatar,
                                                score: firstScore,
                                            },
                                            competitor: {
                                                avatar: AvatarUnknown,
                                            },
                                        }}
                                    />
                                </Link>
                            )
                        })}
                </div>
            </CustomCollapse.Content>
        </CustomCollapse>
    )
}
