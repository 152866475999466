import { Layout } from "components/template/Layout"
import { CoinOffer } from "components/shop/CoinOffer"
import {
    CoinOfferBg1,
    CoinOfferBg2,
    CoinOfferBg3,
    CoinOfferBg4,
    CoinOfferGoldBg1,
    CoinOfferGoldBg2,
    CoinOfferGoldBg3,
    CoinOfferGoldBg4,
} from "core/utilities/ImageImport"
import { useShop } from "core/providers/ShopProvider"
import { ErrorModal } from "components/template/ErrorModal"
import { ConvertGoldToSilverModal } from "components/shop/ConvertGoldToSilverModal"
import { Trans } from "react-i18next"
const Shop = () => {
    // States and Hooks
    const {
        loadingPackages,
        moneyPackages,
        goldPackages,
        error,
        clearError,
        isConfirmationModalOpen,
        setIsConfirmationModalOpen,
    } = useShop()
    // Methods

    // UseEffects

    // Render
    return (
        <>
            {error && (
                <ErrorModal
                    onClose={() => {
                        clearError()
                    }}
                    msg={error}
                />
            )}
            {isConfirmationModalOpen && <ConvertGoldToSilverModal onClose={() => setIsConfirmationModalOpen(false)} />}
            <Layout>
                <div className="py-8">
                    {/* <h3 className="text-white text-xl">جایزه نقدی</h3>
                    {loadingPackages ? (
                        <HorizontalList className="gap-4 mt-4 -mx-4 px-4">
                            {[1, 2].map(i => (
                                <OfferCard.Skeleton key={`offer-card-skeleton-${i}`} />
                            ))}
                        </HorizontalList>
                    ) : (
                        <HorizontalList className="gap-4 mt-4 -mx-4 px-4">
                            {[1, 2, 3, 4].map(index => {
                                return (
                                    <OfferCard
                                        key={index}
                                        bgImageUrl={ShopReward}
                                        rewardData={{ amount: 100, type: "gold" }}
                                        action={"دریافت از کلوپ"}
                                        description={"اکانت نتفلیکس"}
                                        offerExpireDate={new Date("2024/1/1 22:00")}
                                        actionHandler={() => {}}
                                    />
                                )
                            })}
                        </HorizontalList>
                    )} */}

                    <h3 className="text-white text-xl mt-8">
                        <Trans>Packs</Trans>
                    </h3>
                    {loadingPackages ? (
                        <div className="flex flex-col gap-4 mt-2">
                            {[1, 2, 3, 4, 5].map(i => (
                                <CoinOffer.Skeleton key={`coin-offer-skeleton-${i}`} />
                            ))}
                        </div>
                    ) : (
                        <div className="flex flex-col gap-4 mt-2">
                            {moneyPackages?.map((pack, index) => (
                                <CoinOffer
                                    key={index}
                                    bgImage={CoinOfferBackgrounds[index]}
                                    rewardData={{ amount: pack.amount, type: "silver" }}
                                    btnTitle={{
                                        value: pack.price,
                                        currency: "money",
                                    }}
                                    pack={pack}
                                />
                            ))}
                            {goldPackages?.map((pack, index) => (
                                <CoinOffer
                                    key={index}
                                    bgImage={CoinOfferGoldBackgrounds[index]}
                                    rewardData={{ amount: pack.amount, type: "silver" }}
                                    btnTitle={{
                                        value: pack.price,
                                        currency: "gold",
                                    }}
                                    pack={pack}
                                />
                            ))}
                        </div>
                    )}
                    <div className="py-4 mt-3"></div>
                </div>
            </Layout>
        </>
    )
}

export default Shop

const CoinOfferBackgrounds = [CoinOfferBg1, CoinOfferBg2, CoinOfferBg3, CoinOfferBg4]
const CoinOfferGoldBackgrounds = [CoinOfferGoldBg1, CoinOfferGoldBg2, CoinOfferGoldBg3, CoinOfferGoldBg4]
