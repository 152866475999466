import { useState } from "react"
import { Button } from "antd"
import { toast } from "react-toastify"
import { useNavigate } from "react-router"
import { useApi } from "core/api/ApiProvider"
import { useApp } from "core/providers/AppProvider"
import { Layout } from "components/template/Layout"
import { AppRoutes } from "core/utilities/AppRoutes"
import { ScreenLoader } from "components/template"

const EnterName = () => {
    // States and Hooks
    const navigate = useNavigate()
    const { user } = useApi()
    const { loadingApp, currentUser, fetchUser } = useApp()
    const [loding, setLoding] = useState<boolean>(false)
    const [name, setName] = useState<string>("")

    // Methods
    const onConfirmButtonClick = async () => {
        if (name.length < 4) {
            toast.error("نام باید حداقل 4 حرف باشد.")
            return
        }
        setLoding(true)
        const { data, error } = await user.enterName({ ID: currentUser?.ID, name })
        await fetchUser()
        setLoding(false)
        if (error) return toast.error("نام وارد شده معتبر نیست")

        return navigate(AppRoutes.dashboard.home)
    }

    if (loadingApp) {
        return <ScreenLoader />
    }

    // Render
    return (
        <Layout showHeader={false} showControlCapsule={false} fit={true}>
            <div className="flex flex-col min-h-screen items-center p-8">
                <h3 className="text-2xl text-white mb-4">نام دلخواه خود را وارد کنید</h3>
                <div className="w-1/3 h-1 rounded-full bg-gradient-to-tr from-blue-500 to-sky-400"></div>
                <div className="mt-5 w-full">
                    <input
                        type="text"
                        placeholder="نام کاربری خود را وارد کنید"
                        value={name}
                        className="text-white mt-3 px-4 py-3 border-white border border-solid bg-transparent rounded-xl w-full focus:outline-none"
                        onChange={e => setName(e.target.value)}
                    />
                    <div className="text-gray-400 font-thin text-2xs mr-2 mt-1">حداقل 4 کاراکتر</div>
                    <Button
                        className={`w-full bg-gradient-to-tr from-blue-600  to-sky-400 ${
                            loding ? "from-gray-600  to-gray-400" : "from-blue-600  to-sky-400"
                        }  border-0 rounded-xl h-12 mt-4 text-2xl`}
                        type="primary"
                        disabled={loding}
                        onClick={() => onConfirmButtonClick()}
                    >
                        ثبت
                    </Button>
                </div>
            </div>
        </Layout>
    )
}

export default EnterName
