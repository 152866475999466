export interface Step {
    stepNumber: number
    stepDescription?: React.ReactNode
    isCompleted: boolean
}

interface Props {
    children: React.ReactNode
    progressPercentage: number
    type?: "vertical" | "horizontal"
}

const StepIndicatorContainer: React.FC<Props> = ({ progressPercentage, children, type = "horizontal" }) => {
    const Vertical = () => (
        <div className="relative flex flex-col w-fit h-full gap-4 justify-between ltr">
            <div className="absolute left-[38%] top-0 h-[100%] w-2 bg-primary-light ltr">
                <div className={`bg-green-400 w-2`} style={{ height: `${progressPercentage}%` }}></div>
            </div>
            {children}
        </div>
    )

    if (type === "vertical") return <Vertical />

    return (
        <div className="relative flex gap-4 ltr">
            <div className="absolute top-[38%] w-full h-2 bg-primary-light ltr">
                <div className={`bg-green-400 h-2 rounded-lg`} style={{ width: `${progressPercentage}%` }}></div>
            </div>
            <div className="flex justify-between ltr w-full">{children}</div>
        </div>
    )
}

export { StepIndicatorContainer }
