import { Button, Skeleton } from "antd"
import { ShopOfferType } from "core/api/types/ShopTypes"
import { tools } from "core/functions/tools"
import { useShop } from "core/providers/ShopProvider"
import { ArrowLeft, ArrowRight } from "core/utilities/Icons"
import { Gold, Silver } from "core/utilities/ImageImport.js"
import { useTranslation } from "react-i18next"
interface Props {
    key: number
    btnTitle: {
        value: number
        currency: "money" | "gold"
    }
    bgImage: string
    rewardData: {
        type: "gold" | "silver"
        amount: number
    }
    pack: ShopOfferType
}

interface ICoinOffer extends React.FC<Props> {
    Skeleton: React.FC<{}>
}

const CoinOffer: ICoinOffer = ({ btnTitle, bgImage, rewardData, pack }) => {
    // States and Hooks
    const {
        setIsConfirmationModalOpen,
        setCurrentSelectedPack,
        currentSelectedPack,
        purchaseButtonLoding,
        purchaseGoldByMoney,
    } = useShop()

    const { t, i18n } = useTranslation()

    // Methods

    // Render
    return (
        <div className="flex flex-col relative w-full h-36 py-3 px-4">
            {/* This is background image */}
            <img
                className="absolute top-0 left-0 w-full h-full rounded-3xl object-cover"
                src={bgImage}
                alt="Earn coin background"
            />

            <div className={`flex items-center gap-2 z-10 ${i18n.language === "fa" ? "justify-end" : "justify-start"}`}>
                <div className="text-2xl mt-1 font-bold">{tools.thousandsSeparator(rewardData.amount)}</div>
                <img className="w-7 h-7" src={rewardData.type === "gold" ? Gold : Silver} alt="coin" />
            </div>
            <Button
                onClick={async () => {
                    if (btnTitle.currency === "gold") {
                        setIsConfirmationModalOpen(true)
                        setCurrentSelectedPack(pack)
                    }
                    if (btnTitle.currency === "money") {
                        const utmSource = tools.getCookie("utm_source")
                        if (utmSource === "bazaar") {
                            return window.purchaseBazaar(pack.sku)
                        }
                        setCurrentSelectedPack(pack)
                        await purchaseGoldByMoney(pack)
                    }
                }}
                className={`flex items-center justify-center gap-2 ${
                    btnTitle.currency === "money" ? "btn-purchase-purple" : "btn-purchase-gold"
                }`}
                loading={currentSelectedPack?.ID === pack.ID && purchaseButtonLoding}
                type="primary"
            >
                <div className="flex items-center justify-center">
                    <div className="text-lg flex items-center justify-center gap-1">
                        {btnTitle.currency === "gold" && <img className="w-5 h-5" src={Gold} />}
                        <div>{tools.thousandsSeparator(btnTitle.value)}</div>
                        {btnTitle.currency === "money" && t("Price")}
                    </div>
                    {i18n.language === "fa" ? (
                        <ArrowLeft className="mx-2 h-6 w-6" />
                    ) : (
                        <ArrowRight className="mx-2 h-6 w-6" />
                    )}
                </div>
            </Button>
        </div>
    )
}

CoinOffer.Skeleton = () => {
    return (
        <div className="flex flex-col relative w-full h-36 py-3 px-4">
            <div className="absolute top-0 left-0 w-full h-full bg-[#bebebe50] rounded-3xl"></div>
            <div className="w-full gap-1 z-10 mt-auto">
                <Skeleton.Button shape="round" block size="large" active />
            </div>
        </div>
    )
}

export { CoinOffer }
