import { useState } from "react"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router"
import { Button, Image } from "antd"

// Providers
import { useApp } from "core/providers/AppProvider"
import { useHome } from "core/providers/HomeProvider"

// Utilities
import { AppRoutes } from "core/utilities/AppRoutes"
import { EnvelopeIcon, InviteFriends } from "core/utilities/ImageImport"

// Components
import { BlurBg } from "components/template/BlurBg"
import { GameCard } from "components/template/GameCard"
import { Drawer } from "components/template/Drawer"
import { Layout } from "components/template/Layout"
import { OfferCard } from "components/template/OfferCard"
import { HorizontalList } from "components/template/HorizontalList"
import { GameGridSkeleton } from "components/skeleton/GameGridSkeleton"
import { OfferCardSkeleton } from "components/skeleton/OfferCardSkeleton"
import { TournamentCardSkeleton } from "components/skeleton/TournamentCardSkeleton"
import { TournamentCard } from "components/template/TournamentCard"
import { Trans, useTranslation } from "react-i18next"

const Home = () => {
    // States and Hooks
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { currentUser } = useApp()
    const { loadingGames, allGames, loadingTournaments, allTournaments } = useHome()
    const [isClubDrawerOpen, setIsClubDrawerOpen] = useState(false)

    // Render
    return (
        <>
            <Layout>
                <h3 className="mt-6 mb-2 text-xl text-white">
                    <Trans>Recent Games</Trans>
                </h3>
                {loadingGames ? (
                    <HorizontalList className="gap-4 px-4 py-1 -mx-4">
                        {[1, 2, 3, 4, 5].map(i => (
                            <GameCard.Skeleton key={`game-card-skeleton-${i}`} />
                        ))}
                    </HorizontalList>
                ) : (
                    <HorizontalList className="gap-4 px-4 py-1 -mx-4">
                        {currentUser?.lastGames.map(game => {
                            return (
                                <Link key={game.clientId} to={AppRoutes.dashboard.game.home + "/" + game.clientId}>
                                    <GameCard
                                        title={game.name}
                                        titleSize="xs"
                                        imageUrl={game.imagePath}
                                        className="w-24"
                                        imageClassName="h-24"
                                        gameUrl="/#test"
                                    />
                                </Link>
                            )
                        })}
                    </HorizontalList>
                )}

                {/* Tournaments */}
                <>
                    <h3 className="mt-4 mb-2 text-xl text-white text-start">
                        <Trans>Tournaments</Trans>
                    </h3>
                    {loadingTournaments ? (
                        <HorizontalList className="gap-4 mt-2">
                            {[1, 2, 3].map(_ => {
                                return <TournamentCardSkeleton key={_} />
                            })}
                        </HorizontalList>
                    ) : (
                        allTournaments?.length !== 0 && (
                            <HorizontalList className="gap-4 px-4 mt-2 -mx-4">
                                {allTournaments!.map(tournament => {
                                    if (tournament.isActive)
                                        return (
                                            <Link
                                                key={tournament.ID}
                                                to={`${AppRoutes.dashboard.tournament.game.home}/${tournament.tournamentId}`}
                                            >
                                                <TournamentCard tournament={tournament} />
                                            </Link>
                                        )
                                    return <TournamentCard tournament={tournament} isActive={false} />
                                })}
                            </HorizontalList>
                        )
                    )}
                </>

                <h3 className="mt-4 mb-2 text-xl text-white">
                    <Trans>Main Games</Trans>
                </h3>
                {loadingGames ? (
                    <GameGridSkeleton />
                ) : (
                    <div className="grid grid-cols-2 gap-6 mt-2">
                        {allGames?.map(game => {
                            return (
                                <Link key={game.clientId} to={AppRoutes.dashboard.game.home + "/" + game.clientId}>
                                    <GameCard
                                        title={game.name}
                                        titleSize="lg"
                                        imageUrl={game.imagePath}
                                        gameUrl="#"
                                        playersData={{
                                            avatarImageUrls: game.avatars,
                                            playCount: game.playCount,
                                        }}
                                    />
                                </Link>
                            )
                        })}
                    </div>
                )}

                {/* Todo */}
                <h3 className="mt-4 text-xl text-white">
                    <Trans>Win Gold Coins</Trans>
                </h3>
                {loadingGames ? (
                    <HorizontalList className="gap-4 mt-2">
                        {[1, 2, 3].map(_ => {
                            return <OfferCardSkeleton key={_} />
                        })}
                    </HorizontalList>
                ) : (
                    <HorizontalList className="gap-4 px-4 mt-2 -mx-4">
                        <OfferCard
                            action={
                                <div className="flex items-center justify-center gap-1">
                                    <div className="text-lg">
                                        <Trans>Invite Friends</Trans>
                                    </div>
                                    <Image src={EnvelopeIcon} className="w-6 h-6 mx-2" />
                                </div>
                            }
                            actionHandler={() => navigate(AppRoutes.dashboard.inviteFriends)}
                            description={t("Invite Friends Body").toString()}
                            rewardData={{ type: "gold", amount: 1 }}
                            bgImageUrl={InviteFriends}
                        />
                    </HorizontalList>
                )}

                <div className="py-4 mt-12"></div>
            </Layout>
            <Drawer
                isOpen={isClubDrawerOpen}
                setIsOpen={setIsClubDrawerOpen}
                className="z-50 py-10 h-1/3 rounded-t-3xl bg-gradient-to-tr from-violet-400 to-red-400"
            >
                <h3 className="mx-auto text-lg text-white">برنامه کلوپ باز می شود</h3>
                <div className="relative mx-auto my-auto">
                    <BlurBg className="bg-black rounded-full bg-opacity-20 backdrop-brightness-95" />
                    <Button
                        onClick={e => {
                            e.preventDefault()
                            setIsClubDrawerOpen(false)
                        }}
                        className="w-32 h-full px-6 py-2 text-lg"
                        type="text"
                    >
                        باشه
                    </Button>
                </div>
            </Drawer>
        </>
    )
}

export default Home
