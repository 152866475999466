import { Link, useNavigate } from "react-router-dom"

// Providers
import { useApp } from "core/providers/AppProvider"
import { useTournament } from "core/providers/TournamentProvider"

// Utilities
import { AcademicHat, ArrowLeft } from "core/utilities/Icons"
import { Silver, Gold } from "core/utilities/ImageImport"
import { AppRoutes } from "core/utilities/AppRoutes"

// Functions
import { tools } from "core/functions/tools"

// Components
import { Layout } from "components/template/Layout"
import { Qualification } from "components/tournament/game/Qualification"
import { LeaderBoard } from "components/tournament/game/LeaderBoard"
import { ScreenLoader } from "components/template"

const Game = () => {
    // States and Hooks
    const navigate = useNavigate()
    const { currentUser } = useApp()
    const { tournament, loadingTournament, leaderBoardData } = useTournament()

    // Render
    if (loadingTournament) return <ScreenLoader />
    return (
        <>
            <Layout showControlCapsule={true} showHeader={false}>
                {/* Header bg picture */}
                <img
                    className="absolute top-0 left-0 object-cover w-full rounded-b-2xl h-80"
                    src={
                        tournament?.game.bannerPath
                            ? tournament?.game.bannerPath
                            : "https://akm-img-a-in.tosshub.com/indiatoday/images/story/202105/photo-1585620385456-4759f9b5c7_1200x768.jpeg?FBnzbNtZQtXS8JsNwQGVjNs5Th8WXGnW&size=770:433"
                    }
                    alt="Game banner"
                />

                <nav className="z-10 flex items-center justify-between w-full py-3 ltr">
                    <button
                        className="p-2 bg-white rounded-full bg-opacity-30 backdrop-blur-lg"
                        onClick={() => navigate(AppRoutes.dashboard.home)}
                    >
                        <ArrowLeft className="w-5 h-5" />
                    </button>
                    <div className="py-1 px-1.5 rounded-full bg-white bg-opacity-30 backdrop-blur-lg">
                        <div className="flex gap-2.5">
                            <div className="flex gap-1">
                                <img src={Gold} alt="gold" />
                                <span className="font-semibold">
                                    {tools.thousandsSeparator(currentUser?.goldCoin ?? 0)}
                                </span>
                            </div>
                            <div className="flex gap-1">
                                <img src={Silver} alt="gold" />
                                <span className="font-semibold">
                                    {tools.thousandsSeparator(currentUser?.silverCoin ?? 0)}
                                </span>
                            </div>
                        </div>
                    </div>
                    <Link
                        to={`${AppRoutes.dashboard.game.onboarding}/${tournament?.game?.clientId}`}
                        className="p-2 bg-white rounded-full bg-opacity-30 backdrop-blur-lg"
                    >
                        <AcademicHat className="w-5 h-5" />
                    </Link>
                </nav>

                <div className="z-10">
                    <div className="mt-48"></div>
                    <Qualification tournament={tournament} />
                </div>

                <div className="px-4 mt-4">
                    <LeaderBoard data={leaderBoardData} />
                </div>

                {/* Daily Missions */}
                {/* <DailyMissions dailyMissions={data?.dailyMissions} game={data?.game} /> */}

                <div className="py-16"></div>
            </Layout>
        </>
    )
}

export default Game
