import { TournamentLeaderBoardType } from "core/api/types/TournamentTypes"
import { tools } from "core/functions/tools"
import { useApp } from "core/providers/AppProvider"
import { useTournament } from "core/providers/TournamentProvider"
import { AppRoutes } from "core/utilities/AppRoutes"
import { Gold, Silver } from "core/utilities/ImageImport"
import { useEffect } from "react"
import { ArrowLeft, ArrowRight } from "core/utilities/Icons"
import { AvatarUnknown } from "core/utilities/ImageImport"
import { Trans, useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { LeaderBoardItem } from "./LeaderBoardItem"

type Props = {
    data: {
        total: TournamentLeaderBoardType[]
        close: TournamentLeaderBoardType[]
    }
}

const LeaderBoard: React.FC<Props> = ({ data }) => {
    // States and Hooks
    const { currentUser } = useApp()
    const { tournament } = useTournament()
    const goldPlayer: TournamentLeaderBoardType = data.total.slice(0, 1)[0]
    const silverPlayer: TournamentLeaderBoardType = data.total.slice(1, 2)[0]
    const bronzePlayer: TournamentLeaderBoardType = data.total.slice(2, 3)[0]
    const { t, i18n } = useTranslation()

    useEffect(() => {
        console.log("TOurnament id ====> ", tournament)
    }, [tournament])

    // Render
    return (
        <>
            <div className="grid grid-cols-12 justify-items-center gap-1 mb-10">
                {/* Silver */}
                {silverPlayer ? (
                    <Link
                        to={`${AppRoutes.dashboard.userProfile}/${silverPlayer.userID}`}
                        className="flex flex-col items-center col-span-4 w-full"
                    >
                        <div className="mt-auto bg-gradient-to-t from-gray-500 to-gray-400 p-0.5 rounded-full w-11 h-11">
                            <div className="bg-primary-main p-0.5 rounded-full w-10 h-10">
                                <img src={silverPlayer.avatarUrl} className="w-full h-full rounded-full" alt="" />
                            </div>
                        </div>
                        <span className="leading-4 mt-1.5">{silverPlayer.username}</span>
                        <span className="text-cyan-600 leading-4">{tools.thousandsSeparator(silverPlayer.score)}</span>
                        <div className="mt-2 h-36 bg-gradient-to-b from-gray-500 to-gray-400 rounded-2xl w-full flex justify-center">
                            <div className="flex mt-12 justify-center scale-150">
                                <div>{tools.thousandsSeparator(silverPlayer.prize)}</div>
                                <div>
                                    <img
                                        className="inline mx-1"
                                        src={silverPlayer.prizeType === 1 ? Silver : Gold}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </Link>
                ) : (
                    <div className="flex flex-col items-center col-span-4 w-full grayscale">
                        <div className="mt-auto bg-gradient-to-t from-gray-500 to-gray-400 p-0.5 rounded-full w-11 h-11">
                            <div className="bg-primary-main p-0.5 rounded-full w-10 h-10">
                                <img src={AvatarUnknown} className="w-full h-full rounded-full" alt="" />
                            </div>
                        </div>
                        <span className="leading-4 mt-1.5">---</span>
                        <span className="text-cyan-600 leading-4">?</span>
                        <div className="mt-2 h-36 bg-gradient-to-b from-gray-500 to-gray-400 rounded-2xl w-full"></div>
                    </div>
                )}

                {/* Gold */}
                {goldPlayer ? (
                    <Link
                        to={`${AppRoutes.dashboard.userProfile}/${goldPlayer.userID}`}
                        className="flex flex-col items-center col-span-4 w-full"
                    >
                        <div className="mt-auto bg-gradient-to-t from-yellow-500 to-yellow-400 p-0.5 rounded-full w-11 h-11">
                            <div className="bg-primary-main p-0.5 rounded-full w-10 h-10">
                                <img src={goldPlayer.avatarUrl} className="w-full h-full rounded-full" alt="" />
                            </div>
                        </div>
                        <span className="leading-4 mt-1.5">{goldPlayer.username}</span>
                        <span className="text-cyan-600 leading-4">{tools.thousandsSeparator(goldPlayer.score)}</span>
                        <div className="mt-2 h-44 bg-gradient-to-t from-yellow-600 to-yellow-500 rounded-2xl w-full">
                            <div className="flex mt-6 justify-center scale-150">
                                <div>{tools.thousandsSeparator(goldPlayer.prize)}</div>
                                <div>
                                    <img
                                        className="inline mx-1"
                                        src={goldPlayer.prizeType === 1 ? Silver : Gold}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </Link>
                ) : (
                    <div className="flex flex-col items-center col-span-4 w-full grayscale">
                        <div className="mt-auto bg-gradient-to-t from-yellow-500 to-yellow-400 p-0.5 rounded-full w-11 h-11">
                            <div className="bg-primary-main p-0.5 rounded-full w-10 h-10">
                                <img src={AvatarUnknown} className="w-full h-full rounded-full" alt="" />
                            </div>
                        </div>
                        <span className="leading-4 mt-1.5">---</span>
                        <span className="text-cyan-600 leading-4">?</span>
                        <div className="mt-2 h-44 bg-gradient-to-t from-yellow-600 to-yellow-500 rounded-2xl w-full"></div>
                    </div>
                )}
                {/* Bronze */}
                {bronzePlayer ? (
                    <Link
                        to={`${AppRoutes.dashboard.userProfile}/${bronzePlayer.userID}`}
                        className="flex flex-col items-center col-span-4 w-full"
                    >
                        <div className="mt-auto bg-gradient-to-t from-orange-500 to-orange-400 p-0.5 rounded-full w-11 h-11">
                            <div className="bg-primary-main p-0.5 rounded-full w-10 h-10">
                                <img src={bronzePlayer.avatarUrl} className="w-full h-full rounded-full" alt="" />
                            </div>
                        </div>
                        <span className="leading-4 mt-1.5">{bronzePlayer.username}</span>
                        <span className="text-cyan-600 leading-4">{tools.thousandsSeparator(bronzePlayer.score)}</span>
                        <div className="mt-2 h-24 bg-gradient-to-t from-orange-600 to-orange-500 rounded-2xl w-full">
                            <div className="flex mt-6 justify-center scale-150">
                                <div>{tools.thousandsSeparator(bronzePlayer.prize)}</div>
                                <div>
                                    <img
                                        className="inline mx-1"
                                        src={bronzePlayer.prizeType === 1 ? Silver : Gold}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </Link>
                ) : (
                    <div className="flex flex-col items-center col-span-4 w-full grayscale">
                        <div className="mt-auto bg-gradient-to-t from-orange-500 to-orange-400 p-0.5 rounded-full w-11 h-11">
                            <div className="bg-primary-main p-0.5 rounded-full w-10 h-10">
                                <img src={AvatarUnknown} className="w-full h-full rounded-full" alt="" />
                            </div>
                        </div>
                        <span className="leading-4 mt-1.5">---</span>
                        <span className="text-cyan-600 leading-4">?</span>
                        <div className="mt-2 h-24 bg-gradient-to-t from-orange-600 to-orange-500 rounded-2xl w-full"></div>
                    </div>
                )}
            </div>

            {data.total.slice(3, 6).map((_, index) => (
                <LeaderBoardItem
                    key={`leaderboard-item-${_.rank}`}
                    item={_}
                    listNumber={index + 4}
                    isHighlighted={_.userID === currentUser?.ID}
                />
            ))}
            {goldPlayer && silverPlayer && bronzePlayer && (
                <div className="flex items-center justify-center gap-2 mt-10 underline">
                    <Link
                        to={`${AppRoutes.dashboard.tournament.leaderBoard}/${tournament?.tournamentId}`}
                        className="text-white"
                    >
                        <Trans>Full List</Trans>
                    </Link>
                    {i18n.language === "fa" ? <ArrowLeft /> : <ArrowRight />}
                </div>
            )}
        </>
    )
}

export { LeaderBoard }
