import { useNavigate, useParams } from "react-router-dom"
import { AvatarUnknown, AwaitingPageBg } from "core/utilities/ImageImport"
import { ArrowLeft } from "core/utilities/Icons"
import { Row } from "antd"
import { PlayersVersus } from "components/template/PlayersVersus"
import { AppRoutes } from "core/utilities/AppRoutes"
import { Layout } from "components/template/Layout"
import { useActivity } from "core/providers/ActivityProvider"
import { useEffect, useState } from "react"
import { ActivityGame } from "core/api/types/ActivityTypes"
import { useApi } from "core/api/ApiProvider"
import { useApp } from "core/providers/AppProvider"
import { ScreenLoader } from "components/template"
import { t } from "i18next"
import { Trans } from "react-i18next"
const Awaiting = () => {
    // States and Hooks
    const navigate = useNavigate()
    const { match_id: matchID } = useParams()
    const { games } = useApi()
    const { currentUser, loadingApp } = useApp()
    const { isActivity } = useActivity()
    const [activity, setActivity] = useState<ActivityGame | null>(null)
    const loadingActivity: boolean = activity === null
    // Methods
    const fetchActivity = async () => {
        const { data, error } = await games.getMatchByID({
            ID: currentUser?.ID,
            matchID: matchID,
        })

        if (data) setActivity(data)
    }

    const initialize = async () => {
        fetchActivity()
    }

    useEffect(() => {
        if (!loadingApp) initialize()
    }, [loadingApp])

    const user = {
        id: currentUser?.ID === activity?.data?.playerOneId ? activity?.data?.playerOneId : activity?.data?.playerTwoId,
        username:
            currentUser?.ID === activity?.data?.playerOneId
                ? activity?.data?.playerOneName
                : activity?.data?.playerTwoName,
        score:
            currentUser?.ID === activity?.data?.playerOneId
                ? activity?.data?.playerOneScore
                : activity?.data?.playerTwoScore,
        avatarUrl:
            currentUser?.ID === activity?.data?.playerOneId
                ? activity?.data?.playerOneAvatar
                : activity?.data?.playerTwoAvatar,
    }

    if (loadingActivity) return <ScreenLoader />
    // Render
    return (
        <Layout showHeader={false} fit={true} showControlCapsule={false} customBackground={true}>
            <img src={AwaitingPageBg} className="absolute top-0 left-0 h-screen w-screen -z-50" alt="background" />
            <div className="flex flex-col">
                <div className="flex flex-col px-5 py-3  h-screen">
                    <nav className="flex justify-center items-center w-full ltr py-4 relative z-30">
                        <button
                            className="absolute left-0 bg-slate-200 bg-opacity-20 p-2 rounded-full"
                            onClick={() =>
                                navigate(
                                    isActivity
                                        ? AppRoutes.dashboard.activity
                                        : `${AppRoutes.dashboard.game.home}/${activity?.data?.gameClientID}`
                                )
                            }
                        >
                            <ArrowLeft className="w-5 h-5" />
                        </button>
                        <span className="bg-slate-200 bg-opacity-50 rounded-full text-center px-4 py-1">
                            {activity?.data?.game.name}
                        </span>
                    </nav>

                    <Row justify="center" className="mt-16">
                        <h2 className="text-2xl font-bold text-white">
                            <Trans>Waiting Opponent</Trans>
                        </h2>
                    </Row>

                    <Row justify="center" className="mt-auto px-2 mb-8">
                        <PlayersVersus
                            user={{
                                id: user.id,
                                username: user.username ?? "",
                                score: user.score ?? 0,
                                avatarUrl: user.avatarUrl ?? "",
                            }}
                            competitor={{
                                username: "?",
                                desc: t("Waiting Opponent").toString(),
                                avatarUrl: AvatarUnknown,
                            }}
                        />
                    </Row>
                </div>
            </div>
        </Layout>
    )
}

export default Awaiting
