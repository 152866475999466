import React from "react"
import { Avatar, Skeleton } from "antd"
import { tools } from "core/functions/tools"
import { t } from "i18next"
import { useApp } from "core/providers/AppProvider"

type PlayersData = {
    avatarImageUrls: string[]
    playCount: number
}
interface Props extends React.HTMLProps<HTMLDivElement> {
    title: string
    titleSize?: "xs" | "sm" | "base" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | "7xl" | "8xl" | "9xl"
    imageUrl: string
    gameUrl: string
    imageClassName?: string
    playersData?: PlayersData
}

interface IGameCard extends React.FC<Props> {
    Skeleton: React.FC<{}>
}

const GameCard: IGameCard = ({
    title,
    imageUrl,
    gameUrl,
    className,
    imageClassName,
    playersData,
    titleSize = "base",
    ...rest
}) => {
    // States and Hooks
    const { lang } = useApp()

    // Render
    return (
        <div className={`flex flex-col ${className}`} {...rest}>
            <img src={imageUrl} className={`object-cover rounded-xl mb-2 ${imageClassName}`} alt={`${title}-game`} />
            <span className={`text-white font-medium mr-1 text-${titleSize}`}>{t(title).toString()}</span>
            {playersData && (
                <div className={`flex items-center justify-end gap-2 mt-0.5 ${lang === "en" ? "rtl" : "ltr"}`}>
                    <div>{tools.thousandsSeparator(playersData.playCount)}</div>
                    <Avatar.Group>
                        {playersData.avatarImageUrls.map((src, index) => (
                            <Avatar size={"small"} key={`title-avatar-${index}`} src={src} />
                        ))}
                    </Avatar.Group>
                </div>
            )}
        </div>
    )
}

GameCard.Skeleton = () => (
    <>
        <div>
            <Skeleton.Image active className="rounded min-w-full" />
            <div className="mt-1 w-3/4">
                <Skeleton.Button size="small" block active />
            </div>
        </div>
    </>
)

export { GameCard }
