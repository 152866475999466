import { Divider } from "antd"
import { useNavigate } from "react-router-dom"
import { ArrowLeft } from "core/utilities/Icons"
import { Layout } from "components/template/Layout"
import { ProfileAvatar } from "components/template/Avatar"
import { GameScoreCard } from "components/profile/GameScoreCard"
import { useUserProfile } from "core/providers/UserProfileProvider"
import { Trans } from "react-i18next"
import { t } from "i18next"
const UserProfile = () => {
    // States and Hooks
    const navigate = useNavigate()
    const { profile, loadingUserProfile } = useUserProfile()

    // Render
    return (
        <>
            <Layout showControlCapsule={false} showHeader={false}>
                <header className="fixed w-full lg:w-[430px] top-0 py-3 -mx-4 px-4 z-50">
                    <nav className="flex justify-start w-full ltr">
                        <button className="bg-primary-light p-2 rounded-full" onClick={() => navigate(-1)}>
                            <ArrowLeft className="w-5 h-5" />
                        </button>
                    </nav>
                </header>
                <div className="py-6"></div>
                <main className="mt-8">
                    {loadingUserProfile ? (
                        <ProfileAvatar.Skeleton />
                    ) : (
                        <ProfileAvatar
                            username={profile?.username ?? ""}
                            imageUrl={profile?.avatar ?? ""}
                            scoreData={{
                                current: profile?.xpProgress.value ?? 0,
                                nextLevel: profile?.xpProgress.maxValue ?? 200,
                            }}
                            className="w-40 h-40"
                            notificationCount={profile?.level ?? 0}
                            disabled={true}
                        />
                    )}
                    {profile?.games !== null && (
                        <div className="bg-primary-light mt-8 rounded-2xl p-4 ">
                            <h3 className="text-xl text-center text-white">
                                <Trans>Games Stats</Trans>
                            </h3>
                            {loadingUserProfile
                                ? [1, 2, 3].map(_ => (
                                      <div key={_} className="my-2">
                                          <GameScoreCard.Skeleton />
                                      </div>
                                  ))
                                : profile?.games?.map((game, index, arr) => (
                                      <div key={`game-score-${index}`}>
                                          <GameScoreCard
                                              title={game.name}
                                              titleSize="lg"
                                              description={t("High Score").toString()}
                                              score={game.highScore}
                                              level={game.rank}
                                              imageUrl={game.icon || ""}
                                              className="mt-4"
                                              imageClassName="w-20"
                                              rankPicUrl={game.rankPicUrl}
                                              rankName={game.rankName}
                                          />
                                          {index !== arr.length - 1 && <Divider className="bg-slate-600" />}
                                      </div>
                                  ))}
                        </div>
                    )}
                    {profile?.games === null && (
                        <div className="text-center mt-10 text-base">اطلاعاتی برای نمایش وجود ندارد</div>
                    )}
                </main>
            </Layout>
        </>
    )
}

export default UserProfile
