import { Layout } from "components/template/Layout"
import { useShop } from "core/providers/ShopProvider"
import { useEffect, useState } from "react"
import { useApp } from "core/providers/AppProvider"
import { PaymentStatus } from "components/shop"
import { ScreenLoader } from "components/template"

const ShopVerify = () => {
    // States and Hooks
    const { loadingApp } = useApp()
    const { verifyPurchaseLoading, verifyPurchase } = useShop()
    const [isModalOpen, setIsModalOpen] = useState<boolean>(true)

    // Methods
    useEffect(() => {
        if (!loadingApp) verifyPurchase()
    }, [loadingApp])

    useEffect(() => {
        if (!verifyPurchaseLoading) setIsModalOpen(true)
    }, [verifyPurchaseLoading])

    // Render
    if (verifyPurchaseLoading) {
        return <ScreenLoader />
    }

    // Render
    return (
        <>
            <Layout showControlCapsule={false} showHeader={false} fit={true}>
                <PaymentStatus isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
            </Layout>
        </>
    )
}

export default ShopVerify
