import { Row } from "antd"
import Avatar from "antd/lib/avatar"
import Badge from "antd/lib/badge"
import { Col } from "antd/lib/grid"
import { Link } from "react-router-dom"
import { Gold, Silver } from "core/utilities/ImageImport"
import { ArrowLeft } from "core/utilities/Icons"
import { AppRoutes } from "core/utilities/AppRoutes"
import { useNavigate } from "react-router-dom"
import { useApp } from "core/providers/AppProvider"
import { tools } from "core/functions/tools"
const USER_AVATAR_SIZE = 50
interface Props {
    hasBackButton: boolean
    points?: {
        gold: number
        silver: number
    }
    avatarUrl?: string
}

const Header: React.FC<Props> = ({ points, hasBackButton = false, avatarUrl }) => {
    // States and Hooks
    const { lang } = useApp()
    const navigate = useNavigate()
    const { currentUser } = useApp()

    // Methods

    // Render
    return (
        <Row
            className="fixed w-full lg:w-[430px] top-0 bg-primary-main bg-opacity-0 px-4 py-2.5 z-50 rtl"
            justify="space-between"
            align="middle"
        >
            {avatarUrl && (
                <Col>
                    <Link to={AppRoutes.dashboard.profile}>
                        <Badge
                            count={
                                lang === "fa"
                                    ? currentUser?.level.toString().split("").reverse().join("")
                                    : currentUser?.level ?? 0
                            }
                            offset={[-USER_AVATAR_SIZE + 0.15 * USER_AVATAR_SIZE, "90%"]}
                            showZero
                        >
                            <Avatar src={currentUser?.avatarUrl} size={USER_AVATAR_SIZE} />
                        </Badge>
                    </Link>
                </Col>
            )}
            {points && (
                <Col>
                    <div className="py-1 px-1.5 rounded-full bg-slate-600">
                        <div className="flex gap-2.5">
                            <div className="flex gap-1">
                                <img src={Gold} alt="gold" />
                                <span className="font-semibold">
                                    {tools.thousandsSeparator(currentUser?.goldCoin ?? 0)}
                                </span>
                            </div>
                            <div className="flex gap-1">
                                <img src={Silver} alt="gold" />
                                <span className="font-semibold">
                                    {tools.thousandsSeparator(currentUser?.silverCoin ?? 0)}
                                </span>
                            </div>
                        </div>
                    </div>
                </Col>
            )}

            {hasBackButton && (
                <button onClick={() => navigate(-1)} className="bg-primary-light p-2 rounded-full">
                    <ArrowLeft className="w-5 h-5" />
                </button>
            )}
        </Row>
    )
}

export { Header }
