import { Col, Image, Row, Skeleton } from "antd"
import { Cup } from "core/utilities/ImageImport"
import { Trans } from "react-i18next"
interface Player {
    avatar: string
    score?: number
}

interface Props {
    gameImage: string
    title: string
    desc: string
    players: {
        user: Player
        competitor: Player
    }
    isTournament?: boolean
}

interface IOnGoingTournamentCard extends React.FC<Props> {
    Skeleton: React.FC<{}>
}

const OnGoingTournamentCard: IOnGoingTournamentCard = ({ gameImage, title, desc, players, isTournament }) => {
    return (
        <div className="flex flex-col px-3 py-4 bg-primary-light rounded-3xl w-full">
            <Row gutter={[10, 5]}>
                <Col span={4}>
                    <img className="object-cover w-full aspect-square rounded-xl" src={gameImage} alt="" />
                </Col>
                <Col className="grow">
                    <div className="flex items-center justify-between">
                        <div>
                            <h3 className="text-white text-lg">
                                <Trans>{title}</Trans>
                            </h3>
                            <div className="text-gray-300 text-xs text-start">
                                <Trans>{desc}</Trans>
                            </div>
                        </div>
                        <div className="w-7 h-7 mt-2 mr-6">
                            <Image src={Cup} alt="" />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

OnGoingTournamentCard.Skeleton = () => (
    <div className="flex flex-col bg-slate-600 rounded-3xl px-3 py-4">
        <div className="flex items-center gap-2 ">
            <Skeleton.Button style={{ width: "3rem", height: "3rem" }} shape="circle" active />
            <div className="flex flex-col w-full">
                <div className="w-1/2">
                    <Skeleton.Button size="small" active block />
                </div>
                <div className="w-1/4">
                    <Skeleton.Button className=" mt-1" size="small" active block />
                </div>
            </div>
        </div>
    </div>
)

export { OnGoingTournamentCard }
