import axios from "axios"
import axiosRetry from "axios-retry"

axiosRetry(axios, { retries: 5 })

const BaseApiUrl = process.env.REACT_APP_API_BASE_URL

type Args = {
    url: string
    method: string
    body: any
    headers: any
    auth?: {
        username: string
        password: string
    }
    signal?: any
    isWorldCup?: boolean
}

export const axiosRequest = async ({
    url,
    method,
    body = null,
    headers = null,
    auth = undefined,
    signal,
    isWorldCup = false,
}: Args) => {
    try {
        const response = await axios({
            url: `${BaseApiUrl}${url}`,
            method: method,
            data: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                ...headers,
            },
            auth,
            signal,
            timeout: 10000,
        })

        if (response) {
            if ("error" in response.data)
                return {
                    error: response.data.error,
                    data: null,
                }
            return {
                error: null,
                data: response.data,
            }
        }
        return {
            error: "Something went wrong!",
            data: null,
        }
    } catch (err: any) {
        return {
            error: err.message,
            errorObj: err,
            data: null,
        }
    }
}
