import { Divider, Row } from "antd"
import { Trans, useTranslation } from "react-i18next"
import i18n from "../../i18n"

interface GameInfo {
    gameId: string
    gameName: string
    rankingData: {
        rank: string
        username: string
    }[]
    reward: number
    entranceFee: number
    gameMode: string
    time: Date
}

interface Props extends GameInfo {
    className?: React.HTMLProps<HTMLDivElement>["className"]
}

const GameResultInfo: React.FC<Props> = ({
    gameName,
    rankingData,
    reward,
    entranceFee,
    gameMode,
    time,
    gameId,
    className,
}) => {
    const { t } = useTranslation()

    const timeText = new Date(time).toLocaleString(i18n.language === "fa" ? "fa-IR" : "en-US", {
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    })

    const rankingFields = rankingData.map(rankingObj => ({ name: rankingObj.rank, value: rankingObj.username }))

    const infoFields = [
        {
            name: t("Game Name"),
            value: gameName,
        },
        ...rankingFields,
        {
            name: t("Reward"),
            value: `${reward} ${t("Coin")}`,
        },
        {
            name: t("Entrance"),
            value: `${entranceFee} ${t("Coin")}`,
        },
        {
            name: t("Game Mode"),
            value: gameMode,
        },
        {
            name: t("Time"),
            value: timeText,
        },
        {
            name: t("Game ID"),
            value: gameId,
        },
    ]

    return (
        <div className={`flex flex-col text-base ${className ?? " "}`}>
            <h3 className="text-2xl text-white text-center font-bold mb-8">
                <Trans>Game Stats</Trans>
            </h3>

            {infoFields.map((fieldObj, index) => (
                <div key={`info-field-${index}`}>
                    <Row justify="space-between">
                        <span className="text-slate-400">{fieldObj.name}</span>
                        <span>{fieldObj.value}</span>
                    </Row>

                    {/* Remove last divider */}
                    {index !== infoFields.length - 1 && <Divider className="bg-slate-600 my-3" />}
                </div>
            ))}
        </div>
    )
}

export { GameResultInfo }
