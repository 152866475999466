import { Spin } from "antd"

const ScreenLoader = () => {
    // Methods
    return (
        <div className="min-h-screen w-full flex flex-col items-center justify-center">
            <Spin size="large" />
        </div>
    )
}

export { ScreenLoader }
