import { Button } from "antd"
import React, { useState } from "react"
import { CheckIcon } from "core/utilities/Icons"
import { useApp } from "core/providers/AppProvider"
import { useGame } from "core/providers/GameProvider"
import { Gold, Silver } from "core/utilities/ImageImport"
import { Trans } from "react-i18next"

interface Props {}

const Qualification: React.FC<Props> = () => {
    // States and Hooks
    const { userGameModes, game, startGame } = useGame()
    const { setCurrentGame } = useApp()
    const [loading, setLoading] = useState<boolean>(false)
    const mode = userGameModes.filter(mode => mode.isActive === true)[0]

    // Methods

    // Render
    return (
        <div className="flex items-center w-full bg-gray-500 backdrop-blur-sm bg-opacity-50 py-4 px-6 rounded-3xl mt-4 ltr">
            <div className="w-full">
                <div className="flex justify-between items-center">
                    <div className="text-lg font-semibold">
                        <Trans>Trial</Trans>
                    </div>
                    <div className="backdrop-blur-sm bg-gray-500 px-5 py-1 rounded-full">
                        <div className="flex justify-between items-center gap-2">
                            <div className="text-base">{mode.rewards[0].amount}</div>
                            <div>
                                <img src={mode.rewards[0].type === 1 ? Silver : Gold} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="py-6 flex justify-between items-center rtl">
                    {Array.from({ length: mode.maxValue - mode.value }).map((item, index) => {
                        return (
                            <span className="p-3 rounded-full bg-gray-500" key={index}>
                                <span className="invisible">
                                    <CheckIcon />
                                </span>
                            </span>
                        )
                    })}
                    {Array.from({ length: mode.value }).map((item, index) => {
                        return (
                            <span className="p-3 rounded-full bg-gradient-to-r from-green-500 to-green-400" key={index}>
                                <CheckIcon />
                            </span>
                        )
                    })}
                </div>

                <div className="mt-1">
                    <Button
                        onClick={async () => {
                            setLoading(true)
                            setCurrentGame(game)
                            await startGame({ rank: mode.rank, entranceFee: mode.entrance })
                            setLoading(false)
                        }}
                        type="primary"
                        loading={loading}
                        disabled={loading}
                        className="flex items-center justify-center gap-1.5 bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600  border-0 rounded-2xl h-12 text-lg w-full hover:from-blue-400 hover:via-blue-500 hover:to-blue-600 hover:bg-gradient-to-r"
                    >
                        <Trans>Start</Trans>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export { Qualification }
