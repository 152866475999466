import { Button } from "antd"
import { Link } from "react-router-dom"
import { Layout } from "components/template/Layout"
import { AppRoutes } from "core/utilities/AppRoutes"
import { useAuth } from "core/providers/AuthProvider"
import { useEffect, useRef, useState } from "react"
import { Trans, useTranslation } from "react-i18next"

const ConfirmCode = () => {
    // States and Hooks
    const timer: any = useRef()
    const [timeLeft, setTimeLeft] = useState<number>(120)
    const { onConfirmButtonClick, loginButtonLoading, confirmCode, setConfirmCode } = useAuth()
    const [isEditButtonVisible, setIsEditButtonVisible] = useState<boolean>(false)
    const minutesLeft = Math.floor(timeLeft / 60)
    const secondsLeft = timeLeft % 60
    const { t } = useTranslation()

    useEffect(() => {
        if (timeLeft === 0) return setIsEditButtonVisible(true)

        timer.current = setInterval(() => {
            if (timeLeft > 0) setTimeLeft(prev => prev - 1)
        }, 1000)

        return () => clearInterval(timer.current)
    }, [timeLeft])

    // Render
    return (
        <Layout showHeader={false} showControlCapsule={false} fit={true}>
            <div className="flex flex-col min-h-screen items-center p-8">
                <h3 className="text-3xl text-white mb-4">
                    <Trans>Enter Sent Code</Trans>
                </h3>
                <div className="w-1/3 h-1 rounded-full bg-gradient-to-tr from-blue-500 to-sky-400"></div>
                <input
                    type="tel"
                    pattern="[0-9]*"
                    placeholder={t("Enter Code").toString()}
                    value={confirmCode}
                    className="text-white mt-8 px-4 py-3 border-white border border-solid bg-transparent rounded-xl w-full focus:outline-none text-center"
                    onChange={e => setConfirmCode(e.target.value)}
                />
                <Button
                    className="w-full bg-gradient-to-tr from-blue-600  to-sky-400  border-0 rounded-xl h-12 mt-3 text-2xl"
                    type="primary"
                    disabled={loginButtonLoading}
                    onClick={() => onConfirmButtonClick()}
                >
                    <Trans>Confirm</Trans>
                </Button>
                <div className="flex w-full justify-between mt-2">
                    <span className="text-base">
                        <Trans>Time Until Resend</Trans>
                    </span>
                    <span className="text-base">
                        0{minutesLeft}:{secondsLeft < 10 ? "0" + secondsLeft : secondsLeft}
                    </span>
                </div>
                {isEditButtonVisible && (
                    <>
                        <div className="h-1.5 w-full bg-secondary-main rounded-full mt-14"></div>
                        <Link
                            to={AppRoutes.login}
                            replace={true}
                            type="text"
                            className=" text-center border-white  my-3 h-12 border-0"
                        >
                            <span className="text-2xl text-secondary-main">
                                <Trans>Edit Phone Number</Trans>
                            </span>
                        </Link>
                    </>
                )}
            </div>
        </Layout>
    )
}

export default ConfirmCode
