import { type } from "@testing-library/user-event/dist/type"

export type GameType = {
    ID: number
    CreatedAt: string
    UpdatedAt: string
    DeletedAt: any
    name: string
    clientId: string
    gamePath: string
    imagePath: string
    bannerPath: string
    modes: any
    isAvailable: boolean
    playCount: number
    avatars: string[]
    tutorials: string[]
}

export type UserGameModeType = {
    ID: number
    CreatedAt: string
    UpdatedAt: string
    DeletedAt: string | null
    rank: number
    type: number
    trophyReward: number
    loseTrophy: number
    winXp: number
    loseXp: number
    rewards: GameRewardType[]
    entrance: number
    entranceType: number
    isAvailable: boolean
    isActive: boolean
    value: number
    maxValue: number
}

export type ActivityGameModeType = {
    ID: number
    rank: number
    type: number
    winXp: number
    loseXp: number
    rewards: GameRewardType[]
    entrance: number
    CreatedAt: string
    DeletedAt: string | null
    UpdatedAt: string
    loseTrophy: number
    isAvailable: boolean
    entranceType: number
    trophyReward: number
}

export type GameRewardType = {
    type: number
    amount: string
}

export type UserType = {
    ID: number
    CreatedAt: string
    UpdatedAt: string
    DeletedAt: any
    deviceToken: string
    name: string
    tag: string
    avatarUrl: string
    googleId: string
    firebaseId: string
    phoneNumber: string
    silverCoin: number
    goldCoin: number
    xp: number
    level: number
    lastGames: GameType[]
    friends: any
    referralsCount: number
    xpProgress: {
        maxValue: number
        value: number
    }
    isNameChanged: boolean
}

export type UserGameLevelType = {
    min: number
    max: number
    rank: number
}

export enum GameLevelEnum {
    "QUALIFICATION",
    "TRAINING",
    "BRONZE",
    "SILVER",
    "GOLD",
}

export type GameRankDataType = {
    currentRank: number
    currentTrophy: number
    maxValue: number
    progressValue: number
    ranks: [
        {
            ID: number
            CreatedAt: string
            UpdatedAt: string
            DeletedAt: string | null
            key: string
            type: number
            icon: string
            minTrophy: number
            maxTrophy: number
        }
    ]
}

export type GameResultType = {
    ID: number
    gameId: number
    matchId: string
    playerOneId: number
    playerOneName: string
    playerTwoId: number
    playerTwoName: string
    winnerId: number
    playerOneScore: number
    playerTwoScore: number
    playerOneEndGame: boolean
    playerTwoEndGame: boolean
    rank: number
}

export type LeaderBoardType = {
    avatar: string
    gameRank: number
    gold: number
    rank: number
    trophy: number
    userId: number
    username: string
}
