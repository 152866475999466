interface Props extends React.HTMLProps<HTMLDivElement> {
    isOpen: boolean
    setIsOpen: Function
    children: React.ReactNode
}

const Drawer: React.FC<Props> = ({ children, isOpen, setIsOpen, className, ...rest }) => {
    return (
        <div className="flex flex-col">
            <button
                onClick={() => setIsOpen(false)}
                className={`flex flex-col fixed h-full w-full bg-black opacity-50 p-0 border-0 z-40 ${
                    isOpen ? "top-0" : "-top-full"
                }`}
            ></button>
            <div
                {...rest}
                className={`flex flex-col fixed  h-1/2 w-full transition-all duration-300 ease-in-out z-50  ${
                    isOpen ? "-bottom-1" : "-bottom-full"
                } ${className}`}
            >
                {children}
            </div>
        </div>
    )
}

export { Drawer }
