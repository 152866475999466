interface Props extends React.HTMLProps<HTMLDivElement> {
    children: React.ReactNode
}

const StepIndicator: React.FC<Props> = ({ children, className, ...rest }) => {
    return (
        <div {...rest} className={`flex items-center justify-center rounded-full w-8 h-8 z-10 ${className}`}>
            {children}
        </div>
    )
}

export { StepIndicator }
