import { Avatar, Row } from "antd"
import { tools } from "core/functions/tools"
import { AppRoutes } from "core/utilities/AppRoutes"
import { Link } from "react-router-dom"

interface PlayerGameResult {
    username: string
    avatarUrl: string
    score?: number
    desc?: string
    id?: number
}

interface Props {
    user: PlayerGameResult
    competitor: PlayerGameResult
}

const PlayersVersus: React.FC<Props> = ({ user, competitor }) => {
    return (
        <Row justify="space-between" align="middle" className="ltr w-full">
            <div className="flex flex-col gap-2 items-center justify-center text-center">
                {user.id ? (
                    <Link to={`${user.id ? `${AppRoutes.dashboard.userProfile}/${user.id}` : undefined}`}>
                        <Avatar className="w-20 h-20" src={user.avatarUrl} />
                    </Link>
                ) : (
                    <Avatar className="w-20 h-20" src={user.avatarUrl} />
                )}
                <span className="text-sm font-bold">{user.username}</span>
                {user.score && (
                    <span className="text-yellow-400 text-2xl font-semibold">
                        {tools.thousandsSeparator(user.score)}
                    </span>
                )}
                {user.desc && <span className="text-yellow-400 text-2xl font-semibold">{user.desc}</span>}
            </div>

            <span className="text-slate-400 text-3xl font-bold">:</span>

            <div className="flex flex-col gap-2 items-center justify-center text-center">
                {competitor.id ? (
                    <Link to={`${AppRoutes.dashboard.userProfile}/${competitor.id}`}>
                        <Avatar className="w-20 h-20" src={competitor.avatarUrl} />
                    </Link>
                ) : (
                    <Avatar className="w-20 h-20" src={competitor.avatarUrl} />
                )}
                <span className="text-lg font-bold">{competitor.username}</span>
                {competitor.score && (
                    <span className="text-yellow-400 text-2xl font-semibold">
                        {tools.thousandsSeparator(competitor.score)}
                    </span>
                )}
                {competitor.desc && <span className="text-yellow-400 font-semibold">{competitor.desc}</span>}
            </div>
        </Row>
    )
}

export { PlayersVersus }
