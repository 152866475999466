import { Button, Col, Row } from "antd"
import { BlurBg } from "components/template/BlurBg"
import { useGame } from "core/providers/GameProvider"
import { Gold, Silver } from "core/utilities/ImageImport.js"
import { MouseEventHandler } from "react"
import { Trans } from "react-i18next"
interface Props {
    btnContent: React.ReactNode
    description?: string
    bgImageUrl: string
    rewardData?: {
        type: "gold" | "silver"
        amount: number
    }
    // status?: string
    isLocked?: boolean
    rank: number
    action?: MouseEventHandler<HTMLElement>
}

const StartGameCard: React.FC<Props> = ({
    btnContent,
    description,
    bgImageUrl,
    rewardData,
    // status,
    isLocked = false,
    rank,
    action = () => undefined,
}) => {
    // States and Hooks
    const { currentStartedGameRank, loadingStartGameButton } = useGame()

    // Methods

    // Render
    return (
        <div className="flex flex-col relative w-full aspect-[16/7] py-3 px-4">
            {/* This is background image */}
            <img
                className={`absolute top-0 left-0 w-full h-full rounded-3xl object-cover ${
                    isLocked && "filter grayscale"
                }`}
                src={bgImageUrl}
                alt="Earn coin background"
            />

            <Row justify="end" className="rtl">
                <Col>
                    {rewardData && (
                        <div className="flex items-center gap-1 z-10 ltr">
                            <span className="font-bold text-2xl mt-1">{rewardData.amount}</span>
                            <img className="w-7 h-7" src={rewardData.type === "gold" ? Gold : Silver} alt="coin" />
                            <span className="mt-2 ml-1">
                                <Trans>Winner Prize</Trans>
                            </span>
                        </div>
                    )}
                </Col>
            </Row>

            <div className="flex flex-col mt-auto w-full relative">
                {description && description.length > 1 && (
                    <span className="relative z-10 w-fit px-2 py-1 rounded-full text-xs backdrop-blur-lg bg-gray-500 bg-opacity-20">
                        {description}
                    </span>
                )}
                <BlurBg className="bg-white bg-opacity-20 rounded-2xl" />
                <Button
                    className="w-full flex justify-center items-center h-11 gap-3 rounded-2xl"
                    type="text"
                    loading={rank === currentStartedGameRank && loadingStartGameButton && !isLocked}
                    disabled={rank === currentStartedGameRank && loadingStartGameButton && !isLocked}
                    onClick={action}
                >
                    <div>{btnContent}</div>
                </Button>
            </div>
        </div>
    )
}

export { StartGameCard }
