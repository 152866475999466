import { useState } from "react"
import { Avatar, Button, Divider } from "antd"
import { Layout } from "components/template/Layout"
import { ArrowLeft, ChevronLeft, ChevronRight, Logout } from "core/utilities/Icons"
import { useNavigate } from "react-router"
import ExitConfirmationModal from "components/settings/ExitConfirmationModal"
import { useApp } from "core/providers/AppProvider"
import { ChangeNameModal } from "components/settings/ChangeNameModal"
import { Trans } from "react-i18next"
import i18n from "../i18n"

const Settings = () => {
    // States and Hooks
    const navigate = useNavigate()
    const { currentUser, isGuest } = useApp()
    const [isExitModalOpen, setIsExitModalOpen] = useState<boolean>(false)
    const [showChangeNameModal, setShowChangeNameModal] = useState(false)

    // Render
    return (
        <>
            <ChangeNameModal open={showChangeNameModal} onClose={() => setShowChangeNameModal(false)} />
            <Layout showHeader={false} showControlCapsule={false}>
                <ExitConfirmationModal isOpen={isExitModalOpen} setIsOpen={setIsExitModalOpen} />
                <header className="absolute w-full top-0 py-3 -mx-4 px-4 bg-transparent bg-opacity-95 z-50 ">
                    <nav className="flex w-full ltr">
                        <button className="bg-primary-light p-2 rounded-full" onClick={() => navigate(-1)}>
                            <ArrowLeft className="w-5 h-5" />
                        </button>
                    </nav>
                </header>

                <div className="py-16"></div>

                <main>
                    <h3 className="text-lg text-white">
                        <Trans>Profile Settings</Trans>
                    </h3>
                    <div className="bg-primary-light mt-4 rounded-2xl p-4">
                        <h3 className="text-center text-lg text-white">
                            <Trans>General Settings</Trans>
                        </h3>
                        {!isGuest && (
                            <div
                                className="flex justify-between py-1 mt-4"
                                onClick={() => setShowChangeNameModal(true)}
                            >
                                <div className="flex items-center gap-2 ">
                                    <Avatar className="w-7 h-7" src={currentUser?.avatarUrl} />
                                    <span className="text-base ">
                                        <Trans>Nickname</Trans>
                                    </span>
                                </div>
                                {i18n.language === "fa" ? (
                                    <ChevronLeft className="w-5 h-5 my-auto" />
                                ) : (
                                    <ChevronRight className="w-5 h-5 my-auto" />
                                )}
                            </div>
                        )}
                        <Divider className="my-2 bg-slate-600" />
                        {/* <div className="flex justify-between py-1 ">
                            <div className="flex items-center gap-2 ">
                                <BellSolid className="w-7 h-7 fill-slate-400" />
                                <span className="text-base ">اعلان ها</span>
                            </div>
                            <Switch
                                checked={isNotificationEnabled}
                                className={`${isNotificationEnabled ? "bg-purple-500" : "bg-slate-400"}`}
                                onChange={() => setIsNotificationEnabled(!isNotificationEnabled)}
                            />
                        </div> */}
                    </div>

                    {/* <div className="bg-primary-light mt-8 rounded-2xl p-4">
                        <h3 className="text-center text-lg text-white">صداها</h3>
                        <div className="flex justify-between py-1 mt-4">
                            <div className="flex items-center gap-2 ">
                                <MusicNoteSolid className="w-7 h-7 fill-slate-400" />
                                <span className="text-base ">صداهای بازی</span>
                            </div>
                            <Switch
                                checked={isGameSoundsEnabled}
                                className={`${isGameSoundsEnabled ? "bg-purple-500" : "bg-slate-400"}`}
                                onChange={() => setIsGameSoundsEnabled(!isGameSoundsEnabled)}
                            />
                        </div>
                    </div> */}

                    {/* <div className="bg-primary-light mt-8 rounded-2xl p-4">
                        <h3 className="text-center text-lg text-white">پشتیبانی</h3>

                        <div className="flex justify-between py-1 mt-4">
                            <div className="flex items-center gap-2 ">
                                <InformationCircleSolid className="w-7 h-7 fill-slate-400" />
                                <span className="text-base ">درباره ما</span>
                            </div>
                            <ChevronLeft className="w-5 h-5 my-auto" />
                        </div>

                        <Divider className="my-2 bg-slate-600" />

                        <div className="flex justify-between py-1">
                            <div className="flex items-center gap-2 ">
                                <InformationCircleSolid className="w-7 h-7 fill-slate-400" />
                                <span className="text-base ">چطور بازی کنیم</span>
                            </div>
                            <ChevronLeft className="w-5 h-5 my-auto" />
                        </div>

                        <Divider className="my-2 bg-slate-600" />

                        <div className="flex justify-between py-1">
                            <div className="flex items-center gap-2 ">
                                <QuestionMarkCircleSolid className="w-7 h-7 fill-slate-400" />
                                <span className="text-base ">پشتیبانی</span>
                            </div>
                            <ChevronLeft className="w-5 h-5 my-auto" />
                        </div>

                        <Divider className="my-2 bg-slate-600" />

                        <div className="flex justify-between py-1">
                            <div className="flex items-center gap-2 ">
                                <ExclamationSolid className="w-7 h-7 fill-slate-400" />
                                <span className="text-base ">گزارش مورد</span>
                            </div>
                            <ChevronLeft className="w-5 h-5 my-auto" />
                        </div>

                        <Divider className="my-2 bg-slate-600" />

                        <div className="flex justify-between py-1">
                            <div className="flex items-center gap-2 ">
                                <LockClosedSolid className="w-7 h-7 fill-slate-400" />
                                <span className="text-base ">حریم شخصی</span>
                            </div>
                            <ChevronLeft className="w-5 h-5 my-auto" />
                        </div>

                        <Divider className="my-2 bg-slate-600" />

                        <div className="flex justify-between py-1">
                            <div className="flex items-center gap-2 ">
                                <BookOpenSolid className="w-7 h-7 fill-slate-400" />
                                <span className="text-base ">قوانین و شرایط استفاده</span>
                            </div>
                            <ChevronLeft className="w-5 h-5 my-auto" />
                        </div>
                    </div> */}

                    <div className="flex flex-col gap-4 mt-8">
                        <Button
                            className="flex  justify-center items-center gap-1 bg-primary-light rounded-full border-0 w-full p-6"
                            type="primary"
                            onClick={() => setIsExitModalOpen(true)}
                        >
                            <Logout className="w-7 h-7 stroke-slate-400" />
                            <span>
                                <Trans>Exit</Trans>
                            </span>
                        </Button>

                        {/* <Button className="text-center w-fit mx-auto" type="text">
                            <Trash className="w-6 h-6 stroke-slate-400 mx-1" />
                            <span>پاک کردن اکانت</span>
                        </Button> */}
                    </div>

                    {/* <h5 className="mt-6 text-slate-400 text-center">نسخه 1.6.0</h5> */}
                </main>
            </Layout>
        </>
    )
}

export default Settings
