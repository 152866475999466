import React from "react"
import { ShimmerElement } from "./ShimmerElement"

interface Props {}

const GameGridSkeleton: React.FC<Props> = () => {
    // States and Hooks

    // Methods

    // Render
    return (
        <div className="skeleton-wrapper p-0 dark bg-transparent">
            <div className="grid grid-cols-2 gap-6 mt-2">
                {[1, 2, 3, 4].map(_ => {
                    return (
                        <div key={_}>
                            <ShimmerElement type="square" />
                            <div className="mt-2">
                                <ShimmerElement type="title" />
                            </div>
                            <div className="mt-2">
                                <ShimmerElement type="text" />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export { GameGridSkeleton }
