import { Col, Row } from "antd"
import { useGame } from "core/providers/GameProvider"
import { Cup, HomeActive } from "core/utilities/ImageImport"
import { FC } from "react"
import { Trans } from "react-i18next"

interface Props {}

const LeaderBoardSwitch: FC<Props> = ({}) => {
    // States and Hooks
    const { tab, setTab } = useGame()

    // Render
    return (
        <div className="mt-8 mb-6">
            <Row justify="center" align="middle" gutter={16} className="rtl">
                <Col span={10} className="relative">
                    <button
                        onClick={() => setTab("LEADER_BOARD")}
                        className={`flex items-center justify-center gap-2  rounded-xl border-0 h-10 w-full relative ${
                            tab === "LEADER_BOARD" ? "bg-blue-400" : "bg-slate-700 opacity-40"
                        }`}
                    >
                        <span className="text-base text-white">
                            <Trans>Leaderboard</Trans>
                        </span>
                        <img src={Cup} alt="Icon" className="w-4 h-4" />
                    </button>
                </Col>
                <Col span={10}>
                    <button
                        onClick={() => setTab("GAME")}
                        className={`flex items-center justify-center gap-2 rounded-xl border-0 h-10 w-full relative ${
                            tab === "GAME" ? "bg-blue-400" : "bg-slate-700 opacity-40"
                        }`}
                    >
                        <span className="text-base text-white">
                            <Trans>Game</Trans>
                        </span>
                        <img src={HomeActive} alt="Icon" className="w-4 h-4" />
                    </button>
                </Col>
            </Row>
        </div>
    )
}

export { LeaderBoardSwitch }
