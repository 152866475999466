import React from "react"
import { Gold, Silver, Score } from "core/utilities/ImageImport"

interface Props {
    scores: {
        gold: number
        silver: number
        score: number
    }
    className: React.HTMLAttributes<HTMLDivElement>["className"]
}

const EarnedRewardsPill: React.FC<Props> = ({ className, scores = { gold: 0, silver: 0, score: 0 } }) => {
    return (
        <div className={`flex justify-between items-center ltr rounded-full py-2 px-4 ${className}`}>
            <div className="flex items-center gap-1 ltr text-lg">
                <img className="inline w-6 h-6" src={Silver} alt="gold medal" />
                <span className="ltr">+{scores.silver}</span>
            </div>
            <div className="flex items-center gap-1 ltr text-lg">
                <img className="inline w-6 h-6" src={Gold} alt="gold medal" />
                <span className="ltr">+{scores.gold}</span>
            </div>
            <div className="flex items-center gap-1 ltr text-lg">
                <img className="inline w-6 h-6" src={Score} alt="gold medal" />
                <span className="ltr">+{scores.score}</span>
            </div>
        </div>
    )
}

export { EarnedRewardsPill }
