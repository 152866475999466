import { useApp } from "./AppProvider"
import { useApi } from "core/api/ApiProvider"
import { ActivityGame } from "core/api/types/ActivityTypes"
import { createContext, useContext, useEffect, useState } from "react"

type Props = {
    children: JSX.Element
}

interface ActivityContextInterface {
    loadingActivities: boolean
    awaitingGames: ActivityGame[] | null
    setAwaitingGames: Function
    finishedGames: ActivityGame[] | null
    setFinishedGames: Function
    isActivity: boolean
    setIsActivity: Function
}

const initialContextValue = {
    loadingActivities: true,
    awaitingGames: null,
    setAwaitingGames: () => undefined,
    finishedGames: null,
    setFinishedGames: () => undefined,
    isActivity: false,
    setIsActivity: () => undefined,
}

const ActivityContext = createContext<ActivityContextInterface>(initialContextValue)

export const useActivity = () => useContext(ActivityContext)

export const GameLevels = ["QUALIFICATION", "TRAINING", "BRONZE", "SILVER", "GOLD"]

const ActivityProvider: React.FC<Props> = ({ children }) => {
    // States and Hooks
    const { games } = useApi()
    const { currentUser, loadingApp } = useApp()
    const [isActivity, setIsActivity] = useState<boolean>(false)
    const [awaitingGames, setAwaitingGames] = useState<ActivityGame[] | null>(null)
    const [finishedGames, setFinishedGames] = useState<ActivityGame[] | null>(null)
    const loadingActivities: boolean = awaitingGames === null && finishedGames === null

    // Methods
    const fetchActivities = async () => {
        const { data, error } = await games.matches({ ID: currentUser?.ID })
        setAwaitingGames("awaiting" in data ? (data.awaiting ? data.awaiting : []) : [])
        setFinishedGames("finished" in data ? (data.finished ? data.finished : []) : [])
    }

    // UseEffects
    useEffect(() => {
        if (!loadingApp) fetchActivities()
    }, [loadingApp])

    // Binding
    const value = {
        // States
        loadingActivities,

        awaitingGames,
        setAwaitingGames,

        finishedGames,
        setFinishedGames,

        isActivity,
        setIsActivity,
        // Methods
    }

    // Render
    return <ActivityContext.Provider value={value}>{children}</ActivityContext.Provider>
}

export default ActivityProvider
