import { Image } from "antd"
import { Layout } from "components/template/Layout"
import { AppRoutes } from "core/utilities/AppRoutes"
import { ArrowLeft } from "core/utilities/Icons"
import { PrivacyPolicyBanner } from "core/utilities/ImageImport"
import { useNavigate } from "react-router"

const PrivacyPolicy = () => {
    // States and Hooks
    const navigate = useNavigate()

    // Render
    return (
        <Layout showHeader={false} showControlCapsule={false} fit={true}>
            <div className="flex flex-col min-h-screen p-8">
                <nav className="flex justify-between items-center w-full ltr z-10 py-3">
                    <button
                        className="bg-white bg-opacity-30 backdrop-blur-lg p-2 rounded-full"
                        onClick={() => navigate(AppRoutes.login)}
                    >
                        <ArrowLeft className="w-5 h-5" />
                    </button>
                </nav>
                <div className="text-center mb-16">
                    <Image src={PrivacyPolicyBanner} preview={false} />
                </div>
                <div className="text-xl font-bold text-fcenter">حریم خصوصی</div>
                <div className="text-xs text-justify mt-4">
                    <div className="flex flex-col gap-3">
                        <div>
                            رعایت حریم شخصی کاربران برای فانکرفت بسیار حائز اهمیت است. در این متن نحوۀ ذخیره‌سازی و
                            استفادۀ فانکرفت از اطلاعاتی که در نتیجه استفادۀ کاربران از خدمات فانکرفت ثبت می‌شود شرح داده
                            شده است.
                        </div>
                        <div>
                            جمع‌آوری اطلاعات اطلاعاتی که شما مستقیما در فانکرفت ثبت می‌کنید بخشی از اطلاعاتی که فانکرفت
                            ذخیره می‌کند اطلاعاتی است که شما به صورت مستقیم در فرآیند ثبت نام در سامانه ثبت می‌کنید. این
                            اطلاعات شامل شماره تلفن همراه و نام مستعار می‌باشد.
                        </div>
                        <div>
                            نحوه استفاده از خدمات فانکرفت ممکن است اطلاعات مربوط به نحوه استفاده کاربران از نرم‌افزار
                            موبایل و سایت فانکرفت را ثبت می‌کند. از این اطلاعات برای بهبود کیفیت خدمات استفاده می‌شود.
                        </div>
                        <div>
                            استفاده از اطلاعات اطلاعاتی که در فانکرفت ذخیره می‌شود در موارد زیر ممکن است مورد استفاده
                            قرار بگیرد و استفادۀ سرویس فانکرفت به منزلۀ قبول و اعطای اختیار به فانکرفت جهت استفاده و
                            بهره‌برداری از اطلاعات ذکر شده به شرح زیر می‌باشد: احراز هویت کاربر توسط استفاده کننده از
                            سرویس فانکرفت جهت اعطای جوایز دوره‌ای به برندگان ارتباط با سرویس‌های دیگر نظیر کلوپ جهت
                            یکپارچه‌سازی اطلاعات کاربران در این دو پلتفورم
                        </div>
                        <div>
                            تعهد داده می‌شود که این اطلاعات نزد توسعه‌دهنده محفوظ است، به هیچ عنوان از آن‌ها سواستفاده
                            نخواهد شد و در اختیار شخص یا سازمان ثالثی قرار نخواهد گرفت.
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default PrivacyPolicy
