import { Outlet } from "react-router-dom"
import AuthProvider from "../AuthProvider"
const AuthProviderLayout = () => {
    return (
        <AuthProvider>
            <Outlet />
        </AuthProvider>
    )
}

export default AuthProviderLayout
