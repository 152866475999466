import { Button } from "antd"
import { BoldCross } from "core/utilities/ImageImport"
import React from "react"
import { Modal } from "./Modal"

type Props = {
    msg: string | JSX.Element
    btnText?: string
    onClose: Function
}

const ErrorModal: React.FC<Props> = ({ msg, onClose, btnText = "باشه" }) => {
    return (
        <Modal onClose={onClose}>
            <div className="flex flex-col w-80 gap-8 items-center bg-gradient-to-tr from-red-400 to-red-600 border-b-2 border-red-800 rounded-4xl px-4 py-4 mx-auto">
                <div className="bg-white bg-opacity-25 p-2 rounded-full">
                    <img src={BoldCross} className="w-6 h-6" alt="" />
                </div>
                {typeof msg === "string" ? <span className="text-lg text-white font-bold">{msg}</span> : msg}
                <Button onClick={() => onClose()} size="large" className="rounded-xl w-3/5 bg-white ">
                    <span className="text-black text-base">{btnText}</span>
                </Button>
            </div>
        </Modal>
    )
}

export { ErrorModal }
