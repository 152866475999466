type Store = {
    [key: string]: {
        value: any
        expiry: Date
    }
}

type ReqAsyncFunc = () => Promise<
    | {
          error: any
          data: null
      }
    | {
          error: null
          data: any
      }
>

interface CacheInterface {
    store: Store
    get: (key: string) => any
    set: (key: string, value: any, expiry?: Date) => void
    bindReq: (req: ReqAsyncFunc, key: string, expiry: Date | undefined) => ReturnType<ReqAsyncFunc>
}

const ONE_MINUTE_MS = 1000 * 60 * 5

export default class Cache implements CacheInterface {
    constructor(public store: Store) {}
    get(key: string) {
        if (!this.store[key]) return undefined
        if (this.store[key].expiry.getTime() < new Date().getTime()) {
            delete this.store[key]
            return null
        }
        return this.store[key].value
    }
    set(key: string, value: any, expiry: Date = new Date(new Date().getTime() + ONE_MINUTE_MS)) {
        if (expiry.getTime() < new Date().getTime()) throw Error("Expiry date cannot be in the past")
        this.store[key] = {
            value,
            expiry,
        }
    }

    async bindReq(req: ReqAsyncFunc, key: string, expiry: Date = new Date(new Date().getTime() + ONE_MINUTE_MS)) {
        if (this.get(key)) return { data: this.store[key].value, error: null }

        const { data, error } = await req()
        if (error) return { data, error }
        this.store[key] = {
            value: data,
            expiry,
        }
        return { data, error }
    }
}
