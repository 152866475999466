import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"

// Providers
import { useApp } from "core/providers/AppProvider"

// Utilities
import { Silver, VersusTournamentBottom, VersusTournamentTop } from "core/utilities/ImageImport"
import { AppRoutes } from "core/utilities/AppRoutes"

// Components
import { ScreenLoader } from "components/template"
import { Button, Row } from "antd"
import { ArrowLeft } from "core/utilities/Icons"
import { useApi } from "core/api/ApiProvider"
import { Link } from "react-router-dom"
import { LeaderBoard } from "components/tournament/game/LeaderBoard"
import { Trans } from "react-i18next"

const ResultTournament = () => {
    // States and Hooks
    const navigate = useNavigate()
    const { currentUser, loadingApp } = useApp()
    const { tournament_client_id: tournamentClientId } = useParams()
    const [activity, setActivity] = useState<any>(null)
    const { tournaments } = useApi()
    const loadingPage = activity === null
    const leaderBoard = activity?.data?.leaderBoard
    // Methods
    const fetchTournamentActivity = async () => {
        const { data } = await tournaments.getActivity({
            ID: currentUser?.ID,
            tournamentID: tournamentClientId,
        })
        setActivity(data)
    }

    useEffect(() => {
        if (!loadingApp) fetchTournamentActivity()
    }, [loadingApp])

    // Render
    if (loadingPage) return <ScreenLoader />
    return (
        <div className="flex flex-col h-full bg-gradient-to-b from-red-800 to-black">
            {/* Pointers */}
            <div className="absolute top-0 left-0 w-full h-full overflow-hidden versus-bg-upper-shape ltr animate-in-from-tl">
                <img
                    src={VersusTournamentTop}
                    className="transition-all scale-125 left-5 top-10 w-80"
                    alt="Versus Tournament Top"
                />
            </div>
            <div className="absolute bottom-0 w-full h-full overflow-hidden versus-bg-down-shape ltr animate-in-from-br">
                <img
                    src={VersusTournamentBottom}
                    className="absolute bottom-0 right-0 object-cover scale-125 w-72"
                    alt="Versus Tournament Bottom"
                />
            </div>

            {/* Header */}
            <div className={`flex relative items-center justify-between p-3 rtl`}>
                <div className="flex items-center justify-start w-40 p-1 mx-auto bg-black bg-opacity-50 rounded-full z-10 m-4">
                    <img
                        className="object-cover rounded-full w-7 h-7 "
                        src={activity.data.game.imagePath}
                        alt="Avatar"
                    />
                    <span className="flex items-center justify-center flex-1 text-sm">{activity.data.gameName}</span>
                </div>
                <button
                    className="bg-black bg-opacity-30 backdrop-blur-lg p-2 rounded-full"
                    onClick={() => navigate(AppRoutes.dashboard.activity, { replace: true })}
                >
                    <ArrowLeft className="w-5 h-5" />
                </button>
            </div>

            <Row justify="center" className="mt-16 z-10">
                <h2 className="text-2xl font-bold text-white">
                    <Trans
                        i18nKey={"You Placed"}
                        values={{
                            rank: activity.data.rank,
                        }}
                    />
                </h2>
            </Row>

            <Row justify="center" className="mt-10">
                <Link to={`${AppRoutes.dashboard.profile}`}>
                    <div className="rounded-full bg-white bg-opacity-20 backdrop-blur-sm p-3.5">
                        <div className="rounded-full bg-gradient-to-tr from-yellow-600 to-yellow-500 p-1">
                            <img className="rounded-full w-40 h-40 object-cover" src={currentUser?.avatarUrl} alt="" />
                        </div>
                    </div>
                </Link>
            </Row>

            <div className="flex justify-center items-center rounded-xl px-4 mt-10">
                {/* <VolumeUp /> */}
                <div className="flex justify-between items-center bg-black bg-opacity-50 px-6 py-4 rounded-full">
                    <span className="text-2xl">
                        <Trans>Winner Prize</Trans>
                    </span>

                    <span className="text-2xl ml-2 mr-1">{100}</span>
                    <img src={Silver} className="w-8 h-8" alt="" />
                </div>
            </div>
            <div className="z-10 flex flex-col items-center justify-between h-full p-4">
                {/* Reward */}
                {/* <div className="flex flex-col items-center mt-20 gap-y-2">
                    <span className="text-base">مجموع جوایز</span>
                    <div className="flex items-center justify-center mt-1">
                        <span className="ml-3 mr-2 text-2xl mt-1">
                            {tournament?.prizePools.reduce((t, o) => (t += o.reward.amount), 0)}
                        </span>
                        <img
                            className="h-8 w-8"
                            src={tournament?.prizePools[0].reward.type === 1 ? Silver : Gold}
                            alt="Coin"
                        />
                    </div>
                </div> */}

                {/* LeaderBoard */}
                <div className="w-full">
                    <div className="px-4 mt-4">
                        <LeaderBoard data={leaderBoard} />
                    </div>
                </div>

                {/* Navigation */}
                <Button
                    type="text"
                    size="large"
                    className="bg-gradient-to-r text-white from-red-600 to-red-800 px-10 h-11 w-full mt-10"
                    shape="round"
                    onClick={() =>
                        navigate(`${AppRoutes.dashboard.tournament.game.home}/${tournamentClientId}`, {
                            replace: true,
                        })
                    }
                >
                    <Trans>Back</Trans>
                </Button>
            </div>
        </div>
    )
}

export default ResultTournament
