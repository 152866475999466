import { Col, Divider, Row } from "antd"
import { Drawer } from "components/template/Drawer"
import { Cross } from "core/utilities/Icons"
import { Trans, useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

type ReportItem = {
    title: string
}
interface Props {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
}

const ReportDrawer: React.FC<Props> = ({ isOpen, setIsOpen }) => {
    const { t } = useTranslation()

    const reportItems: ReportItem[] = [
        {
            title: t("Loading Game Issue"),
        },
        {
            title: t("Lost Internet Issue"),
        },
        {
            title: t("Game Locked Or Closed Issue"),
        },
        {
            title: t("Game Bug Issue"),
        },
        {
            title: t("Score Not Saved Issue"),
        },
        {
            title: t("No Opponent Issue"),
        },
        {
            title: t("Lost Reward Issue"),
        },
        {
            title: t("Expired Game Issue"),
        },
        {
            title: t("Other Issues"),
        },
    ]

    return (
        <Drawer className="bg-primary-light shadow-2xl rounded-t-3xl p-4 h-fit" isOpen={isOpen} setIsOpen={setIsOpen}>
            <Row justify="center" align="middle" className="relative">
                <button
                    className="bg-white bg-opacity-20 p-1.5 w-8 h-8 rounded-full absolute right-0"
                    onClick={() => setIsOpen(false)}
                >
                    <Cross />
                </button>
                <h4 className="text-white text-lg">
                    <Trans>Report</Trans>
                </h4>
            </Row>
            <Row>
                <Col span={24} className="flex flex-col mt-12">
                    {reportItems.map((_, index) => (
                        <>
                            <Link className="text-base py-2.5" to={"#"}>
                                {_.title}
                            </Link>
                            {index < reportItems.length - 1 && <Divider className="my-0 bg-slate-600" />}
                        </>
                    ))}
                </Col>
            </Row>
        </Drawer>
    )
}

export { ReportDrawer }
