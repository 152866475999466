export const Content = {
    en: {
        translations: {
            Back: "Back",
            "Recent Games": "Recent Games",
            Start: "Start",
            Tournaments: "Tournaments",
            "Prize Pool": "Prize Pool",
            "Enter Tournament": "Enter Tournament",
            Entrance: "Entrance",
            "Starts In": "Starts In",
            Hours: "h",
            Minutes: "m",
            Seconds: "s",

            "First Place": "First place",
            "Second Place": "Second place",

            Reward: "Reward",

            "Game Name": "Game name",
            "Game Mode": "Game mode",
            "Game ID": "Game ID",

            Time: "Time",

            Report: "Report",

            "Ends In": "Ends in",

            "Win Gold Coins": "Win Gold Coins",
            "Invite Friends": "Invite Friends",
            "Invite Friends Body": "Invite Friends and Earn Coins",
            "Your Invitation Code": "Your Invitation Code",
            "Enter Gift Code": "Enter Gift Code",
            "Enter Invitation Code": "Enter Invitation Code",
            "Enter Code": "Enter Code",
            Confirm: "Confirm",

            "Profile Settings": "Profile Settings",
            "General Settings": "General Settings",
            Nickname: "Nickname",
            "Change Nickname": "Change Your Nickname",
            "Required Coins": "Required Coins",
            "Go to Store": "Go to Store",
            Exit: "Exit",
            "Are You Sure?": "Are You Sure?",
            Cancel: "Cancel",
            "Logged in as guest": "Logged in as guest",
            "Log in with phone number": "Log in with phone number",
            "Main Games": "Main Games",
            Bouncy: "Bouncy",

            // Games
            "Magic Zuma": "Magic Zuma",
            Hexie: "Hexie",
            "2048 Cubes": "2048 Cubes",
            Jumpy: "Jumpy",
            "Tetris 3x3": "Tetris 3x3",
            "Jelly Wars": "Jelly Wars",

            Game: "Game",

            "Recent Activity": "Recent Activity",
            Unfinished: "Unfinished",
            "Waiting Opponent": "Waiting for Opponent",
            "Waiting Tournament to Finish": "Waiting Tournament",
            Trial: "Trial Phase",
            "Unlocks After Trial": "Unlocks After Trial Phase",
            "Practice Game": "Practice Game",
            "You Placed 2nd": "You Placed 2nd",
            "You Won": "You Won!",
            Duel: "Duel",
            Coins: "Coins",
            Coin: "Coin",

            Packs: "Packs",
            Price: "Toman",

            "Select Avatar": "Select Avatar",

            "Convert Coins": "Convert Gold Coins Into Silver Coins",
            "Gold Coin": "Gold Coin",
            "Silver Coin": "Silver Coin",
            Convert: "Convert",
            "Not Enough Coins": "Not Enough Coins",

            "Loading The Game": "Loading The Game",

            "Games Stats": "Games Stats",

            "You Placed": "You placed {{rank}}",

            "Redirecting To The Match": "Redirecting to the match",

            Play: "Play",
            "Duel With": "Duel With Opponents and Show Them Your Skills!",
            "Winner Prize": "Winner Prize",
            "Start Match": "Start Match",
            "Unlocks in": "Unlocks in <0></0>",
            Practice: "Practice",
            "Practice Description": "Practice to Improve Your Skills",
            "Your Stats": "Your Stats",
            "Your Scores": "Your Scores",
            "High Score": "High Score",
            "Match Start": "Match Starts in:",

            Leaderboard: "Leaderboard",
            "Seasonal Leaderboard": "Seasonal Leaderboard",
            "Leaderboard Description":
                "Compete with All Players and Earn Coins. Leaderboard Will Restart After Season Ends.",
            "Full List": "Full List",

            "One To One": "1 to 1",

            Qualification: "Qualification",
            Bronze: "Bronze",
            Silver: "Silver",
            Gold: "Gold",
            Diamond: "Diamond",
            Champion: "Champion",
            Legend: "Legend",

            "Loading Game Issue": "The Game did not load",
            "Lost Internet Issue": "Internet got disconnected",
            "Game Locked Or Closed Issue": "Game got locked or closed",
            "Game Bug Issue": "There was a bug in the game",
            "Score Not Saved Issue": "Scores were not saved",
            "No Opponent Issue": "Could not find an opponent",
            "Lost Reward Issue": "Did not receive the reward",
            "Expired Game Issue": "Game Expired",
            "Other Issues": "Other issues",

            Login: "Login",
            "Login with Phone Number": "Login with Phone Number",
            "Enter your phone number": "Enter your phone number",
            Tournament: "Tournament",

            "Convert Gold to Silver": "Convert Gold to Silver",
            "Gold coin": "Gold coin",
            "Silver coin": "Silver coin",
            "Not enough gold": "Not enough gold",

            "Change Language": "Change Language",

            "No unfinished matches": "No unfinished matches",
            "No recent activities": "No recent activities",

            "Login as guest": "Login as guest",
            "Privacy Policy": "Privacy Policy",

            Players: "Players",
            Rewarded: "Rewarded",

            "Time Until Resend": "Time Until Resend",
            "Edit Phone Number": "Edit Phone Number",
            "Enter Sent Code": "Enter Sent Code",
            "Invalid Code": "Code",

            "You Are Offline": "You Are Offline",
            "Check Connection": "Check Connection",
            "Try Again": "Try Again",

            "Add FunCraft to Your Home Screen": "Add FunCraft to Your Home Screen",
            "Click on the Share Button": "Click on the Share Button",
            "Select the Plus Icon in the Opened Menu": "Select the Plus Icon in the Opened Menu",
            "Next, Click on the Add Button From the Top": "Next, Click on the Add Button From the Top",
            Noted: "Noted",
        },
    },
    fa: {
        translations: {
            "Recent Games": "بازی‌های اخیر",

            "First Place": "اول",
            "Second Place": "دوم",
            "Your Invitation Code": "کد دعوت شما",
            "Logged in as guest": "به عنوان مهمان وارد شده اید!",
            "Log in with phone number": "ورود با شماره موبایل",
            "Enter Gift Code": "وارد کردن کد هدیه",
            "Enter Invitation Code": "وارد کردن کد دعوت",
            "Enter Code": "کد را وارد کنید",
            Report: "گزارش",

            "Game Name": "نام بازی",
            "Game Mode": "مود بازی",
            "Game ID": "شناسه بازی",

            Reward: "جایزه",

            Time: "زمان",

            "Ends In": "تا پایان",

            Nickname: "نام مستعار",
            "Change Nickname": "تغییر نام مستعار",
            "Required Coins": "سکه مورد نیاز",

            Tournaments: "تورنومنت‌ها",
            "Prize Pool": "مجموع جوایز",
            "Enter Tournament": "ورود به تورنومنت",
            Entrance: "ورودی",
            "Starts In": "تا شروع",
            Hours: "ساعت",
            Minutes: "دقیقه",
            Seconds: "ثانیه",

            "You Placed": "نفر {{rank}} ام شدید",

            "Main Games": "بازی‌های اصلی",
            Bouncy: "توپ شیطونک",

            // Games
            "Magic Zuma": "زوما جادویی",
            Hexie: "هگزونیکس",
            "2048 Cubes": "۲۰۴۸ مکعبی",
            Jumpy: "جامپی",
            "Tetris 3x3": "تتریس ۳×۳",
            "Jelly Wars": "جنگ ژله‌ای",

            "Win Gold Coins": "سکه طلایی ببر",
            "Invite Friends": "دعوت از دوستان",
            "Invite Friends Body": "دوستات رو به بازی دعوت کن و جایزه بگیر",

            "Redirecting To The Match": "در حال انتقال به مسابقه",

            "Recent Activity": "فعالیت‌های اخیر",
            Unfinished: "در حال انجام",
            "Waiting Opponent": "منتظر بازی حریف",
            "Waiting Tournament to Finish": "در انتظار پایان تورنمنت",
            Trial: "دست گرمی",
            "Unlocks After Trial": "بعد از اتمام دست گرمی باز می شود",
            "Practice Game": "بازی تمرینی",
            "You Placed 2nd": "نفر دوم شدی",
            "You Won": "برنده شدی!",
            Duel: "دوئل",
            Coins: "سکه‌ای",
            Coin: "سکه",

            Packs: "بسته‌ها",
            Price: "تومان",
            "Convert Coins": "تبدیل سکه نقره‌ای به طلایی",
            "Gold Coin": "سکه طلایی",
            "Silver Coin": "سکه نقره‌ای",
            Convert: "تبدیل",
            "Not Enough Coins": "سکه کافی ندارید",

            "Select Avatar": "انتخاب آواتار",

            Play: "بازی",
            "Duel With": "با حریف‌هات بازی کن و توانایی‌هات رو به رخ بکش!",
            "Winner Prize": "جایزه برنده",
            "Start Match": "شروع بازی",
            "Unlocks in": "در سطح <0></0> باز می‌شود",
            Practice: "تمرین کن",
            "Practice Description": "بازی کن تا در رقابت اصلی بهتر شرکت کنی",
            "Your Stats": "آمار شما",
            "Games Stats": "آمار بازی ها",
            "Your Scores": "امتیاز های شما",
            "High Score": "بیشترین امتیاز",
            "Match Start": "ثانیه دیگه بازی شروع میشه",

            "Profile Settings": "تنظیمات پروفایل",
            "General Settings": "عمومی",

            "Loading The Game": "در حال بارگزاری بازی",

            Back: "بازگشت",

            Leaderboard: "لیدربورد",
            "Seasonal Leaderboard": "لیدربورد فصلی",
            "Leaderboard Description":
                "با تمام بازیکن‌ها به صورت هفتگی رقابت کن و جایزه ببر. توجه کن که در هر فصل امتیازها از صفر شروع می‌شوند",
            "Full List": "لیست کامل",
            "Go to Store": "فروشگاه",

            "One To One": "1 به 1",

            Game: "بازی",

            Start: "شروع",

            Confirm: "تایید",
            Cancel: "لغو",
            Exit: "خروج",

            Qualification: "دست گرمی",
            Bronze: "برنز",
            Silver: "نقره",
            Gold: "طلا",
            Diamond: "الماس",
            Champion: "قهرمانان",
            Legend: "افسانه‌ای",

            "Loading Game Issue": "بازی لود نشد.",
            "Lost Internet Issue": "اینترنت قطع شد",
            "Game Locked Or Closed Issue": "بازی قفل یا بسته شد.",
            "Game Bug Issue": "بازی باگ داشت.",
            "Score Not Saved Issue": "امتیاز ذخیره نشد.",
            "No Opponent Issue": "حریف پیدا نشد.",
            "Lost Reward Issue": "جایزه داده نشد.",
            "Expired Game Issue": "بازی منقضی شد.",
            "Other Issues": "دیگر",

            Login: "ورود",
            "Login with Phone Number": "ورود با شماره موبایل",
            "Enter your phone number": "شماره خود را وارد کنید",
            Tournament: "تورنومنت",

            "Convert Gold to Silver": "تبدیل سکه طلایی به نقره‌ای",
            "Gold coin": "سکه طلایی",
            "Silver coin": "سکه نقره‌ای",
            "Not enough gold": "سکه کافی ندارید",
            "Change Language": "تغییر زبان",

            "No unfinished matches": "بازی در حال انجامی وجود ندارد",
            "No recent activities": "فعالیتی وجود ندارد",

            "Login as guest": "ورود به عنوان مهمان",
            "Privacy Policy": "حریم خصوصی",

            Players: "بازیکن‌ها",
            Rewarded: "جایزه بگیران",

            "Time Until Resend": "زمان باقی‌مانده تا ارسال دوباره",
            "Edit Phone Number": "اصلاح شماره",
            "Enter Sent Code": "کد ارسال شده را وارد کنید",
            "Invalid Code": "کد وارد شده معتبر نمی‌باشد",

            "You Are Offline": "شما آفلاین هستید",
            "Check Connection": "بررسی اتصال",
            "Try Again": "تلاش مجدد",

            "Add FunCraft to Your Home Screen": "وب‌اپلیکیشن فان‌کرفت را به صفحه اصلی موبایل خود اضافه کنید",
            "Click on the Share Button": "در نوار پایین روی دکمه Share بزنید",
            "Select the Plus Icon in the Opened Menu": "در منوی باز شده، در قسمت پایین، گزینه را انتخاب کنید",
            "Next, Click on the Add Button From the Top": "در مرحله بعد در قسمت بالا روی Add بزنید",
            Noted: "متوجه شدم",
            "Are You Sure?": "آیا مطمعن هستید؟",
        },
    },
}
