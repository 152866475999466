import React from "react"
import { Navigate } from "react-router"
import { AppRoutes } from "core/utilities/AppRoutes"
import { tools } from "core/functions/tools"

interface Props {
    children: React.ReactNode
}

export const ProtectedRoute: React.FC<Props> = ({ children }) => {
    // States and Hooks
    const userToken = tools.getUserToken()
    let isAuthenticated = userToken !== null

    // Render
    if (!isAuthenticated) return <Navigate to={AppRoutes.login} replace />
    return <>{children}</>
}
