import React from "react"

type Props = {
    children: React.ReactNode
    onClose: Function
    className?: React.HTMLProps<HTMLDivElement>["className"]
}

const Modal: React.FC<Props> = ({ children, onClose, className }) => {
    return (
        <div className={`flex fixed h-screen w-screen bg-black bg-opacity-50 z-50${className ? className : ""}`}>
            <div className="absolute h-screen w-screen z-10" onClick={() => onClose()}></div>
            <div className="relative mx-auto my-auto z-20 w-full">{children}</div>
        </div>
    )
}

export { Modal }
