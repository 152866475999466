import { toast } from "react-toastify"
import { useApp } from "./AppProvider"
import { useParams } from "react-router"
import { useApi } from "core/api/ApiProvider"
import { ProfileType } from "core/api/types/ProfileTypes"
import { createContext, useContext, useEffect, useState } from "react"

type Props = {
    children: JSX.Element
}

interface UserProfileContextInterface {
    profile: ProfileType | null
    loadingUserProfile: boolean
}

const initialContextValue: UserProfileContextInterface = {
    profile: null,
    loadingUserProfile: true,
}

const UserProfileContext = createContext<UserProfileContextInterface>(initialContextValue)

export const useUserProfile = () => useContext(UserProfileContext)

const UserProfileProvider: React.FC<Props> = ({ children }) => {
    // States and Hooks
    const { user_id: userID } = useParams()
    const { user } = useApi()
    const { loadingApp } = useApp()
    const [profile, setProfile] = useState<ProfileType | null>(null)

    // Methods
    const fetchProfile = async () => {
        const { data, error } = await user.fetchProfile({ ID: Number(userID) })
        console.log("data ===> ", data)
        if (error) return toast.error("مشکل در دریافت اطلاعات")
        if (!error) return setProfile(data)
    }

    useEffect(() => {
        if (!loadingApp) fetchProfile()
    }, [loadingApp])

    // Binding
    const value = {
        profile,
        loadingUserProfile: profile === null,
    }

    // Render
    return <UserProfileContext.Provider value={value}>{children}</UserProfileContext.Provider>
}

export default UserProfileProvider
