import { v4 } from "uuid"
import sha256 from "crypto-js/sha256"
import { axiosRequest } from "./axiosRequest"
import { createContext, useContext } from "react"
import { FifaWorldCupController } from "./controllers"
import { ActivityTypeObject } from "core/providers/TournamentProvider"

const SALT: string = process.env.REACT_APP_API_SALT_SECURITY ?? ""

type Props = {
    children: JSX.Element
}

interface ApiContextInterface {
    user: {
        loginAsGuest: Function
        loginWithPhoneNumber: Function
        confirmCode: Function
        fetchProfile: Function
        fetchAvatarList: Function
        setAvatar: Function
        enterName: Function
        setReferralCode: Function
        postGiftCode: Function
        changeName: Function
        foobar: Function
    }
    games: {
        fetch: Function
        findOne: Function
        start: Function
        matches: Function
        getMatchByID: Function
        getLeaderBoard: Function
        endMatch: Function
    }
    tournaments: {
        findOne: Function
        fetch: Function
        start: Function
        getLeaderBoard: Function
        getCloseLeaderBoard: Function
        endMatch: Function
        getActivity: Function
    }
    shop: {
        fetch: Function
        purchase: Function
        verify: Function
    }
    fifa: {
        login: Function
        leaderboard: Function
        matches: Function
        info: Function
        fetch: Function
        placeBet: Function
    }
}

const initialContextValue = {
    user: {
        loginAsGuest: () => undefined,
        loginWithPhoneNumber: () => undefined,
        confirmCode: () => undefined,
        fetchProfile: () => undefined,
        enterName: () => undefined,
        setReferralCode: () => undefined,
        postGiftCode: () => undefined,
        changeName: () => undefined,
        fetchAvatarList: () => undefined,
        setAvatar: () => undefined,
        foobar: () => undefined,
    },
    games: {
        fetch: () => undefined,
        findOne: () => undefined,
        start: () => undefined,
        matches: () => undefined,
        getMatchByID: () => undefined,
        getLeaderBoard: () => undefined,
        endMatch: () => undefined,
    },
    tournaments: {
        findOne: () => undefined,
        fetch: () => undefined,
        start: () => undefined,
        getLeaderBoard: () => undefined,
        endMatch: () => undefined,
        getCloseLeaderBoard: () => undefined,
        getActivity: () => undefined,
    },
    shop: {
        fetch: () => undefined,
        purchase: () => undefined,
        verify: () => undefined,
    },
    fifa: {
        login: () => undefined,
        leaderboard: () => undefined,
        matches: () => undefined,
        info: () => undefined,
        fetch: () => undefined,
        placeBet: () => undefined,
    },
}

const ApiContext = createContext<ApiContextInterface>(initialContextValue)

export const useApi = () => useContext(ApiContext)

const ApiProvider: React.FC<Props> = ({ children }) => {
    // States and Hooks

    // Methods

    // Binding
    const value: ApiContextInterface = {
        // States
        user: {
            loginAsGuest: async (args: { token: string }) => {
                const body = {
                    token: args.token,
                    reference: "app",
                }

                const hashedPassword = sha256("0" + JSON.stringify(body) + SALT).toString()

                return await axiosRequest({
                    url: "/info/user/login",
                    method: "POST",
                    body,
                    headers: null,
                    auth: {
                        username: "0",
                        password: hashedPassword,
                    },
                })
            },
            loginWithPhoneNumber: async (args: { phoneNumber: string }) => {
                const body = {
                    receptor: args.phoneNumber,
                }
                const hashedPassword = sha256("0" + JSON.stringify(body) + SALT).toString()
                return await axiosRequest({
                    method: "POST",
                    url: "/info/user/login/sms",
                    auth: {
                        username: "0",
                        password: hashedPassword,
                    },
                    body,
                    headers: null,
                })
            },
            enterName: async (args: { ID: number; name: string }) => {
                const body = {
                    name: args.name,
                }
                const hashedPassword = sha256(args.ID.toString() + JSON.stringify(body) + SALT).toString()
                return await axiosRequest({
                    method: "POST",
                    url: "/info/user/name",
                    auth: {
                        username: args.ID.toString(),
                        password: hashedPassword,
                    },
                    body,
                    headers: null,
                })
            },
            confirmCode: async (args: { phoneNumber: string; token: string }) => {
                const body = {
                    receptor: args.phoneNumber,
                    token: args.token,
                }
                const hashedPassword = sha256("0" + JSON.stringify(body) + SALT).toString()
                return await axiosRequest({
                    method: "POST",
                    url: "/info/user/login/sms/verify",
                    auth: {
                        username: "0",
                        password: hashedPassword,
                    },
                    body,
                    headers: null,
                })
            },
            fetchProfile: async (args: { ID: number }) => {
                const body = {
                    userId: args.ID,
                }
                const hashedPassword = sha256(args.ID.toString() + JSON.stringify(body) + SALT).toString()
                return await axiosRequest({
                    method: "POST",
                    url: "/info/user/profile",
                    auth: {
                        username: args.ID.toString(),
                        password: hashedPassword,
                    },
                    body,
                    headers: null,
                })
            },

            fetchAvatarList: async (args: { ID: number }) => {
                const hashedPassword = sha256(args.ID.toString() + SALT).toString()
                return await axiosRequest({
                    method: "GET",
                    url: "/info/avatar",
                    auth: {
                        username: args.ID.toString(),
                        password: hashedPassword,
                    },
                    body: null,
                    headers: null,
                })
            },

            setAvatar: async (args: { ID: number; avatarId: number }) => {
                const body = {
                    avatarId: args.avatarId,
                }
                const hashedPassword = sha256(args.ID.toString() + JSON.stringify(body) + SALT).toString()
                return await axiosRequest({
                    url: "/info/avatar/set",
                    method: "POST",
                    auth: {
                        username: args.ID.toString(),
                        password: hashedPassword,
                    },
                    headers: null,
                    body,
                })
            },

            setReferralCode: async (args: { ID: number; code: string }) => {
                const body = {
                    code: args.code,
                }

                const hashedPassword = sha256(args.ID.toString() + JSON.stringify(body) + SALT).toString()

                return await axiosRequest({
                    url: "/info/referral",
                    method: "POST",
                    auth: {
                        username: args.ID.toString(),
                        password: hashedPassword,
                    },
                    headers: null,
                    body,
                })
            },
            postGiftCode: async (args: { ID: number; code: string }) => {
                const body = {
                    code: args.code,
                }
                const hashedPassword = sha256(args.ID.toString() + JSON.stringify(body) + SALT).toString()
                return await axiosRequest({
                    url: "/info/gift",
                    method: "POST",
                    auth: {
                        username: args.ID.toString(),
                        password: hashedPassword,
                    },
                    headers: null,
                    body,
                })
            },
            changeName: async (args: { ID: number; name: string }) => {
                const body = {
                    name: args.name,
                }
                const hashedPassword = sha256(args.ID.toString() + JSON.stringify(body) + SALT).toString()

                return await axiosRequest({
                    url: "/info/user/name",
                    method: "POST",
                    auth: {
                        username: args.ID.toString(),
                        password: hashedPassword,
                    },
                    headers: null,
                    body,
                })
            },
            foobar: async (args: { ID: number }) => {
                const body = {
                    sku: "foobar",
                    token: "some_token_will",
                }
                const hashedPassword = sha256(args.ID.toString() + JSON.stringify(body) + SALT).toString()

                return await axiosRequest({
                    url: "/info/shop/bazaar/verify",
                    method: "POST",
                    auth: {
                        username: args.ID.toString(),
                        password: hashedPassword,
                    },
                    headers: null,
                    body,
                })
            },
        },
        games: {
            fetch: async (args: { ID: number }) => {
                const hashedPassword = sha256(args.ID + SALT).toString()

                return await axiosRequest({
                    url: "/info/games",
                    method: "GET",
                    body: null,
                    headers: null,
                    auth: {
                        username: args.ID.toString(),
                        password: hashedPassword,
                    },
                })
            },
            findOne: async (args: { gameClientId: string; ID: number }) => {
                const hashedPassword = sha256(args.ID + SALT).toString()

                return await axiosRequest({
                    url: "/info/games/" + args.gameClientId,
                    method: "GET",
                    body: null,
                    headers: null,
                    auth: {
                        username: args.ID.toString(),
                        password: hashedPassword,
                    },
                })
            },
            start: async (args: { clientId: string; ID: number; rank: number }) => {
                const body = {
                    clientId: args.clientId,
                    rank: args.rank,
                }

                const hashedPassword = sha256(args.ID + JSON.stringify(body) + SALT).toString()

                return await axiosRequest({
                    url: "/info/match/start",
                    method: "POST",
                    body: body,
                    headers: null,
                    auth: {
                        username: args.ID.toString(),
                        password: hashedPassword,
                    },
                })
            },
            matches: async (args: { ID: number }) => {
                const body = {
                    page: 1,
                    size: 1000,
                }
                const hashedPassword = sha256(args.ID + JSON.stringify(body) + SALT).toString()

                return await axiosRequest({
                    url: "/info/activities",
                    method: "POST",
                    body,
                    headers: null,
                    auth: {
                        username: args.ID.toString(),
                        password: hashedPassword,
                    },
                })
            },
            getMatchByID: async (args: { ID: number; matchID: string }) => {
                const body = {
                    type: 0,
                    id: args.matchID,
                }
                const hashedPassword = sha256(args.ID + JSON.stringify(body) + SALT).toString()

                return await axiosRequest({
                    url: `/info/activities/get`,
                    method: "POST",
                    body,
                    headers: null,
                    auth: {
                        username: args.ID.toString(),
                        password: hashedPassword,
                    },
                })
            },
            getLeaderBoard: async (args: { ID: number; gameId: string; page: number; size: number }) => {
                const body = {
                    page: args.page,
                    size: args.size,
                }

                const hashedPassword = sha256(args.ID + JSON.stringify(body) + SALT).toString()

                return await axiosRequest({
                    url: `/info/leaderboard/${args.gameId}`,
                    method: "POST",
                    auth: {
                        username: args.ID.toString(),
                        password: hashedPassword,
                    },
                    body,
                    headers: null,
                })
            },
            endMatch: async (args: {
                ID: number
                clientId: string
                matchId: number
                score: string
                secret: string
            }) => {
                const body = {
                    gameClientID: args.clientId,
                    matchId: args.matchId,
                    score: args.score,
                }

                const hashedPassword = sha256(args.ID + JSON.stringify(body) + args.secret).toString()

                return await axiosRequest({
                    url: `/info/match/end`,
                    method: "POST",
                    auth: {
                        username: args.ID.toString(),
                        password: hashedPassword,
                    },
                    body,
                    headers: null,
                })
            },
        },
        tournaments: {
            findOne: async (args: { tournamentClientId: string; ID: number }) => {
                const hashedPassword = sha256(args.ID + SALT).toString()

                return await axiosRequest({
                    url: "/info/tournaments/" + args.tournamentClientId,
                    method: "GET",
                    body: null,
                    headers: null,
                    auth: {
                        username: args.ID.toString(),
                        password: hashedPassword,
                    },
                })
            },
            fetch: async (args: { ID: number }) => {
                const hashedPassword = sha256(args.ID + SALT).toString()

                return await axiosRequest({
                    url: "/info/tournaments",
                    method: "GET",
                    body: null,
                    headers: null,
                    auth: {
                        username: args.ID.toString(),
                        password: hashedPassword,
                    },
                })
            },
            start: async (args: { clientID: string; ID: number; tournamentID: string }) => {
                const body = {
                    clientID: args.clientID,
                    tournamentID: args.tournamentID,
                }

                const hashedPassword = sha256(args.ID + JSON.stringify(body) + SALT).toString()

                return await axiosRequest({
                    url: "/info/tournaments/match/start",
                    method: "POST",
                    body: body,
                    headers: null,
                    auth: {
                        username: args.ID.toString(),
                        password: hashedPassword,
                    },
                })
            },
            getLeaderBoard: async (args: { ID: number; gameId: string; page: number; size: number }) => {
                const body = {
                    page: args.page,
                    size: args.size,
                }

                const hashedPassword = sha256(args.ID + JSON.stringify(body) + SALT).toString()

                return await axiosRequest({
                    url: `/info/tournaments/leaderboard/${args.gameId}`,
                    method: "POST",
                    auth: {
                        username: args.ID.toString(),
                        password: hashedPassword,
                    },
                    body,
                    headers: null,
                })
            },
            getCloseLeaderBoard: async (args: { ID: number; tournamentID: string }) => {
                const hashedPassword = sha256(args.ID + SALT).toString()
                return await axiosRequest({
                    url: `/info/tournaments/leaderboard/close/${args.tournamentID}`,
                    method: "GET",
                    auth: {
                        username: args.ID.toString(),
                        password: hashedPassword,
                    },
                    body: null,
                    headers: null,
                })
            },
            endMatch: async (args: {
                ID: number
                clientId: string
                matchId: number
                score: string
                secret: string
            }) => {
                const body = {
                    gameClientID: args.clientId,
                    matchId: args.matchId,
                    score: args.score,
                }

                const hashedPassword = sha256(args.ID + JSON.stringify(body) + args.secret).toString()

                return await axiosRequest({
                    url: `/info/tournaments/match/end`,
                    method: "POST",
                    auth: {
                        username: args.ID.toString(),
                        password: hashedPassword,
                    },
                    body,
                    headers: null,
                })
            },
            getActivity: async (args: { ID: number; tournamentID: string }) => {
                const body = {
                    type: ActivityTypeObject.Tournament,
                    id: args.tournamentID,
                }

                const hashedPassword = sha256(args.ID + JSON.stringify(body) + SALT).toString()

                return await axiosRequest({
                    url: `/info/activities/get`,
                    method: "POST",
                    auth: {
                        username: args.ID.toString(),
                        password: hashedPassword,
                    },
                    body,
                    headers: null,
                })
            },
        },
        shop: {
            fetch: async (args: { ID: number }) => {
                const hashedPassword = sha256(args.ID + SALT).toString()

                return await axiosRequest({
                    url: "/info/shop",
                    method: "GET",
                    body: null,
                    headers: null,
                    auth: {
                        username: args.ID.toString(),
                        password: hashedPassword,
                    },
                })
            },
            purchase: async (args: { ID: number; sku: string }) => {
                const { sku, ID } = args
                const token = v4()
                const body = {
                    sku,
                    token,
                }
                const hashedPassword = sha256(ID + JSON.stringify(body) + SALT).toString()
                return await axiosRequest({
                    url: "/info/shop/purchase",
                    method: "POST",
                    auth: {
                        username: ID.toString(),
                        password: hashedPassword,
                    },
                    body,
                    headers: null,
                })
            },
            verify: async (args: { ID: number; options: string }) => {
                const { options, ID } = args
                const hashedPassword = sha256(ID + SALT).toString()
                return await axiosRequest({
                    url: `/info/shop/zarinpal/verify${options}`,
                    method: "GET",
                    auth: {
                        username: ID.toString(),
                        password: hashedPassword,
                    },
                    body: null,
                    headers: null,
                })
            },
        },
        fifa: {
            ...FifaWorldCupController,
        },
        // Methods
    }

    // Render
    return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>
}

export default ApiProvider
