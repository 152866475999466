import React from "react"

interface Props extends React.HTMLProps<HTMLDivElement> {
    children: React.ReactNode
}

const HorizontalList: React.FC<Props> = ({ children, className, ...rest }) => {
    return (
        <div {...rest} className={`grid grid-flow-col overflow-x-scroll overflow-y-hidden ${className}`}>
            {children}
        </div>
    )
}

export { HorizontalList }
