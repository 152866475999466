import { Col, Row } from "antd"
import { LeaderBoardType } from "core/api/types/GameTypes"
import { AppRoutes } from "core/utilities/AppRoutes"
import { Gold } from "core/utilities/ImageImport.js"
import { Link } from "react-router-dom"

type Props = {
    listNumber: number
    item: LeaderBoardType
    isHighlighted: boolean
}

export const LeaderBoardItem: React.FC<Props> = ({ item, listNumber, isHighlighted }) => {
    // States and Hooks
    const { avatar, gold, trophy, username } = item

    // Render
    return (
        <Link to={`${AppRoutes.dashboard.userProfile}/${item.userId}`}>
            <Row
                className={`everything-ltr my-2 gap-3 p-1 ${
                    isHighlighted && "bg-gradient-to-tr from-gray-700 to-gray-500 rounded-xl"
                }`}
                align="middle"
                justify="space-around"
            >
                <Col span={3}>
                    <img src={avatar} className="w-full object-cover rounded-full" alt="" />
                </Col>
                <Col span={1}>{listNumber}.</Col>
                <Col span={8}>{username}</Col>
                <Col span={4}>
                    <img className="inline mx-1" src={Gold} alt="" />
                    {gold}
                </Col>
                <Col span={3}>
                    <span className="text-green-600">{trophy}</span>
                </Col>
            </Row>
        </Link>
    )
}
