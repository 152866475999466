import { useApi } from "core/api/ApiProvider"
import { tools } from "core/functions/tools"
import { AppRoutes } from "core/utilities/AppRoutes"
import { createContext, useContext, useState } from "react"
import { useNavigate } from "react-router"
import { toast } from "react-toastify"
import { v4 } from "uuid"
import { useApp } from "./AppProvider"

type Props = {
    children: JSX.Element
}

interface AuthContextInterface {
    onLoginButtonClick: Function
    loginButtonLoading: boolean
    onConfirmButtonClick: Function
    phoneNumber: string
    setPhoneNumber: Function
    confirmCode: string
    setConfirmCode: Function
    onGuestLoginButtonClick: Function
    guestLoginButtonLoading: boolean
}

const initialContextValue = {
    onLoginButtonClick: () => undefined,
    loginButtonLoading: false,

    onConfirmButtonClick: () => undefined,

    phoneNumber: "",
    setPhoneNumber: () => undefined,

    confirmCode: "",
    setConfirmCode: () => undefined,

    onGuestLoginButtonClick: () => undefined,
    guestLoginButtonLoading: false,
}

const AuthContext = createContext<AuthContextInterface>(initialContextValue)

export const useAuth = () => useContext(AuthContext)

const AuthProvider: React.FC<Props> = ({ children }) => {
    // States and Hooks
    const { user } = useApi()
    const { fetchUser, setCurrentUser } = useApp()
    const navigate = useNavigate()
    const [loginButtonLoading, setLoginButtonLoading] = useState<boolean>(false)
    const [guestLoginButtonLoading, setGuestLoginButtonLoading] = useState<boolean>(false)
    const [phoneNumber, setPhoneNumber] = useState<string>("")
    const [confirmCode, setConfirmCode] = useState<string>("")

    // Methods
    const onLoginButtonClick = async () => {
        if (phoneNumber.length !== 11) return toast.error("شماره وارد شده معتبر نمی‌باشد")
        setLoginButtonLoading(true)
        const { data, error } = await user.loginWithPhoneNumber({ phoneNumber: phoneNumber })
        await fetchUser()
        setLoginButtonLoading(false)

        if (error) return toast.error("مشکل در ارسال کد تایید")
        return navigate(AppRoutes.confirmCode, {
            replace: true,
        })
    }

    const onGuestLoginButtonClick = async () => {
        setGuestLoginButtonLoading(true)
        const token = v4()
        const { data, error } = await user.loginAsGuest({ token })
        await fetchUser()
        setGuestLoginButtonLoading(false)

        setCurrentUser(data)
        localStorage.setItem("User_ID", data.ID)
        tools.setUserToken(data.deviceToken)

        if (error) return toast.error("مشکل در ورود! دوباره امتحان کنید.")
        return navigate(AppRoutes.dashboard.home, {
            replace: true,
        })
    }

    const onConfirmButtonClick = async () => {
        setLoginButtonLoading(true)
        const { data, error } = await user.confirmCode({
            phoneNumber: phoneNumber,
            token: confirmCode,
        })
        setCurrentUser(data)
        setLoginButtonLoading(false)

        if (error) return toast.error("کد وارد شده معتبر نمی‌باشد")
        tools.setUserToken(data.deviceToken)

        if (data.isNameChanged)
            return navigate(AppRoutes.dashboard.home, {
                replace: true,
            })

        return navigate(AppRoutes.enterName, {
            replace: true,
        })
    }
    // Binding
    const value: AuthContextInterface = {
        // States
        onLoginButtonClick,
        loginButtonLoading,
        guestLoginButtonLoading,
        phoneNumber,
        setPhoneNumber,

        onConfirmButtonClick,
        confirmCode,
        onGuestLoginButtonClick,
        setConfirmCode,
    }

    // Render
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthProvider
