import { LosePageBg } from "core/utilities/ImageImport"
import { ArrowLeft } from "core/utilities/Icons"
import { useNavigate, useParams } from "react-router"
import { Button, Divider, Row } from "antd"
import { EarnedRewardsPill } from "components/result-page/EarnedRewardsPill"
import { PlayersVersus } from "components/template/PlayersVersus"
import { GameResultInfo } from "components/result-page/GameResultInfo"
import { InformationCircleSolid } from "core/utilities/Icons"
import { useEffect, useState } from "react"
import { ReportDrawer } from "components/result-page/ReportDrawer"
import { useActivity } from "core/providers/ActivityProvider"
import { useApp } from "core/providers/AppProvider"
import { ActivityGame } from "core/api/types/ActivityTypes"
import { useApi } from "core/api/ApiProvider"
import { AppRoutes } from "core/utilities/AppRoutes"
import { Link } from "react-router-dom"
import { ScreenLoader } from "components/template"
import { Trans, useTranslation } from "react-i18next"
interface Props {}

const Lose: React.FC<Props> = () => {
    const navigate = useNavigate()
    const { games } = useApi()
    const { match_id: matchID } = useParams()
    const { currentUser, loadingApp } = useApp()
    const [openReportDrawer, setOpenReportDrawer] = useState(false)
    const { isActivity } = useActivity()
    const [activity, setActivity] = useState<ActivityGame | null>(null)
    const { t } = useTranslation()
    // Methods
    const fetchActivity = async () => {
        const { data, error } = await games.getMatchByID({
            ID: currentUser?.ID,
            matchID: matchID,
        })
        if (data) setActivity(data)
    }

    const initialize = async () => {
        fetchActivity()
    }

    useEffect(() => {
        if (!loadingApp) initialize()
    }, [loadingApp])

    if (!activity) {
        return <ScreenLoader />
    }

    const {
        winnerId,
        playerOneId,
        playerTwoId,
        playerOneScore,
        playerOneName,
        playerOneAvatar,
        playerTwoScore,
        playerTwoName,
        playerTwoAvatar,
    } = activity.data

    const winner = {
        id: winnerId === playerOneId ? playerOneId : playerTwoId,
        score: winnerId === playerOneId ? playerOneScore : playerTwoScore,
        name: winnerId === playerOneId ? playerOneName : playerTwoName,
        avatarUrl: winnerId === playerOneId ? playerOneAvatar : playerTwoAvatar,
    }

    const looser = {
        id: winnerId === playerTwoId ? playerOneId : playerTwoId,
        score: winnerId === playerTwoId ? playerOneScore : playerTwoScore,
        name: winnerId === playerTwoId ? playerOneName : playerTwoName,
        avatarUrl: winnerId === playerTwoId ? playerOneAvatar : playerTwoAvatar,
    }

    const rewards = {
        gold: activity.data.mode.rewards
            ?.filter(rw => rw.type === 2)
            ?.map(rw => Number(rw.amount) ?? 0)
            ?.reduce((total = 0, curr) => (total += curr), 0),
        silver: activity.data.mode.rewards
            .filter(rw => rw.type === 1)
            .map(rw => Number(rw.amount) ?? 0)
            .reduce((total, curr) => (total += curr), 0),
        XP: activity.data.mode.loseXp,
    }

    return (
        <div
            className="flex flex-col relative"
            style={{
                backgroundImage: `url(${LosePageBg})`,
                // backgroundAttachment: "fixed",
                // backgroundSize: "100%",
            }}
        >
            {/* Fixed background picture */}
            <div className="px-5 py-3">
                <nav className="flex justify-center items-center w-full ltr py-4 relative z-30">
                    <button
                        className="absolute left-0 bg-slate-200 bg-opacity-20 p-2 rounded-full"
                        onClick={() =>
                            navigate(
                                isActivity
                                    ? AppRoutes.dashboard.activity
                                    : `${AppRoutes.dashboard.game.home}/${activity?.data?.gameClientID}`
                            )
                        }
                    >
                        <ArrowLeft className="w-5 h-5" />
                    </button>
                    <span className="bg-slate-200 bg-opacity-50 rounded-full text-center px-4 py-1">
                        {" "}
                        {activity?.data?.game.name}
                    </span>
                </nav>

                <Row justify="center" className="mt-16">
                    <h2 className="text-2xl font-bold text-white">
                        <Trans>You Placed 2nd</Trans>
                    </h2>
                </Row>

                <Row justify="center" className="mt-4">
                    <Link to={`${AppRoutes.dashboard.userProfile}/${looser.id}`}>
                        <div className="rounded-full bg-white bg-opacity-20 backdrop-blur-sm p-3.5">
                            <div className="rounded-full bg-gradient-to-tr from-yellow-600 to-yellow-500 p-1">
                                <img className="rounded-full w-44 h-44 object-cover" src={looser.avatarUrl} alt="" />
                            </div>
                        </div>
                    </Link>
                </Row>

                <Row justify="center" className="mt-8">
                    <div className={`bg-gradient-to-r p-0.5 rounded-full w-64 bg-gray-500`}>
                        <EarnedRewardsPill
                            className={`w-full bg-gradient-to-r from-gray-700 to-gray-600`}
                            scores={{ gold: rewards.gold, silver: rewards.silver, score: rewards.XP }}
                        />
                    </div>
                </Row>

                <Row justify="center" className="mx-6 mt-8">
                    <PlayersVersus
                        user={{
                            id: looser.id,
                            username: looser.name,
                            score: Number(looser.score),
                            avatarUrl: looser.avatarUrl,
                        }}
                        competitor={{
                            id: winner.id,
                            username: winner.name,
                            score: Number(winner.score),
                            avatarUrl: winner.avatarUrl,
                        }}
                    />
                </Row>

                <div className="bg-primary-light rounded-3xl mt-8 p-6  ">
                    <GameResultInfo
                        entranceFee={activity?.data.mode.entrance}
                        gameId={activity?.data.ID.toString()}
                        gameMode={t("One To One")}
                        gameName={activity?.data.game.name}
                        rankingData={[
                            { rank: t("First Place"), username: winner.name },
                            { rank: t("Second Place"), username: looser.name },
                        ]}
                        reward={Number(activity?.data.mode.rewards[0].amount)}
                        time={new Date(activity?.data.UpdatedAt)}
                    />

                    <Divider className="bg-slate-600 my-2" />

                    <Button
                        onClick={() => setOpenReportDrawer(true)}
                        type="primary"
                        className="bg-slate-500 border-0 w-full h-12 mt-1 text-lg rounded-2xl"
                    >
                        <div className="flex gap-1 justify-center items-center w-full">
                            <InformationCircleSolid className="w-7 h-7" />
                            <span className="mr-1">
                                <Trans>Report</Trans>
                            </span>
                        </div>
                    </Button>
                </div>
            </div>
            <ReportDrawer isOpen={openReportDrawer} setIsOpen={setOpenReportDrawer} />
        </div>
    )
}

export default Lose
