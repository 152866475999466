import React from "react"
import { ShimmerElement } from "./ShimmerElement"

interface Props {}

const TournamentCardSkeleton: React.FC<Props> = () => {
    // States and Hooks

    // Methods

    // Render
    return (
        <div className="relative flex flex-col overflow-hidden w-80 h-48 rounded-3xl skeleton">
            <div className="p-0 skeleton-wrapper dark">
                <ShimmerElement type="big-square" />
            </div>
        </div>
    )
}

export { TournamentCardSkeleton }
