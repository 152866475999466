import { Row } from "antd"
import { Drawer } from "components/template/Drawer"
import { useShop } from "core/providers/ShopProvider"
import { AppRoutes } from "core/utilities/AppRoutes"
import { Cross } from "core/utilities/Icons"
import { Link } from "react-router-dom"

interface Props {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
}

const PaymentStatus: React.FC<Props> = ({ isOpen, setIsOpen }) => {
    // States and Hooks
    const { paymentStatus } = useShop()

    // Render
    return (
        <Drawer className="bg-primary-light shadow-2xl rounded-t-3xl p-4 h-4/5" isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="p-4">
                <Row justify="center" align="middle" className="relative">
                    <button
                        className="bg-white bg-opacity-20 p-1.5 w-8 h-8 rounded-full absolute right-0"
                        onClick={() => setIsOpen(false)}
                    >
                        <Cross />
                    </button>
                </Row>
            </div>
            {paymentStatus ? (
                <div className="px-4 my-16 text-center">
                    <div className="font-medium text-2xl border-b-4 border-green-600 border-solid pb-3">
                        پرداخت با موفقیت انجام شد
                    </div>
                    <div className="mt-12">
                        <Link
                            to={AppRoutes.dashboard.home}
                            className="btn-purchase-success flex items-center justify-center text-2xl"
                        >
                            ادامه
                        </Link>
                    </div>
                </div>
            ) : (
                <div className="px-4 my-16 text-center">
                    <div className="font-medium text-2xl border-b-4 border-red-600 border-solid pb-3">
                        پرداخت موفقیت آمیز نبود
                    </div>
                    <div className="mt-12">
                        <Link
                            to={AppRoutes.dashboard.home}
                            className="btn-purchase-danger flex items-center justify-center text-2xl"
                        >
                            بازگشت
                        </Link>
                    </div>
                </div>
            )}
        </Drawer>
    )
}

export { PaymentStatus }
