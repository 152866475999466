import { Divider } from "antd"
import { icons } from "antd/lib/image/PreviewGroup"
import { LeaderBoardType } from "core/api/types/GameTypes"
import { useApp } from "core/providers/AppProvider"
import { useGame } from "core/providers/GameProvider"
import { AppRoutes } from "core/utilities/AppRoutes"
import { ArrowLeft, ArrowRight } from "core/utilities/Icons"
import { Trans } from "react-i18next"
import { Link } from "react-router-dom"
import i18n from "../../i18n"
import { LeaderBoardItem } from "./LeaderBoardItem"

type Props = {
    data: {
        total: LeaderBoardType[]
        close: LeaderBoardType[]
    }
}

export const LeaderBoard: React.FC<Props> = ({ data }) => {
    // States and Hooks
    const { currentUser } = useApp()
    const { game } = useGame()
    const goldPlayer: LeaderBoardType = data.total.slice(0, 1)[0]
    const silverPlyaer: LeaderBoardType = data.total.slice(1, 2)[0]
    const bronzePlayer: LeaderBoardType = data.total.slice(2, 3)[0]

    const userData: LeaderBoardType | null = [...data.total, ...data.close].filter(
        item => item.userId === currentUser?.ID
    )[0]

    // Render
    return (
        <>
            <div className="grid grid-cols-12 justify-items-center gap-1 mb-10">
                {/* Silver */}
                {silverPlyaer && (
                    <Link
                        to={`${AppRoutes.dashboard.userProfile}/${silverPlyaer.userId}`}
                        className="flex flex-col items-center col-span-4 w-full"
                    >
                        <div className="mt-auto bg-gradient-to-t from-gray-500 to-gray-400 p-0.5 rounded-full w-11 h-11">
                            <div className="bg-primary-main p-0.5 rounded-full w-10 h-10">
                                <img src={silverPlyaer.avatar} className="w-full h-full rounded-full" alt="" />
                            </div>
                        </div>
                        <span className="leading-4 mt-1.5">{silverPlyaer.username}</span>
                        <span className="text-cyan-600 leading-4">{silverPlyaer.trophy}</span>
                        <div className="mt-2 h-36 bg-gradient-to-b from-gray-500 to-gray-400 rounded-2xl w-full"></div>
                    </Link>
                )}

                {/* Gold */}
                {goldPlayer && (
                    <Link
                        to={`${AppRoutes.dashboard.userProfile}/${goldPlayer.userId}`}
                        className="flex flex-col items-center col-span-4 w-full"
                    >
                        <div className="mt-auto bg-gradient-to-t from-yellow-500 to-yellow-400 p-0.5 rounded-full w-11 h-11">
                            <div className="bg-primary-main p-0.5 rounded-full w-10 h-10">
                                <img src={goldPlayer.avatar} className="w-full h-full rounded-full" alt="" />
                            </div>
                        </div>
                        <span className="leading-4 mt-1.5">{goldPlayer.username}</span>
                        <span className="text-cyan-600 leading-4">{goldPlayer.trophy}</span>
                        <div className="mt-2 h-44 bg-gradient-to-t from-yellow-600 to-yellow-500 rounded-2xl w-full"></div>
                    </Link>
                )}
                {/* Bronze */}
                {bronzePlayer && (
                    <Link
                        to={`${AppRoutes.dashboard.userProfile}/${bronzePlayer.userId}`}
                        className="flex flex-col items-center col-span-4 w-full"
                    >
                        <div className="mt-auto bg-gradient-to-t from-orange-500 to-orange-400 p-0.5 rounded-full w-11 h-11">
                            <div className="bg-primary-main p-0.5 rounded-full w-10 h-10">
                                <img src={bronzePlayer.avatar} className="w-full h-full rounded-full" alt="" />
                            </div>
                        </div>
                        <span className="leading-4 mt-1.5">{bronzePlayer.username}</span>
                        <span className="text-cyan-600 leading-4">{bronzePlayer.trophy}</span>
                        <div className="mt-2 h-24 bg-gradient-to-t from-orange-600 to-orange-500 rounded-2xl w-full"></div>
                    </Link>
                )}
            </div>

            {data.total.slice(3, 6).map((_, index) => (
                <LeaderBoardItem
                    key={`leaderboard-item-${_.rank}`}
                    item={_}
                    listNumber={index + 4}
                    isHighlighted={_.userId === currentUser?.ID}
                />
            ))}
            {userData?.rank < 9 && (
                <>
                    {data.total.slice(6, 10).map(_ => (
                        <LeaderBoardItem
                            key={`leaderboard-item-${_.rank}`}
                            item={_}
                            listNumber={_.rank}
                            isHighlighted={_.userId === currentUser?.ID}
                        />
                    ))}
                </>
            )}

            {userData?.rank >= 9 && (
                <>
                    <Divider>
                        <span className="text-3xl tracking-wider">...</span>
                    </Divider>
                    {data.close.map(_ => (
                        <LeaderBoardItem
                            key={`leaderboard-item-${_.rank}`}
                            item={_}
                            isHighlighted={_.userId === currentUser?.ID}
                            listNumber={_.rank}
                        />
                    ))}
                </>
            )}
            <div className="flex items-center justify-center gap-2 mt-10 underline">
                <Link to={`${AppRoutes.dashboard.game.leaderBoard}/${game?.clientId}`} className="text-white">
                    <Trans>Full List</Trans>
                </Link>
                <div>{i18n.language === "fa" ? <ArrowLeft /> : <ArrowRight />}</div>
            </div>
        </>
    )
}
