import { Button, Progress } from "antd"
import { Layout } from "components/template/Layout"
import { useApp } from "core/providers/AppProvider"
import { useGame } from "core/providers/GameProvider"
import { AppRoutes } from "core/utilities/AppRoutes"
import React, { useCallback, useEffect } from "react"
import { useNavigate, useParams } from "react-router"
import { Unity, useUnityContext } from "react-unity-webgl"
import { GameResultType } from "core/api/types/GameTypes"
import { ScreenLoader } from "components/template"
import { Trans } from "react-i18next"

interface Props {}

const Play: React.FC<Props> = () => {
    // States and Hooks
    const navigate = useNavigate()
    const { loadingGame, game, onGoingGameData } = useGame()
    const { game_client_id: gameClientId } = useParams()
    const { currentUser, currentGame } = useApp()
    const { unityProvider, isLoaded, loadingProgression, sendMessage, addEventListener } = useUnityContext({
        loaderUrl: `${currentGame?.gamePath}/Build/game.loader.js`,
        dataUrl: `${currentGame?.gamePath}/Build/game.data`,
        frameworkUrl: `${currentGame?.gamePath}/Build/game.framework.js`,
        codeUrl: `${currentGame?.gamePath}/Build/game.wasm`,
    })

    // Methods
    const startCurrentGame = async () => {
        const data = onGoingGameData
        sendMessage("Atari", "OnSetMatchData", JSON.stringify({ matchId: data.matchId, userId: currentUser?.ID }))
    }

    const handleMatchResult = useCallback(async (matchData: string) => {
        const object: GameResultType = JSON.parse(matchData)
        const anchorElement = document.createElement("a")

        if (object.playerTwoEndGame && object.playerOneEndGame) {
            if (object.winnerId === currentUser?.ID) anchorElement.href = `${AppRoutes.dashboard.win}/${object.ID}`
            else anchorElement.href = `${AppRoutes.dashboard.lose}/${object.ID}`
        } else {
            anchorElement.href = `${AppRoutes.dashboard.awaiting}/${object.ID}`
        }
        anchorElement.click()
    }, [])

    useEffect(() => {
        if (isLoaded) setTimeout(() => startCurrentGame(), 1000)
    }, [isLoaded])

    useEffect(() => {
        if (!currentGame) navigate(`${AppRoutes.dashboard.game.home}/${gameClientId}`)
    }, [])

    useEffect(() => {
        addEventListener("MatchResult", handleMatchResult)
        addEventListener("GetData", event => console.log("=====> GetData Event", event))
    }, [addEventListener])

    // Render
    if (loadingGame) return <ScreenLoader />
    return (
        <Layout showControlCapsule={false} showHeader={false} fit={true}>
            {!isLoaded && (
                <div className="flex flex-col items-center justify-center min-h-screen px-4">
                    <div className="text-lg">
                        <Trans>Loading The Game</Trans> {game?.name}
                    </div>
                    <Progress percent={loadingProgression * 100} showInfo={false} />
                    <Button
                        className="bg-blue-500 hover:bg-blue-700 border-blue-500 hover:border-blue-700 mt-10 w-72"
                        onClick={() => navigate(-1)}
                        size="large"
                    >
                        <Trans>Back</Trans>
                    </Button>
                </div>
            )}
            <Unity
                className={`w-full ${isLoaded ? "min-h-screen" : "max-h-0"} ${isLoaded ? "visible" : "invisible"}`}
                unityProvider={unityProvider}
            />
        </Layout>
    )
}

export default Play
