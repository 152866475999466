import { Button, Modal } from "antd"
import { tools } from "core/functions/tools"
import { AppRoutes } from "core/utilities/AppRoutes"
import React from "react"
import { useNavigate } from "react-router"
import { Trans } from "react-i18next"

interface Props {
    isOpen: boolean
    setIsOpen: Function
}

const ExitConfirmationModal: React.FC<Props> = ({ isOpen, setIsOpen }) => {
    // States and Hooks
    const navigate = useNavigate()

    // Methods
    const exitUser = () => {
        tools.exitUser()
        navigate(AppRoutes.login)
        return setIsOpen(false)
    }

    // Render
    return (
        <Modal className="offline-modal" visible={isOpen} closable={false} footer={null} centered>
            <div>
                <div className="text-lg font-medium">
                    <Trans>Are You Sure?</Trans>
                </div>

                <div className="flex items-center gap-2 mt-10">
                    <Button
                        type="default"
                        shape="round"
                        size="large"
                        className="w-full text-white"
                        onClick={() => setIsOpen(false)}
                    >
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button
                        type="primary"
                        shape="round"
                        size="large"
                        className="w-full bg-blue-500 border-blue-500 hover:bg-blue-900 hover:border-blue-900"
                        onClick={exitUser}
                    >
                        <Trans>Confirm</Trans>
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default ExitConfirmationModal
