import { Outlet } from "react-router-dom"
import TournamentProvider from "../TournamentProvider"

const GameProviderLayout = () => {
    return (
        <TournamentProvider>
            <Outlet />
        </TournamentProvider>
    )
}

export default GameProviderLayout
