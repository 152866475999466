import { Image, Spin } from "antd"
import { Modal } from "components/template/Modal"
import { AvatarType } from "core/api/types/ProfileTypes"
import { useProfile } from "core/providers/ProfileProvider"
import { Cross } from "core/utilities/Icons"
import { useEffect, useState } from "react"
import { Trans } from "react-i18next"

type Props = {
    onClose: Function
}

export const EditAvatarModal: React.FC<Props> = ({ onClose }) => {
    const [pending, setPending] = useState(false)
    const { avatars, fetchAvatarsList, setAvatar } = useProfile()

    const onSelectAvatar = async (avatar: AvatarType) => {
        setPending(true)
        await setAvatar(avatar)
        setPending(false)
        onClose()
    }

    useEffect(() => {
        fetchAvatarsList()
    }, [])

    if (pending || !avatars)
        return (
            <Modal className="top-0 left-0 bg-opacity-100" onClose={onClose}>
                <div className="w-full h-full text-center">
                    <Spin />
                </div>
            </Modal>
        )

    return (
        <Modal className="top-0 left-0 bg-opacity-90" onClose={onClose}>
            <div className="w-full h-screen px-6 py-14">
                <div className="flex justify-between items-center">
                    <h3 className="text-xl text-white">
                        <Trans>Select Avatar</Trans>
                    </h3>
                    <button className="p-2" onClick={() => onClose()}>
                        <Cross className="w-7 h-7" />
                    </button>
                </div>
                <div className="grid grid-cols-3 gap-x-4 gap-y-2 mt-8 justify-items-center">
                    {avatars.map(avatar => (
                        <Image
                            onClick={() => onSelectAvatar(avatar)}
                            key={`avatar-${avatar.ID}`}
                            preview={false}
                            className="rounded-full"
                            src={avatar.url}
                        />
                    ))}
                </div>
            </div>
        </Modal>
    )
}
