import { Button } from "antd"
import { Layout } from "components/template/Layout"
import { useAuth } from "core/providers/AuthProvider"
import { Trans } from "react-i18next"

const Login = () => {
    // States and Hooks
    const { guestLoginButtonLoading, onGuestLoginButtonClick } = useAuth()

    // Render
    return (
        <Layout showHeader={false} showControlCapsule={false} fit={true}>
            <div className="flex flex-col min-h-screen justify-center items-center p-8">
                <Button
                    type="link"
                    shape="round"
                    className="text-center w-full border-gray-500 border-solid h-14"
                    onClick={() => onGuestLoginButtonClick()}
                    loading={guestLoginButtonLoading}
                >
                    <span className="text-gray-300 text-lg">
                        <Trans>Login as Guest</Trans>
                    </span>
                </Button>
            </div>
        </Layout>
    )
}

export default Login
