import { useApi } from "core/api/ApiProvider"
import Cache from "core/api/Cache"
import { ShopOfferType } from "core/api/types/ShopTypes"
import { createContext, useContext, useState, useEffect } from "react"
import { useApp } from "./AppProvider"
import { ShopErrors } from "core/utilities/ErrorMessages"
import { toast } from "react-toastify"
type Props = {
    children: JSX.Element
}

interface ShopContextInterface {
    loadingPackages: boolean
    moneyPackages: ShopOfferType[] | null
    goldPackages: ShopOfferType[] | null
    purchaseSilverByGold: Function
    error: any
    clearError: Function
    isConfirmationModalOpen: boolean
    setIsConfirmationModalOpen: Function
    currentSelectedPack: ShopOfferType | null
    setCurrentSelectedPack: Function
    convertButtonLoading: boolean
    setConvertButtonLoading: Function
    purchaseButtonLoding: boolean
    setPurchaseButtonLoding: Function
    purchaseGoldByMoney: Function
    verifyPurchaseLoading: boolean
    setVerifyPurchaseLoading: Function
    verifyPurchase: Function
    paymentStatus: boolean
}

const initialContextValue: ShopContextInterface = {
    loadingPackages: true,
    moneyPackages: null,
    goldPackages: null,
    error: null,
    clearError: () => undefined,
    purchaseSilverByGold: () => undefined,
    isConfirmationModalOpen: false,
    setIsConfirmationModalOpen: () => undefined,
    currentSelectedPack: null,
    setCurrentSelectedPack: () => undefined,
    convertButtonLoading: false,
    setConvertButtonLoading: () => undefined,

    purchaseButtonLoding: false,
    setPurchaseButtonLoding: () => undefined,

    purchaseGoldByMoney: () => undefined,

    verifyPurchaseLoading: true,
    setVerifyPurchaseLoading: () => undefined,

    verifyPurchase: () => undefined,

    paymentStatus: false,
}

const ShopContext = createContext<ShopContextInterface>(initialContextValue)

const ShopCache = new Cache({})

export const useShop = () => useContext(ShopContext)

const ShopProvider: React.FC<Props> = ({ children }) => {
    const { shop } = useApi()
    const { currentUser, loadingApp, setCurrentUser, fetchUser } = useApp()
    const [moneyPackages, setMoneyPackages] = useState<ShopOfferType[] | null>(null)
    const [goldPackages, setGoldPackages] = useState<ShopOfferType[] | null>(null)
    const [error, setError] = useState<any>(null)
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)
    const [currentSelectedPack, setCurrentSelectedPack] = useState<ShopOfferType | null>(null)
    const [convertButtonLoading, setConvertButtonLoading] = useState<boolean>(false)
    const [purchaseButtonLoding, setPurchaseButtonLoding] = useState<boolean>(false)
    const [verifyPurchaseLoading, setVerifyPurchaseLoading] = useState<boolean>(true)
    const [paymentStatus, setPaymentStatus] = useState<boolean>(false)

    const fetchPackages = async () => {
        const { data, error } = await ShopCache.bindReq(() => shop.fetch({ ID: currentUser?.ID }), "packages")
        if (!error) {
            setMoneyPackages([...data.money])
            return setGoldPackages([...data.gold])
        }
        setMoneyPackages([])
        return setGoldPackages([])
    }

    const purchaseSilverByGold = async () => {
        if (!currentUser || !currentSelectedPack) return
        if (currentUser?.goldCoin < currentSelectedPack?.price) {
            setCurrentSelectedPack(null)
            setError(ShopErrors.NotEnoughGold)
        }
        setConvertButtonLoading(true)
        const { data, error, errorObj } = await shop.purchase({
            ID: currentUser?.ID,
            sku: currentSelectedPack?.sku,
        })
        setConvertButtonLoading(false)

        if (errorObj?.response?.status === 403) return setError(ShopErrors.NotEnoughGold)
        if (error) return setError("عملیات ناموفق")
        return setCurrentUser({
            ...currentUser,
            silverCoin: currentUser.silverCoin + Number(data.amount),
            goldCoin: currentUser.goldCoin - currentSelectedPack.price,
        })
    }

    const purchaseGoldByMoney = async (pack: ShopOfferType) => {
        if (!currentUser || !pack) return
        setPurchaseButtonLoding(true)
        const { data, error } = await shop.purchase({
            ID: currentUser?.ID,
            sku: pack?.sku,
        })
        setPurchaseButtonLoding(false)
        if (error) return setError("عملیات ناموفق")
        toast.success("در حال انتقال به صفحه پرداخت")
        return (window.location = data.url)
    }

    const clearError = () => setError(null)

    const verifyPurchase = async () => {
        setVerifyPurchaseLoading(true)
        const { data, error } = await shop.verify({ ID: currentUser?.ID, options: window.location.search })
        await fetchUser()
        setVerifyPurchaseLoading(false)

        if (error) return setPaymentStatus(false)
        return setPaymentStatus(true)
    }

    const value = {
        loadingPackages: goldPackages === null || moneyPackages === null,
        fetchPackages,
        moneyPackages,
        goldPackages,
        convertButtonLoading,
        setConvertButtonLoading,

        purchaseSilverByGold,
        purchaseGoldByMoney,

        error,
        clearError,
        isConfirmationModalOpen,
        setIsConfirmationModalOpen,

        currentSelectedPack,
        setCurrentSelectedPack,

        purchaseButtonLoding,
        setPurchaseButtonLoding,

        verifyPurchaseLoading,
        setVerifyPurchaseLoading,

        verifyPurchase,

        paymentStatus,
    }

    useEffect(() => {
        if (!loadingApp) fetchPackages()
    }, [loadingApp])

    return <ShopContext.Provider value={value}>{children}</ShopContext.Provider>
}
export default ShopProvider
