import { ScreenLoader } from "components/template"
import { tools } from "core/functions/tools"
import { AppRoutes } from "core/utilities/AppRoutes"
import { useEffect } from "react"
import { useNavigate } from "react-router"

const Android = () => {
    // States and Hooks

    const navigate = useNavigate()

    // Methods
    useEffect(() => {
        tools.setCookie("utm_source", "bazaar", 1000)
        return navigate(AppRoutes.dashboard.home, { replace: true })
    }, [])

    // Render
    return <ScreenLoader />
}

export default Android
