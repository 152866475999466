import { Image } from "antd"
import { Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { Layout } from "components/template/Layout"
import { useGame } from "core/providers/GameProvider"

// Import Swiper Slides
import "swiper/css/pagination"
import "swiper/css"
import { Onboarding1, Onboarding2, Onboarding3 } from "core/utilities/ImageImport"
import { ArrowLeft } from "core/utilities/Icons"
import { useNavigate } from "react-router"
import { ScreenLoader } from "components/template"

const Onboarding = () => {
    // States and Hooks
    const navigate = useNavigate()
    const { loadingGame, game } = useGame()

    // Methods

    // Render
    if (loadingGame) return <ScreenLoader />

    return (
        <Layout fit={true} showControlCapsule={false} showHeader={false}>
            <div className="min-h-screen">
                <div className="absolute top-0 left-0 z-50 px-4">
                    <nav className="flex justify-between items-center w-full ltr z-10 py-3">
                        <button
                            className="bg-black bg-opacity-30 backdrop-blur-lg p-2 rounded-full"
                            onClick={() => navigate(-1)}
                        >
                            <ArrowLeft className="w-5 h-5" />
                        </button>
                    </nav>
                </div>
                <Swiper
                    slidesPerView={1}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="min-w-screen min-h-screen"
                    loop={true}
                >
                    {(game?.tutorials ?? [Onboarding1, Onboarding2, Onboarding3]).map((picture, index) => {
                        return (
                            <SwiperSlide className="w-screen" key={index}>
                                <Image src={picture} preview={false} className="object-contain" />
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
        </Layout>
    )
}

export default Onboarding
