import { Link, useNavigate } from "react-router-dom"

// Utilities
import { AcademicHat, ArrowLeft } from "core/utilities/Icons"
import { Silver, Gold } from "core/utilities/ImageImport"
import { AppRoutes } from "core/utilities/AppRoutes"

// Functions
import { tools } from "core/functions/tools"

// Components
import { Layout } from "components/template/Layout"
import { StatisticsList } from "components/tournament/statistics/StatisticsList"

// Mock Data
import { data } from "./data"
import { useTournament } from "core/providers/TournamentProvider"

const Statistics = () => {
    const navigate = useNavigate()
    const { tournament } = useTournament()

    return (
        <>
            <Layout showControlCapsule={false} showHeader={false}>
                {/* Header bg picture */}
                <nav className="z-10 flex items-center justify-between w-full py-3 ltr">
                    <button
                        className="p-2 bg-white rounded-full bg-opacity-30 backdrop-blur-lg"
                        onClick={() =>
                            navigate(`${AppRoutes.dashboard.tournament.game.home}/${tournament?.tournamentId}`)
                        }
                    >
                        <ArrowLeft className="w-5 h-5" />
                    </button>
                    <div className="py-1 px-1.5 rounded-full bg-white bg-opacity-30 backdrop-blur-lg">
                        <div className="flex gap-2.5">
                            <div className="flex gap-1">
                                <img src={Gold} alt="gold" />
                                <span className="font-semibold">
                                    {tools.thousandsSeparator(data?.currentUser?.goldCoin ?? 0)}
                                </span>
                            </div>
                            <div className="flex gap-1">
                                <img src={Silver} alt="gold" />
                                <span className="font-semibold">
                                    {tools.thousandsSeparator(data?.currentUser?.silverCoin ?? 0)}
                                </span>
                            </div>
                        </div>
                    </div>
                    <Link
                        to={`${AppRoutes.dashboard.game.onboarding}/${data?.game?.clientId}`}
                        className="p-2 bg-white rounded-full bg-opacity-30 backdrop-blur-lg"
                    >
                        <AcademicHat className="w-5 h-5" />
                    </Link>
                </nav>

                {/* Statistics */}
                <StatisticsList statistics={data?.statistics} />

                <div className="py-16"></div>
            </Layout>
        </>
    )
}

export default Statistics
