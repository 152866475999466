import { Outlet } from "react-router-dom"
import ProfileProvider from "../ProfileProvider"

const ProfileProviderLayout = () => {
    return (
        <ProfileProvider>
            <Outlet />
        </ProfileProvider>
    )
}

export default ProfileProviderLayout
