import { useApi } from "core/api/ApiProvider"
import Cache from "core/api/Cache"
import { AvatarType, ProfileType } from "core/api/types/ProfileTypes"
import { createContext, useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useApp } from "./AppProvider"

type Props = {
    children: JSX.Element
}

interface ProfileContextInterface {
    error: string | null
    profile: ProfileType | null
    loadingProfile: boolean
    avatars: AvatarType[] | null
    setReferralCode: Function
    postGiftCode: Function
    changeName: Function
    fetchAvatarsList: () => void
    setAvatar: (avatar: AvatarType) => void
}

const initialContextValue: ProfileContextInterface = {
    error: null,
    profile: null,
    loadingProfile: true,
    avatars: [],
    setReferralCode: () => undefined,
    postGiftCode: () => undefined,
    changeName: () => undefined,
    fetchAvatarsList: () => undefined,
    setAvatar: () => undefined,
}

const ProfileContext = createContext<ProfileContextInterface>(initialContextValue)

export const useProfile = () => useContext(ProfileContext)

const profileCache = new Cache({})

const ProfileProvider: React.FC<Props> = ({ children }) => {
    // States and Hooks
    const { user } = useApi()
    const { currentUser, setCurrentUser, loadingApp } = useApp()
    const [error, setError] = useState<string | null>(null)
    const [profile, setProfile] = useState<ProfileType | null>(null)
    const [avatars, setAvatars] = useState<ProfileContextInterface["avatars"]>(null)
    // Methods
    const fetchProfile = async () => {
        const { data, error } = await profileCache.bindReq(
            () => user.fetchProfile({ ID: currentUser?.ID }),
            "profileData"
        )
        if (!error) return setProfile(data)
    }

    const setReferralCode = async (code: string) => {
        const { error } = await user.setReferralCode({ ID: currentUser?.ID, code })
        if (error) {
            toast.error("کد وارد شده معتبر نمی باشد.")
            return setError(error)
        }
        toast.success("کد معرفی با موفقیت ثبت شد.")
    }

    const postGiftCode = async (code: string) => {
        const { error } = await user.postGiftCode({ ID: currentUser?.ID, code })
        if (error) {
            toast.error("کد وارد شده معتبر نمی باشد.")
            return setError(error)
        }
        toast.success("کد هدیه با موفقیت ثبت شد.")
    }

    const changeName = async (name: string) => {
        const { error } = await user.changeName({ ID: currentUser?.ID, name })

        if (error) return toast.error("متاسفیم! مشکلی در تغییر نام پیش آمد.")
        setCurrentUser({ ...currentUser, name: name })
        return toast.success("نام مستعار با موفقیت تغییر یافت.")
    }
    const fetchAvatarsList = async () => {
        const { data, error } = await profileCache.bindReq(
            () => user.fetchAvatarList({ ID: currentUser?.ID }),
            "avatarsList"
        )
        if (error) setError(error)
        setAvatars(data.Avatars)
    }

    const setAvatar = async (avatar: AvatarType) => {
        const { data, error } = await user.setAvatar({ ID: currentUser?.ID, avatarId: avatar.ID })
        if (error) setError(error)
        setCurrentUser({ ...currentUser, avatarUrl: avatar.url })
        toast.success("آواتار با موفقیت تغییر یافت")
    }

    useEffect(() => {
        if (!loadingApp) fetchProfile()
    }, [loadingApp])

    // Binding
    const value = {
        error,
        profile,
        avatars,
        loadingProfile: profile === null,
        setReferralCode,
        postGiftCode,
        changeName,
        fetchAvatarsList,
        setAvatar,
    }

    // Render
    return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
}

export default ProfileProvider
