import { Row, Spin } from "antd"
import { ScreenLoader } from "components/template"
import { Layout } from "components/template/Layout"
import { LeaderBoardItem } from "components/tournament/game/LeaderBoardItem"
import { tools } from "core/functions/tools"
import { useApp } from "core/providers/AppProvider"
import { useTournament } from "core/providers/TournamentProvider"
import { AppRoutes } from "core/utilities/AppRoutes"
import { ArrowLeft } from "core/utilities/Icons"
import { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

const TournamentLeaderBoard = () => {
    // States and Hooks
    const size = 20
    const navigate = useNavigate()
    const { currentUser, loadingApp } = useApp()
    const [currentPage, setCurrentPage] = useState<number>(1)
    const { loadingTournament, leaderBoardData, fetchLeaderBoardData, tournament } = useTournament()
    const [loadingNewData, setLoadingNewData] = useState<boolean>(false)
    const [isFinished, setIsFinished] = useState<boolean>(false)
    const { i18n } = useTranslation()
    // Methods

    useEffect(() => {
        if (!loadingApp) {
            const scrollHandler = () => {
                const element = document.getElementById("leader-board-full-list")
                const bottom: number = element?.getBoundingClientRect().bottom ?? 0
                const windowInnerHeight: number = window.innerHeight
                const isBottom: boolean = bottom <= windowInnerHeight

                if (isBottom) setCurrentPage(prev => prev + 1)
            }

            window.addEventListener("scroll", () => scrollHandler())
            return window.removeEventListener("scroll", () => scrollHandler())
        }
    }, [loadingApp])

    const fetchNewData = async () => {
        setLoadingNewData(true)
        const total = await fetchLeaderBoardData({ size: size, page: currentPage, continuous: true })
        if (total === null) setIsFinished(true)
        await tools.sleep(1500)
        setLoadingNewData(false)
    }

    useEffect(() => {
        if (!loadingApp && currentPage !== 1 && isFinished === false) fetchNewData()
    }, [currentPage, loadingApp])

    // Render
    if (loadingTournament) return <ScreenLoader />
    return (
        <Layout showHeader={false} showControlCapsule={false}>
            <Row
                className="fixed w-full lg:w-[430px] top-0 bg-primary-main bg-opacity-0 -mx-4 px-4 z-50"
                justify={i18n.language === "fa" ? "end" : "start"}
                align="middle"
            >
                <nav className="ltr z-10 py-5 bg-transparent">
                    <button
                        className="bg-white bg-opacity-30 backdrop-blur-lg p-2 rounded-full"
                        onClick={() =>
                            navigate(`${AppRoutes.dashboard.tournament.game.home}/${tournament?.tournamentId}`)
                        }
                    >
                        <ArrowLeft className="w-5 h-5" />
                    </button>
                </nav>
            </Row>
            <div className="text-2xl font-bold mb-6 mt-20">
                <Trans>Leaderboard</Trans>
            </div>
            <div id="leader-board-full-list">
                {leaderBoardData.total.map((_, index) => (
                    <LeaderBoardItem
                        key={`leader-board-item-${_.userID}`}
                        item={_}
                        listNumber={index + 1}
                        isHighlighted={_.userID === currentUser?.ID}
                    />
                ))}
            </div>
            <div>
                {loadingNewData && (
                    <div className="text-center">
                        <Spin />
                    </div>
                )}
            </div>
        </Layout>
    )
}

export default TournamentLeaderBoard
