import { tools } from "core/functions/tools"
import { axiosRequest } from "../axiosRequest"

const FifaWorldCupController = {
    login: async (args: { phoneNumber: string }) => {
        const token = tools.getUserToken()
        return await axiosRequest({
            method: "POST",
            url: "/users",
            body: {
                phoneNumber: args.phoneNumber,
                deviceToken: token,
            },
            headers: null,
            isWorldCup: true,
        })
    },
    leaderboard: async () => {
        const token = tools.getFifaUserToken()
        return await axiosRequest({
            method: "GET",
            url: "/leader-board",
            body: null,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            isWorldCup: true,
        })
    },
    matches: async () => {
        const token = tools.getFifaUserToken()
        return await axiosRequest({
            method: "GET",
            url: "/matches",
            body: null,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            isWorldCup: true,
        })
    },
    history: async () => {
        const token = tools.getFifaUserToken()
        return await axiosRequest({
            method: "GET",
            url: "/users/pre",
            body: null,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            isWorldCup: true,
        })
    },
    info: async () => {
        const token = tools.getFifaUserToken()
        return await axiosRequest({
            method: "GET",
            url: "/user/info",
            body: null,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            isWorldCup: true,
        })
    },
    placeBet: async (args: { matchId: string; guess: string }) => {
        const token = tools.getFifaUserToken()
        return await axiosRequest({
            method: "POST",
            url: "/users/pre",
            body: {
                matchID: args.matchId,
                playerWinner: args.guess,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
            isWorldCup: true,
        })
    },
    fetch: async (args: { matchId: string }) => {
        const token = tools.getFifaUserToken()
        return await axiosRequest({
            method: "POST",
            url: "/match",
            body: {
                matchID: args.matchId,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
            isWorldCup: true,
        })
    },
}

export { FifaWorldCupController }
