import { Outlet } from "react-router-dom"
import GameProvider from "../GameProvider"

const GameProviderLayout = () => {
    return (
        <GameProvider>
            <Outlet />
        </GameProvider>
    )
}

export default GameProviderLayout
