import { useApp } from "./AppProvider"

// API
import { useApi } from "core/api/ApiProvider"
import Cache from "core/api/Cache"
import { createContext, useContext, useEffect, useState } from "react"

// Types
import { GameType } from "core/api/types/GameTypes"
import { TournamentType } from "core/api/types/TournamentTypes"

/* Defining the interface for the context. */
interface HomeContextInterface {
    loadingGames: boolean
    allGames: GameType[] | null
    loadingTournaments: boolean
    allTournaments: TournamentType[] | null
}

/* Setting the initial value of the context. */
const initialContextValue = {
    loadingGames: true,
    allGames: null,
    loadingTournaments: true,
    allTournaments: null,
}

const HomeContext = createContext<HomeContextInterface>(initialContextValue)

const HomeCache = new Cache({})

export const useHome = () => useContext(HomeContext)

type Props = {
    children: JSX.Element
}

const HomeProvider: React.FC<Props> = ({ children }) => {
    // States and Hooks
    const { games, tournaments } = useApi()
    const { currentUser, loadingApp } = useApp()
    const [allGames, setAllGames] = useState<GameType[] | null>(null)
    const [allTournaments, setAllTournaments] = useState<TournamentType[] | null>(null)
    const loadingGames = allGames === null
    const loadingTournaments = allTournaments === null

    // Methods
    const fetchGames = async () => {
        const { data, error } = await HomeCache.bindReq(() => games.fetch({ ID: currentUser?.ID }), "games")
        if (!error) return setAllGames(data)
    }

    const fetchTournaments = async () => {
        const { data, error } = await HomeCache.bindReq(() => tournaments.fetch({ ID: currentUser?.ID }), "tournaments")

        const activeTournaments = data
            .filter((_: any) => _.isActive === true)
            .sort((a: any, b: any) => new Date(a.endTime).getTime() - new Date(b.endTime).getTime())
        const inActiveTournaments = data
            .filter((_: any) => _.isActive === false)
            .sort((a: any, b: any) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime())

        if (!error) return setAllTournaments([...activeTournaments, ...inActiveTournaments])
    }

    useEffect(() => {
        if (!loadingApp) {
            fetchGames()
            fetchTournaments()
        }
    }, [loadingApp])

    // Binding
    const value = {
        // States
        loadingGames,
        loadingTournaments,

        allGames,
        setAllGames,

        allTournaments,
        setAllTournaments,
    }

    // Render
    return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>
}

export default HomeProvider
