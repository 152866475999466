import { createContext, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import { useApp } from "./AppProvider"

// API
import { TournamentLeaderBoardType, TournamentType } from "core/api/types/TournamentTypes"
import { useApi } from "core/api/ApiProvider"

// Utilities
import { GameErrors } from "core/utilities/ErrorIndicators"
import { AppRoutes } from "core/utilities/AppRoutes"

// Interfaces & Types
type Props = {
    children: JSX.Element
}
interface TournamentContextInterface {
    loadingTournament: boolean
    tournament: TournamentType | null
    currentError: string | null
    clearError: () => void
    startTournament: Function
    onGoingTournamentData: any

    loadingStartTournamentButton: boolean
    setLoadingStartTournamentButton: Function

    currentStartedTournamentRank: number
    setCurrentStartedTournamentRank: Function

    leaderBoardData: {
        total: TournamentLeaderBoardType[]
        close: TournamentLeaderBoardType[]
    }
    setLeaderBoardData: Function
    isLoaderBoardLoading: boolean
    fetchLeaderBoardData: Function
}

/* The initial value of the context. */
const initialContextValue: TournamentContextInterface = {
    loadingTournament: true,
    tournament: null,
    currentError: null,
    clearError: () => undefined,
    startTournament: () => undefined,
    onGoingTournamentData: null,

    loadingStartTournamentButton: false,
    setLoadingStartTournamentButton: () => undefined,

    currentStartedTournamentRank: 0,
    setCurrentStartedTournamentRank: () => undefined,

    leaderBoardData: { total: [], close: [] },
    setLeaderBoardData: () => undefined,
    isLoaderBoardLoading: false,
    fetchLeaderBoardData: () => undefined,
}

const TournamentContext = createContext<TournamentContextInterface>(initialContextValue)

export const useTournament = () => useContext(TournamentContext)

const TournamentProvider: React.FC<Props> = ({ children }) => {
    // States and Hooks
    const navigate = useNavigate()
    const { tournaments } = useApi()
    const { currentUser, loadingApp } = useApp()
    const [tournament, setTournament] = useState<TournamentType | null>(null)
    const { tournament_client_id: tournamentClientId } = useParams()
    const [currentError, setCurrentError] = useState<TournamentContextInterface["currentError"]>(null)
    const [onGoingTournamentData, setOnGoingTournamentData] = useState<any>(null)
    const loadingTournament: boolean = tournament === null

    const [currentStartedTournamentRank, setCurrentStartedTournamentRank] = useState<number>(0)
    const [loadingStartTournamentButton, setLoadingStartTournamentButton] = useState<boolean>(false)

    const [leaderBoardData, setLeaderBoardData] = useState<{
        total: TournamentLeaderBoardType[]
        close: TournamentLeaderBoardType[]
    }>(initialContextValue.leaderBoardData)
    const [isLoaderBoardLoading, setIsLeaderBoardLoading] = useState<boolean>(true)

    // Methods
    const fetchTournament = async () => {
        const { data, error } = await tournaments.findOne({ tournamentClientId, ID: currentUser?.ID })

        if (!error) {
            setTournament(data.tournament)
        }
    }

    const startTournament = async (args: { tournamentID: string; entranceFee: number }) => {
        // Error handling here.
        if (!currentUser) return
        if (currentUser.silverCoin < args.entranceFee) return setCurrentError(GameErrors.NOT_ENOUGH_COINS)

        setLoadingStartTournamentButton(true)
        const { data, error } = await tournaments.start({
            clientID: tournament?.game.clientId,
            ID: currentUser?.ID,
            tournamentID: args.tournamentID,
        })
        setLoadingStartTournamentButton(false)

        if (error) return setCurrentError(GameErrors.PROBLEM_STARTING)
        setOnGoingTournamentData(data)
        return navigate(`${AppRoutes.dashboard.tournament.versus}/${tournamentClientId}`)
    }

    const clearError = () => setCurrentError(null)

    const fetchLeaderBoardData = async (args: { size: number; page: number; continuous: boolean }) => {
        const { data, error } = await tournaments.getLeaderBoard({
            ID: currentUser?.ID,
            gameId: tournamentClientId,
            size: args.size,
            page: args.page,
        })
        if (error) return setCurrentError(error)

        setIsLeaderBoardLoading(false)

        const total: TournamentLeaderBoardType[] | null = data.total
        const close: TournamentLeaderBoardType[] | null = data.close

        const newLeaderBoardData = args.continuous ? leaderBoardData : { total: [], close: [] }

        if (total) newLeaderBoardData.total = [...newLeaderBoardData.total, ...total]
        if (close) newLeaderBoardData.close = [...newLeaderBoardData.close, ...close]

        setLeaderBoardData(newLeaderBoardData)
        return total
    }

    // Binding
    const value = {
        // States
        tournament,
        loadingTournament,
        currentError,
        onGoingTournamentData,

        loadingStartTournamentButton,
        setLoadingStartTournamentButton,

        currentStartedTournamentRank,
        setCurrentStartedTournamentRank,

        leaderBoardData,
        setLeaderBoardData,
        isLoaderBoardLoading,

        // Methods
        clearError,
        startTournament,
        fetchLeaderBoardData,
    }

    useEffect(() => {
        if (!loadingApp) {
            fetchTournament()
            fetchLeaderBoardData({ size: 20, page: 1, continuous: false })
        }
    }, [loadingApp])

    // Render
    return <TournamentContext.Provider value={value}>{children}</TournamentContext.Provider>
}

export default TournamentProvider

export const RankToMedal = {
    2: "Bronze",
    3: "Silver",
    4: "Gold",
}

export const MedalTypeLocalization: { [key: string]: string } = {
    Qualification: "دست گرمی",
    Bronze: "برنز",
    Silver: "نقره",
    Gold: "طلا",
    Diamond: "الماس",
    Champion: "قهرمانان",
    Legend: "افسانه ای",
}

export const ActivityTypeObject = {
    Match: 0,
    Tournament: 1,
}
