import { Button, Skeleton } from "antd"
import { Clock } from "core/utilities/Icons"
import { GoldBig, SilverBig } from "core/utilities/ImageImport.js"
import { BlurBg } from "./BlurBg"
interface Props {
    action: React.ReactNode
    description?: string
    bgImageUrl: string
    rewardData: {
        type: "gold" | "silver"
        amount: number
    }
    offerExpireDate?: Date

    actionHandler: () => void
}

interface IOfferCard extends React.FC<Props> {
    Skeleton: React.FC<{}>
}

const OfferCard: IOfferCard = ({ action, description, bgImageUrl, rewardData, offerExpireDate, actionHandler }) => {
    return (
        <div className="flex flex-col relative w-72 h-36 py-3 px-4">
            {/* This is background image */}
            <img
                className="absolute top-0 left-0 w-full h-full rounded-lg object-fit"
                src={bgImageUrl}
                alt="Earn coin background"
            />

            <div className="flex justify-end">
                {offerExpireDate && (
                    <div className="w-fit  h-fit px-2 py-1 relative">
                        <BlurBg className="bg-black bg-opacity-20 rounded-full" />
                        <div className="flex gap-1 relative z-10">
                            <Clock className="w-4 h-4" />
                            <span>{offerExpireDate.getHours()} ساعت</span>
                            <span>و</span>
                            <span>{offerExpireDate.getMinutes()} دقیقه</span>
                        </div>
                    </div>
                )}
                <div className="flex items-center gap-1 z-10">
                    <div className="text-xl">{rewardData.amount}</div>
                    <img className="w-7 h-7" src={rewardData.type === "gold" ? GoldBig : SilverBig} alt="coin" />
                </div>
            </div>

            <div className="flex flex-col mt-auto w-full ">
                <div className="w-fit relative px-4 py-2">
                    <BlurBg className="bg-black bg-opacity-20 rounded-full z-10" />
                    <span className="relative rounded-full text-xs z-20">{description}</span>
                </div>
                <div className="relative px-3 py-1 mt-2 text-center">
                    <BlurBg className="bg-black bg-opacity-20 rounded-xl z-10" />
                    <Button
                        onClick={actionHandler}
                        className="rounded-2xl h-10 border-0 hover:opacity-80 z-20 w-full text-center"
                        type="link"
                    >
                        <div className="z-20 text-center">{action}</div>
                    </Button>
                </div>
                {/* <div className="w-fit relative px-4 py-2">
                    <Button
                        onClick={actionHandler}
                        className="rounded-2xl mt-1 h-10 border-0 hover:opacity-80"
                        type="link"
                    >
                        <BlurBg className="bg-black bg-opacity-20 rounded-full z-10" />
                        {action}
                    </Button>
                </div> */}
            </div>
        </div>
    )
}

OfferCard.Skeleton = () => (
    <div className="w-72 h-36">
        <Skeleton.Image className="offer-card-skeleton" active />
    </div>
)

export { OfferCard }
