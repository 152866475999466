import { Layout } from "components/template/Layout"
import { ArrowLeft, CopyOutlined } from "core/utilities/Icons"
import { useNavigate } from "react-router"
import { Gold, InvitePageHeader } from "core/utilities/ImageImport"
import { Button } from "antd"
import { Step, StepIndicatorContainer } from "components/invite-friends/StepIndicatorContainer"
import { StepIndicator } from "components/invite-friends/StepIndicator"
import { toast } from "react-toastify"
import { useApp } from "core/providers/AppProvider"
import { ScreenLoader } from "components/template"
import { Trans } from "react-i18next"
import i18n from "../i18n"

const InviteFriends = () => {
    // States and Hooks
    const navigate = useNavigate()
    const { currentUser } = useApp()
    const steps: Step[] = [
        {
            isCompleted: true,
            stepNumber: 5,
        },
        {
            isCompleted: true,

            stepNumber: 10,
        },
        {
            isCompleted: false,
            stepNumber: 15,
        },
        {
            isCompleted: false,
            stepNumber: 20,
        },
    ]
    const inviteCode = currentUser?.tag ?? ""

    // Methods
    const onCopyButtonClick = () => {
        toast.success(i18n.language === "fa" ? "کد دعوت کپی شد!" : "Copied invite code")
        navigator.clipboard.writeText(inviteCode)
    }

    if (inviteCode === "") return <ScreenLoader />

    // Render
    return (
        <>
            <Layout showControlCapsule={false} showHeader={false}>
                <img
                    className="absolute z-20 rounded-b-5xl w-full h-[40vh] object-cover -mx-4"
                    src={InvitePageHeader}
                    alt=""
                />
                <nav className="flex justify-center items-center w-full ltr py-4 relative z-30">
                    <button
                        className="absolute left-0 bg-slate-200 bg-opacity-20 p-2 rounded-full"
                        onClick={() => navigate(-1)}
                    >
                        <ArrowLeft className="w-5 h-5" />
                    </button>
                    <span className="bg-slate-200 bg-opacity-50 rounded-full text-center px-4 py-1">
                        <Trans>Invite Friends</Trans>
                    </span>
                </nav>

                <div className="py-[20vh]"></div>
                <div className="inline text-xl text-center mx-auto">
                    <Trans>Invite Friends Body</Trans>
                    {/* CONTENT: image between text */}
                    {/* با دعوت از دوستان خود 1 سکه
                    <img src={Gold} className="w-4 h-4 inline mx-1" alt="" /> دریافت کنید */}
                </div>

                <div className="flex justify-between mt-12 px-6 py-2 rounded-3xl bg-primary-light">
                    <div>
                        <span className="text-slate-400">
                            <Trans>Your Invitation Code</Trans>
                        </span>
                        <h2 className="text-white text-lg font-bold">{inviteCode}</h2>
                    </div>
                    <Button className="w-12 h-12 p-0 border-0" type="text" shape="circle" onClick={onCopyButtonClick}>
                        <CopyOutlined className="w-7 h-7" />
                    </Button>
                </div>

                {/* <div className="mt-5">
                    <StepIndicatorContainer progressPercentage={33}>
                        {steps.map(step => (
                            <StepIndicator
                                key={`step-${step.stepNumber}`}
                                className={`${step.isCompleted ? "bg-green-400" : "bg-primary-light"}`}
                            >
                                {step.stepNumber}
                            </StepIndicator>
                        ))}
                    </StepIndicatorContainer>
                </div> */}

                {/* <div className="flex justify-between mt-4 ltr">
                    <span className="flex gap-1">
                        5
                        <img className="inline" src={Gold} alt="gold coin" />
                    </span>
                    <span className="flex gap-1">
                        65
                        <img className="inline" src={Gold} alt="gold coin" />
                    </span>
                    <span className="flex gap-1">
                        65
                        <img className="inline" src={Gold} alt="gold coin" />
                    </span>
                    <span className="flex gap-1">
                        65
                        <img className="inline" src={Gold} alt="gold coin" />
                    </span>
                </div> */}

                {/* <Button
                    type="ghost"
                    className="bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600  border-0 rounded-lg h-12 text-lg mt-auto"
                >
                    دعوت از دوستان
                </Button> */}
            </Layout>
        </>
    )
}

export default InviteFriends
