import { Button } from "antd"
import { Modal } from "components/template/Modal"
import { AppRoutes } from "core/utilities/AppRoutes"
import { Cross } from "core/utilities/Icons"
import { Silver } from "core/utilities/ImageImport"
import { useNavigate } from "react-router"
type Props = {
    onClose: Function
}

export const NotEnoughCoinsModal: React.FC<Props> = ({ onClose }) => {
    const navigate = useNavigate()
    return (
        <Modal onClose={onClose}>
            <div className="flex flex-col gap-2 bg-gradient-to-tr from-slate-900 to-slate-700 w-5/6 p-4 rounded-2xl mx-auto">
                <button
                    onClick={() => onClose()}
                    className="flex items-center justify-center bg-white bg-opacity-20 rounded-full p-1 w-7 h-7"
                >
                    <Cross className="w-full h-full" />
                </button>
                <div className="px-8 text-center">
                    <h3 className="text-white text-2xl ">سکه کافی ندارید!</h3>

                    <div className="mt-4">
                        <span className="text-base">برای خرید سکه نقره ای</span>
                        <img className="inline mx-1" src={Silver} alt="" />
                        <span className="text-base">به فروشگاه مراجعه کنید</span>
                    </div>
                </div>
                <Button
                    type="primary"
                    className="bg-gradient-to-tr from-indigo-500 to-sky-400 h-12 rounded-xl border-0 mt-8 hover:bg-gradient-to-tr hover:from-indigo-500 hover:to-sky-400"
                    onClick={() => navigate(AppRoutes.dashboard.shop.home)}
                >
                    <span className="text-lg">فروشگاه</span>
                </Button>
            </div>
        </Modal>
    )
}
