import { Header } from "components/template/Header"
import { ControlCapsule } from "components/template/ControlCapsule"
import { AppRoutes, ProtectedRoutes } from "core/utilities/AppRoutes"
import { useApp } from "core/providers/AppProvider"
import { ScreenLoader } from "./ScreenLoader"

interface Props {
    showControlCapsule?: boolean
    showHeader?: boolean
    fit?: boolean
    customBackground?: boolean
    children?: React.ReactNode
}

export const Layout: React.FC<Props> = ({
    showControlCapsule = true,
    showHeader = true,
    fit = false,
    customBackground = false,
    children,
}) => {
    // States and Hooks
    const { loadingApp } = useApp()
    const pageUrl = window.location.pathname
    const hasBackButton = !(
        pageUrl === AppRoutes.dashboard.activity ||
        pageUrl === AppRoutes.dashboard.shop.home ||
        pageUrl === AppRoutes.dashboard.home
    )

    // Render
    if (loadingApp && ProtectedRoutes.find(item => pageUrl.includes(item))) return <ScreenLoader />
    return (
        <div>
            {showHeader && (
                <>
                    <Header
                        points={{ gold: 12, silver: 65 }}
                        hasBackButton={hasBackButton}
                        avatarUrl={
                            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQh59gL9H1p0OG9YcXqXmBFsJ8_8oOBDBCE8exlwuGJGw&s"
                        }
                    />
                </>
            )}
            <div
                className={`flex flex-col w-full relative min-h-screen  text-white
                ${!fit && "pb-4 px-4"} ${!customBackground && ""}`}
            >
                {showHeader && <div className="py-8"></div>}
                {children}
            </div>
            {showControlCapsule && <ControlCapsule />}
        </div>
    )
}
