import { Outlet } from "react-router-dom"
import ShopProvider from "../ShopProvider"

const ShopProviderLayout = () => {
    return (
        <ShopProvider>
            <Outlet />
        </ShopProvider>
    )
}

export default ShopProviderLayout
