import { Col, Row } from "antd"
interface Props {
    levelImage: string
    min: number
    max: number
    current: number
}

const GameProgressIndicator: React.FC<Props> = ({ levelImage, min, max, current }) => {
    return (
        <Row justify="space-between" align="middle" className="ltr">
            <Col span={current > 100 ? 7 : 6} className="flex gap-2 items-center ltr">
                <img className="h-10 w-10" src={levelImage} alt="medal" />
                <span className="text-base text-transparent bg-clip-text bg-gradient-to-r from-green-600 to-green-400">
                    {current}
                </span>
            </Col>
            <Col span={current > 100 ? 12 : 13}>
                <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 ltr">
                    <div
                        className="bg-green-400 h-2.5 rounded-full"
                        style={{
                            width: `${(((current - min) / (max - min)) * 100).toFixed(2)}%`,
                        }}
                    ></div>
                </div>
            </Col>
            <Col span={2} className="flex items-center ltr">
                <span className="text-base">{max}</span>
            </Col>
        </Row>
    )
}

export { GameProgressIndicator }
