import React from "react"
import { StartGameCard } from "./StartGameCard"
import { BronzeMedal, Gold, GoldMedal, Silver, SilverMedal, StartGameGold } from "core/utilities/ImageImport"
import { tools } from "core/functions/tools"
import { Trans, useTranslation } from "react-i18next"

interface Props {
    openingRank: number
    entrance: number
    rewardData?: {
        type: "gold" | "silver"
        amount: number
    }
}
const InActiveStartGame: React.FC<Props> = ({ openingRank, entrance, rewardData }) => {
    // States and Hooks
    const isPractice = openingRank === 0
    const isBronze = openingRank === 2
    const { t } = useTranslation()
    // Render
    return (
        <div className="relative">
            {isPractice || isBronze ? (
                <div className="absolute h-full w-full flex justify-center items-center z-10 bg-black bg-opacity-50 rounded-3xl text-base">
                    <Trans>Unlocks After Trial</Trans>
                </div>
            ) : (
                <div className="absolute h-full w-full flex justify-center items-center z-10 bg-black bg-opacity-50 rounded-3xl text-base">
                    <Trans
                        i18nKey={"Unlocks in"}
                        components={[
                            <img
                                src={openingRank === 2 ? BronzeMedal : openingRank === 3 ? SilverMedal : GoldMedal}
                                className="w-9 h-9 mx-1"
                                alt=""
                            />,
                        ]}
                    />
                </div>
            )}
            <div className="">
                <StartGameCard
                    rank={0}
                    isLocked
                    btnContent={
                        <div className="flex justify-center items-center text-lg gap-2">
                            {!isPractice && (
                                <div className="flex items-center gap-1 z-10 opacity-60">
                                    <span className="font-normal text-sm">{tools.thousandsSeparator(entrance)}</span>
                                    <img src={Silver} className="w-5 h-5" alt="coin" />
                                </div>
                            )}
                            <span>
                                <Trans>Start Match</Trans>
                            </span>
                        </div>
                    }
                    description=" "
                    bgImageUrl={StartGameGold}
                    rewardData={{
                        type: rewardData?.type ?? "silver",
                        amount: Number(rewardData?.amount),
                    }}
                />
            </div>
        </div>
    )
}

export { InActiveStartGame }
