import { Alert, Button, Form, Input, Spin, Modal } from "antd"
import { useApp } from "core/providers/AppProvider"
import { useProfile } from "core/providers/ProfileProvider"
import { AppRoutes } from "core/utilities/AppRoutes"
import { Gold } from "core/utilities/ImageImport"
import { useState } from "react"
import { Link } from "react-router-dom"
import { Cross } from "core/utilities/Icons"
import { Trans } from "react-i18next"
interface Props {
    open: boolean
    onClose: () => void
}

export const ChangeNameModal: React.FC<Props> = ({ open, onClose }) => {
    const { changeName } = useProfile()
    const { currentUser } = useApp()
    const [name, setName] = useState("")
    const [isPending, setIsPending] = useState(false)

    const onConfirmNameChange = async () => {
        setIsPending(true)
        await changeName(name)
        onClose()
        setIsPending(false)
    }

    const CHANGE_NAME_FEE = currentUser?.isNameChanged ? 10 : 0

    const hasEnoughCoin = currentUser!.goldCoin >= CHANGE_NAME_FEE

    return (
        <Modal open={open} footer={null} bodyStyle={{ padding: 0 }} centered onCancel={() => onClose()}>
            <div className="flex flex-col gap-4 px-6 py-12  bg-gradient-to-tr from-slate-900 to-slate-700 -m-1 ">
                <h3 className="text-white text-xl">
                    <Trans>Change Nickname</Trans>
                </h3>

                {CHANGE_NAME_FEE > 0 && (
                    <div className="flex gap-2">
                        <span>
                            <Trans>Required Coins</Trans>:
                        </span>
                        <div className="flex gap-1">
                            <img src={Gold} alt="" />
                            <span>{CHANGE_NAME_FEE}</span>
                        </div>
                    </div>
                )}

                {hasEnoughCoin ? (
                    <Input
                        value={name}
                        onChange={e => setName(e.target.value)}
                        className="bg-transparent text-white rounded-lg h-10"
                        placeholder="نام"
                    />
                ) : (
                    <>
                        <Alert
                            className="border-0 rounded-lg bg-red-500 "
                            showIcon
                            type="error"
                            message={
                                <span className="text-white mx-2">
                                    <Trans>Not Enough Coins</Trans>
                                </span>
                            }
                        />
                    </>
                )}

                <div className="grid gap-2">
                    {hasEnoughCoin ? (
                        <>
                            <Button
                                className="flex items-center justify-center bg-sky-500 disabled:bg-gray-400 h-12 rounded-lg border-0 mt-8 "
                                size="large"
                                type="primary"
                                onClick={() => {
                                    onConfirmNameChange()
                                }}
                                disabled={isPending}
                            >
                                {isPending ? <Spin /> : <Trans>Confirm</Trans>}{" "}
                            </Button>
                            <Button
                                disabled={isPending}
                                onClick={() => onClose()}
                                className="rounded-lg h-10 disabled:bg-gray-800 text-white"
                                size="large"
                                type="default"
                            >
                                <Trans>Cancel</Trans>
                            </Button>
                        </>
                    ) : (
                        <Link
                            to={AppRoutes.dashboard.shop.home}
                            className="flex items-center justify-center bg-sky-500 disabled:bg-gray-400 h-12 rounded-lg border-0 mt-8 text-lg"
                        >
                            {/* CONTENT: use go to store instead of store in farsi */}
                            <Trans>Go to Store</Trans>
                        </Link>
                    )}
                </div>
            </div>
        </Modal>
    )
}
