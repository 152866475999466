import { useLayoutEffect, useState, createContext, useContext, useRef } from "react"
import { ArrowUp, ArrowDown } from "core/utilities/Icons"
import { OnGoingGameCard } from "components/activity/OnGoingGameCard"
import { GameLevels, useActivity } from "core/providers/ActivityProvider"
import { ActivityGame } from "core/api/types/ActivityTypes"
import { useApp } from "core/providers/AppProvider"
import { AppRoutes } from "core/utilities/AppRoutes"
import { useNavigate } from "react-router-dom"
import { AvatarUnknown } from "core/utilities/ImageImport"
import { ActivityTypeObject } from "core/providers/TournamentProvider"
import { OnGoingTournamentCard } from "components/activity"
import { Trans, useTranslation } from "react-i18next"
interface Props {
    title: string | React.ReactNode
    children: React.ReactNode
}

interface ICollapsible extends React.FC<Props> {
    Content: React.FC<{ children: React.ReactNode }>
}

const CollapseContext = createContext(false)

const CustomCollapse: ICollapsible = ({ title, children }) => {
    // States and Hooks
    const { currentUser } = useApp()
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)
    const { awaitingGames, setIsActivity } = useActivity()
    const { t } = useTranslation()
    const listLength: number = awaitingGames?.length ?? 0
    const isCollapsiable: boolean = listLength > 1
    const foundActivity: ActivityGame | null = awaitingGames ? awaitingGames[0] : null
    const activity = foundActivity?.data
    const type = foundActivity?.type
    const isTournament = type === ActivityTypeObject.Tournament

    const firstScore: number =
        (activity?.playerOneId === currentUser?.ID ? activity?.playerOneScore : activity?.playerTwoScore) ?? 0

    const playerOneAvatar: string =
        (activity?.playerOneId === currentUser?.ID ? activity?.playerOneAvatar : activity?.playerTwoAvatar) ?? ""

    const rank = activity?.mode.rank as 0 | 1 | 2 | 3 | 4
    let cardDesc = ""
    switch (GameLevels[rank]) {
        case "QUALIFICATION":
            cardDesc = t("Trial").toString()
            break
        case "TRAINING":
            cardDesc = t("Practice Game").toString()
            break
        case "BRONZE":
            cardDesc = `${t("Duel").toString()} ${Number(activity?.mode.entrance)} ${t("Coins").toString()}`
            break
        case "SILVER":
            cardDesc = `${t("Duel").toString()} ${Number(activity?.mode.entrance)} ${t("Coins").toString()}`
            break
        case "GOLD":
            cardDesc = `${t("Duel").toString()} ${Number(activity?.mode.entrance)} ${t("Coins").toString()}`
            break
        default:
            break
    }

    // Methods

    // Render
    return (
        <CollapseContext.Provider value={isOpen}>
            <button
                onClick={() => {
                    if (isCollapsiable) setIsOpen(!isOpen)
                }}
            >
                <div className="flex justify-between">
                    {typeof title === "string" ? <h3 className="text-xl text-white">{title}</h3> : title}
                    {isCollapsiable &&
                        (isOpen ? (
                            <ArrowUp className="bg-primary-light rounded-full leading-none w-9 h-9 p-2" />
                        ) : (
                            <ArrowDown className="bg-primary-light rounded-full leading-none w-9 h-9 p-2" />
                        ))}
                </div>
                {!activity ? (
                    <div className="text-center text-gray-400 mt-2">
                        <Trans>No unfinished matches</Trans>
                    </div>
                ) : isTournament ? (
                    <button
                        className="btn p-0"
                        onClick={() => {
                            if ((isOpen && activity) || awaitingGames?.length === 1) {
                                navigate(`${AppRoutes.dashboard.tournament.result}/${activity.tournamentID}`)
                                setIsActivity(true)
                            }
                        }}
                    >
                        <div className="mt-2">
                            <OnGoingTournamentCard
                                gameImage={activity.game.imagePath}
                                title="Waiting Tournament to Finish"
                                desc="Tournament"
                                players={{
                                    user: {
                                        avatar: playerOneAvatar,
                                        score: firstScore,
                                    },
                                    competitor: {
                                        avatar: AvatarUnknown,
                                    },
                                }}
                                isTournament={true}
                            />
                        </div>
                    </button>
                ) : (
                    <button
                        className="btn p-0"
                        onClick={() => {
                            if ((isOpen && activity) || awaitingGames?.length === 1) {
                                setIsActivity(true)
                                navigate(`${AppRoutes.dashboard.awaiting}/${activity.matchId}`)
                            }
                        }}
                    >
                        <div className="mt-2">
                            <OnGoingGameCard
                                gameImage={activity.game.imagePath}
                                title={t("Waiting Opponent").toString()}
                                desc={cardDesc}
                                players={{
                                    user: {
                                        avatar: playerOneAvatar,
                                        score: firstScore,
                                    },
                                    competitor: {
                                        avatar: AvatarUnknown,
                                    },
                                }}
                            />
                        </div>
                    </button>
                )}
            </button>
            <div>{children}</div>
        </CollapseContext.Provider>
    )
}

const Content: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const isOpen = useContext(CollapseContext)
    const [height, setHeight] = useState(0)
    const childrenWrapper = useRef<HTMLDivElement>(null)

    // This part is for collapse expand transition thing to work
    useLayoutEffect(() => {
        if (isOpen) {
            childrenWrapper.current!.style.display = "block"
            setHeight(childrenWrapper.current!.clientHeight)
            return
        }
        setHeight(0)
        setTimeout(() => {
            childrenWrapper.current!.style.display = "none"
        }, 0)
    }, [isOpen])

    return (
        <div style={{ height }} className={`transition-all ${!isOpen ? "opacity-0" : " "}`}>
            <div ref={childrenWrapper}>{children}</div>
        </div>
    )
}

CustomCollapse.Content = Content

export { CustomCollapse }
